import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import {  HStack, SimpleGrid, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  GetAllSuggestionsApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import BackButton from "../../../components/BackButton/BackButton";
import TableComp from "../../../components/Table/TableComp";
import NoDataComponent from "../../../components/utils/NoDataComponent";
import UserSuggestionsRow from "../../../components/car/UserSuggestionsRow/UserSuggestionsRow";

export default function UserSuggestionsScreen() {
  const { logged } = useAuth();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);




  const titles = [
    { title: "User Name" },
    { title: "Cell Phone Number" },
    { title: "Email ID" },
    { title: "Suggestions" },
  ];




  useEffect(() => {
    fetchSuggestions();
  }, []);
  
  const fetchSuggestions = async (page) => {
    setLoading(true);
    setError(null);
    try {
      const response = await GetAllSuggestionsApi(page);
      if (response) {
        setSuggestions(response);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      setError("Failed to get User Suggestions. Please try again later.");
    }
    setLoading(false);
  };
  

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={{ base: "28px", md: "40px", lg: "46px" }}
                opacity={1}
              >
                <BackButton />
                User Suggestions
              </Text>
            </HStack>
          }
        />
      )}

<VStack w={"95%"} h={"auto"} py={"8"}>
        {loading && suggestions ? (
          <CustSpinner />
        ) : error ? (
          <Text color="red.500" py={8}>
            {error}
          </Text>
        ) : suggestions?.suggestions?.length === 0 ? (
          <Text color="gray.500" py={8}>
            No User Suggestions available at the moment.
          </Text>
        ) : (
          <VStack w={"95%"} h={"auto"} minHeight={"100vh"} py={"8"}>
            {titles ? (
              <SimpleGrid
              columns={{ base: 1, md: 1, lg: 1 }}
              w={"100%"}
              justifyItems={"center"}
              alignItems={"center"}
              py={12}
              p={15}
              spacing={5}
            >
                <TableComp 
                title={titles} 
                pagination={suggestions.pagination}
                onPageChange={fetchSuggestions}
                displayPagination={true}
                >
                  {suggestions && suggestions.suggestions?.length > 0 ? (
                    suggestions.suggestions?.map((user) => (
                      <UserSuggestionsRow
                        key={user._id}
                        _id={user._id}
                        name={user.username}
                        phoneNumber={user.phoneNumber}
                        email={user.email}
                        suggestions={user.content}
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No User available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            ) : (
              <NoDataComponent type="nocars" />
            )}
          </VStack>
        )}
      </VStack>
      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
