import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  VStack,
  FormErrorMessage,
  FormLabel,
  FormControl,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

// Yup validation schema for the FAQ form
const validationSchema = Yup.object({
  report_name: Yup.string().required("Report Type is required"),
  report_price: Yup.string().required("Report Price is required"),
  commission_per_sale: Yup.string().required("commission per sale is required"),
});

export default function ReportModal({ isOpen, onClose, onSubmit, reportsData = {} }) {
  const formik = useFormik({
    initialValues: {
      id: reportsData?._id || "", 
      report_name: reportsData?.report_name || "",
      report_price: reportsData?.report_price || "",
      commission_per_sale: reportsData?.commission_per_sale || "",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values); 
      formik.resetForm();
      onClose();
    },
  });

  useEffect(() => {
    if (reportsData) {
      formik.setValues({
        id: reportsData._id || "",
        report_name: reportsData.report_name || "",
        report_price: reportsData.report_price || "",
        commission_per_sale: reportsData.commission_per_sale || "",
      });
    }
    // eslint-disable-next-line
  }, [reportsData]); 

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent width={"100%"} height={"fit-content"} borderRadius={48} p={5}>
        <ModalHeader
          fontWeight={"600"}
          color={"#153373"}
          letterSpacing={"-2.53px"}
          fontSize={"31px"}
          opacity={1}
        >
          {reportsData ? "Edit Report Type" : "Create New Report Type"} 
        </ModalHeader>
        <ModalCloseButton onClick={onClose} right={8} top={6} />
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.touched.report_name && formik.errors.report_name}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                  Report Type:
                </FormLabel>
                <Input
                  name="report_name"
                  placeholder="Report Name"
                  value={formik.values.report_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.report_name && formik.errors.report_name}
                  inputMode="text"
                  type="text"
                  
                />
                <FormErrorMessage>{formik.errors.report_name}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.touched.report_price && formik.errors.report_price}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                  Report Price:
                </FormLabel>
                <InputGroup>
                <InputLeftAddon children="$" rounded={26} py={6}
                  fontSize={"xs"} bg={'#fff'} borderRight={'none'}/>
                <Input
                  name="report_price"
                  placeholder="Report Price"
                  value={formik.values.report_price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  borderLeft={'none'}
                  fontSize={"xs"}
                  isInvalid={formik.touched.report_price && formik.errors.report_price}
                  inputMode="numeric"
                  type="number"
                />
                </InputGroup>
                <FormErrorMessage>{formik.errors.report_price}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.touched.commission_per_sale && formik.errors.commission_per_sale}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                Commission Per Sale:
                </FormLabel>
                <InputGroup>
                <InputLeftAddon children="$" rounded={26} py={6}
                  fontSize={"xs"} bg={'#fff'} borderRight={'none'}/>
                <Input
                  name="commission_per_sale"
                  placeholder="commission per sale"
                  value={formik.values.commission_per_sale}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  borderLeft={'none'}
                  fontSize={"xs"}
                  isInvalid={formik.touched.commission_per_sale && formik.errors.commission_per_sale}
                  inputMode="numeric"
                  type="number"
                />
                </InputGroup>
                <FormErrorMessage>{formik.errors.commission_per_sale}</FormErrorMessage>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={formik.handleSubmit} 
            colorScheme="red"
            py={6}
            rounded={"49px"}
            w={{ base: "50%", md: "50%", lg: "50%" }}
            position={"absolute"}
            bottom={"0"}
            left={"50%"}
            transform={"translate(-50%, 50%)"}
            fontSize={"xs"}
            whiteSpace="pre-wrap"
            wordWrap="break-word"
            textOverflow="ellipsis"
          >
            {reportsData ? "Update Report Type" : "Create New Report Type"} 
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
