import {
    Button,
    Card,
    FormControl,
    Input,
    SimpleGrid,
    VStack,
    Text,
    Select,
    FormErrorMessage
} from '@chakra-ui/react';
import React from 'react';
import { Divider } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';
// import { useFormik } from 'formik';
import { BrandOptions, years } from '../../utils/DropDataList';
import { notifyError } from '../../utils/Toast/ToastNotifications';

export default function CheckVehicleInfo({currentStep, setCurrentStep,formik}) {
  

    const handleContinue = async () => {
        const errors = await formik.validateForm();
        formik.setTouched({
            mile: true,
            year: true,
            make: true,
            model: true,
            trim: true,
            engine: true,
            made_in: true,
            style: true,
            s_seating: true,
            h_milage: true,
            c_milage: true,
            fuel_type: true,
            fuel_capacity: true,
            vehicle_color_i: true,
            vehicle_color_e: true,
        });

        if (Object.keys(errors).length === 1) {
            setCurrentStep(currentStep + 1);
        }else if(Object.keys(errors).length === 0){
            setCurrentStep(currentStep + 1);
        }else{
            notifyError('Fill The All Required Fields')
        }
    };

    return (
        <Card
            bg='white' borderRadius={'48px'}
            style={{
                boxShadow: '0px 10px 99px #00000029'
            }}
            py={{base:'12', lg:'6'}}
            px={{ base: '6', md: '12', lg:'16' }}
            w={{base:'80%', md:'90%', lg:'60%'}}
            my={4}
        >

            <VStack maxW='100%' w='100%' justifyContent='start' alignItems='start'>
                <Text
                    textAlign={{base:'center', md:'start'}}
                    w={{ base: '100%', md: '80%' }}
                    fontSize={{ base: 'xs', md: 'sm', lg:'md' }}
                    fontWeight={'500'}
                    color={'#153373'}
                    opacity={'0.8'}
                >
                    Check Your Vehicle Information
                </Text>
                <Divider borderWidth={'1px'} borderColor={'#e3e1e1'} alignItems={'center'} w={'100%'} my={2} />
            </VStack>

            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacing={{ base: '6', lg:'4' }}
                w={'100%'}
                py={4}               
            >
                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Current miles on vehicle<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.mile && formik.touched.mile}>
                        <Input
                            type='number'
                            id='mile'
                            name='mile'
                            onChange={formik.handleChange}
                            value={formik.values.mile}
                            required={true}
                            rounded={26}
                            // placeholder='2200'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                            min={1}
                        />
                        <FormErrorMessage>{formik.errors.mile}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Select Year<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.year && formik.touched.year}>
                          <Select
                                id='year'
                                name='year'
                                onChange={formik.handleChange}
                                value={formik.values.year}
                                required
                                rounded={26}
                                bg={'#fffff'}
                                fontSize={'xs'}
                                color={'#707070'}
                                placeholder='Select Year'
                              >
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </Select>
                          
                        <FormErrorMessage>{formik.errors.year}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Select Make<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.make && formik.touched.make}>
                        {/* <Select
                            // placeholder='Nissan'
                            id='make'
                            name='make'
                            onChange={formik.handleChange}
                            value={formik.values.make}
                            required
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                            placeholder='select'
                        >
                            <option value='option1'>Nissan</option>
                            <option value='option1'>Nissan</option>
                            <option value='option2'>Nissan</option>
                            <option value='option3'>Nissan</option>
                            <option value='option3'>Nissan</option>
                        </Select> */}
                        
                         <Select
                          id="make"
                          name="make"
                          onChange={formik.handleChange}
                          value={formik.values.make}
                          placeholder="Select Make"
                          rounded={26}
                          bg={'#fffff'}
                          fontSize={'xs'}
                          color={'#707070'}
                        >
                          {BrandOptions.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </Select>
           
                        <FormErrorMessage>{formik.errors.make}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Select Model<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.model && formik.touched.model}>
                        {/* <Select
                            // placeholder='Nissan Model'
                            id='model'
                            name='model'
                            onChange={formik.handleChange}
                            value={formik.values.model}
                            required
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                            placeholder='select'
                        >
                            <option value='option1'>Nissan</option>
                            <option value='option1'>Nissan</option>
                            <option value='option2'>Nissan</option>
                            <option value='option3'>Nissan</option>
                            <option value='option3'>Nissan</option>
                        </Select> */}
                         <Input
                            type='text'
                            id='model'
                            name='model'
                            onChange={formik.handleChange}
                            value={formik.values.model}
                            required={true}
                            rounded={26}
                            // placeholder='2200'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                            min={1}
                        />
                        <FormErrorMessage>{formik.errors.model}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>Select Trim<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.trim && formik.touched.trim}>
                        {/* <Select
                            // placeholder='32SR'
                            id='trim'
                            name='trim'
                            onChange={formik.handleChange}
                            value={formik.values.trim}
                            required
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                            placeholder='select'
                        >
                            <option value='option1'>32SR</option>
                            <option value='option1'>32SR</option>
                            <option value='option2'>32SR</option>
                            <option value='option3'>32SR</option>
                            <option value='option3'>32SR</option>
                        </Select> */}
                         <Input
                            type='text'
                            id='trim'
                            name='trim'
                            onChange={formik.handleChange}
                            value={formik.values.trim}
                            required={true}
                            rounded={26}
                            // placeholder='2200'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                            min={1}
                        />
                        <FormErrorMessage>{formik.errors.trim}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Engine<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.engine && formik.touched.engine}>
                        <Input
                            type='text'
                            id='engine'
                            name='engine'
                            onChange={formik.handleChange}
                            value={formik.values.engine}
                            required={true}
                            rounded={26}
                            // placeholder='1.8L L4 DOHC 16V'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                            sx={{ textTransform: 'uppercase' }}
                        />
                         <FormErrorMessage>{formik.errors.engine}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Made In<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.made_in && formik.touched.made_in}>
                        <Input
                            type='text'
                            id='made_in'
                            name='made_in'
                            onChange={formik.handleChange}
                            value={formik.values.made_in}
                            required={true}
                            rounded={26}
                            // placeholder='2013'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                        />
                         <FormErrorMessage>{formik.errors.made_in}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Style<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.style && formik.touched.style}>
                        <Input
                            type='text'
                            id='style'
                            name='style'
                            onChange={formik.handleChange}
                            value={formik.values.style}
                            required={true}
                            rounded={26}
                            // placeholder='2200'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                        />
                         <FormErrorMessage>{formik.errors.style}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Standard Seating<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.s_seating && formik.touched.s_seating}>
                        <Input
                            type='number'
                            id='s_seating'
                            name='s_seating'
                            onChange={formik.handleChange}
                            value={formik.values.s_seating}
                            required={true}
                            rounded={26}
                            // placeholder='5'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                            min={1}
                            max={2}
                        />
                         <FormErrorMessage>{formik.errors.s_seating}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Highway Milage<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.h_milage && formik.touched.h_milage}>
                        <Input
                            type='number'
                            id='h_milage'
                            name='h_milage'
                            onChange={formik.handleChange}
                            value={formik.values.h_milage}
                            required={true}
                            rounded={26}
                            // placeholder=''
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                        />
                         <FormErrorMessage>{formik.errors.h_milage}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} opacity={'0.8'} fontSize={'xs'} fontWeight={'400'}>City Milage<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.c_milage && formik.touched.c_milage}>
                        {/* <Select
                            // placeholder=''
                            id='c_milage'
                            name='c_milage'
                            onChange={formik.handleChange}
                            value={formik.values.c_milage}
                            required
                            rounded={26}
                            bg={'#fffff'}
                            fontSize={'xs'}
                            color={'#707070'}
                            placeholder='select'
                        >
                            <option value='option1'>Nissan</option>
                            <option value='option1'>Nissan</option>
                            <option value='option2'>Nissan</option>
                            <option value='option3'>Nissan</option>
                            <option value='option3'>Nissan</option>
                        </Select> */}
                        <Input
                            type='number'
                            id='c_milage'
                            name='c_milage'
                            onChange={formik.handleChange}
                            value={formik.values.c_milage}
                            required={true}
                            rounded={26}
                            // placeholder='2200'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                            min={1}
                        />
                        <FormErrorMessage>{formik.errors.c_milage}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Fuel Type<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.fuel_type && formik.touched.fuel_type}>
                        <Input
                            type='text'
                            id='fuel_type'
                            name='fuel_type'
                            onChange={formik.handleChange}
                            value={formik.values.fuel_type}
                            required={true}
                            rounded={26}
                            // placeholder='Regular Unleaded'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                        />
                         <FormErrorMessage>{formik.errors.fuel_type}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Fuel Capacity<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.fuel_capacity && formik.touched.fuel_capacity}>
                        <Input
                            type='number'
                            id='fuel_capacity'
                            name='fuel_capacity'
                            onChange={formik.handleChange}
                            value={formik.values.fuel_capacity}
                            required={true}
                            rounded={26}
                            // placeholder='13.2 gallon'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                        />
                         <FormErrorMessage>{formik.errors.fuel_capacity}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Vehicle Color (Interior)<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.vehicle_color_i && formik.touched.vehicle_color_i}>
                        <Input
                            type='text'
                            id='vehicle_color_i'
                            name='vehicle_color_i'
                            onChange={formik.handleChange}
                            value={formik.values.vehicle_color_i}
                            required={true}
                            rounded={26}
                            // placeholder='grey'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                        />
                         <FormErrorMessage>{formik.errors.vehicle_color_i}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align={'start'}>
                    <Text color={'#707070'} fontSize={'xs'} opacity={'0.8'} fontWeight={'400'}>Vehicle Color (Exterior)<Text color={'#f33'} as='sup'>*</Text></Text>
                    <FormControl isInvalid={formik.errors.vehicle_color_e && formik.touched.vehicle_color_e}>
                        <Input
                            type='text'
                            id='vehicle_color_e'
                            name='vehicle_color_e'
                            onChange={formik.handleChange}
                            value={formik.values.vehicle_color_e}
                            required={true}
                            rounded={26}
                            // placeholder='green'
                            fontSize={'xs'}
                            py={4}
                            color={'#707070'}
                        />
                         <FormErrorMessage>{formik.errors.vehicle_color_e}</FormErrorMessage>
                    </FormControl>
                </VStack>

            </SimpleGrid>

            <Button
                colorScheme="red"
                py={6}
                rounded={'34px'}
                w={{ base: '60%', md: '30%', lg: '20%' }}
                position={'absolute'}
                bottom={'0'}
                left={'50%'}
                transform={'translate(-50%, 50%)'}
                fontSize={'xs'}
                onClick={handleContinue}
            >
                Continue
            </Button>

        </Card>
    )
}
