import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import NoDataComponent from "../../../components/utils/NoDataComponent";
import { HStack, SimpleGrid, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import { GetAllCarsDetailsApi } from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import CustCard from "../../../components/utils/CustCard";
import TableComp from "../../../components/Table/TableComp";
import CarRow from "../../../components/car/CarRow/CarRow";
import BackButton from "../../../components/BackButton/BackButton";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";

export default function CarsScreen() {
  const { logged } = useAuth();
  const [vehicles, setVehicles] = useState();
  const [loading, setLoading] = useState(true);
  const fetchVehicles = (values) => {
    if (!logged) return;
    GetAllCarsDetailsApi(values)
      .then((response) => {
        setVehicles(response);
      })
      .catch((err) => {
        console.log(err);
        setVehicles([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchVehicles();
  }, [logged]);

  const handleCheckboxChange = () => {};

  const cards = [
    {
      title: vehicles?.totalVehicles || "0",
      subtitle: "No of Cars",
    },
    {
      title: vehicles?.totalBuyRequests || "0",
      subtitle: "Buying Requests",
    },
    {
      title: vehicles?.totalTradeRequests || "0",
      subtitle: "Trading Requests",
    },
  ];

  const titles = [
    { title: "VIN" },
    { title: "Current Miles" },
    { title: "Year" },
    { title: "Make" },
    { title: "Model" },
    { title: "Original Price" },
    { title: "Buy Requests" },
    { title: "Trade Requests" },
  ];

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <BackButton />
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={"46px"}
                opacity={1}
              >
                Cars{" "}
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"95%"} h={"auto"} py={"8"}>
        {loading ? (
          <CustSpinner />
        ) : (
          <VStack w={"95%"} h={"auto"} minHeight={"100vh"} py={"8"}>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              w={"fit-content"}
              alignSelf={"flex-start"}
              justifyItems={"flex-start"}
              py={12}
              p={15}
              spacing={5}
              rowGap={50}
            >
              {cards.map((item, index) => (
                <CustCard
                  key={index}
                  title={item.title}
                  subtitle={item.subtitle}
                />
              ))}
            </SimpleGrid>
            <HStack
              h={"fit-content"}
              w={"100%"}
              justify={"flex-start"}
              align={"center"}
              textAlign={"left"}
              p={2}
              mt={10}
            >
              <Text
                fontWeight={"500"}
                color={"#1D1C1A"}
                opacity={"1"}
                fontSize={"25px"}
              >
                Cars Details
              </Text>
            </HStack>
            {titles ? (
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                justifyItems={"center"}
                alignItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <TableComp
                  title={titles}
                  emptySColumn={true}
                  pagination={vehicles.pagination}
                  onPageChange={fetchVehicles}
                  displayPagination={true}
                >
                  {vehicles.vehicleDetails &&
                  vehicles.vehicleDetails.length > 0 ? (
                    vehicles.vehicleDetails?.map((vehicle) => (
                      <CarRow
                        key={vehicle._id}
                        _id={vehicle._id}
                        vin={vehicle.vin}
                        miles={vehicle.miles}
                        year={vehicle.year}
                        make={`${vehicle.make} ${vehicle.vehicleModel}`}
                        // vehicleModel={vehicle.vehicleModel}
                        price={vehicle.price}
                        buyRequestCount={vehicle.buyRequestCount}
                        tradeRequestCount={vehicle.tradeRequestCount}
                        onCheckboxChange={handleCheckboxChange}
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No vehicles available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            ) : (
              <NoDataComponent type="nocars" />
            )}
          </VStack>
        )}
      </VStack>

      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
