import {
    Button,
    FormControl,
    FormErrorMessage,
    Input,
    SimpleGrid,
    Text,
    VStack,
    Divider
} from '@chakra-ui/react';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { notifyError, notifySuccess } from '../utils/Toast/ToastNotifications';
import { ChangePasswordApi } from '../../Axios/Api';

const validationSchema = Yup.object({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string()
        .required('New Password is required')
        .min(8, 'Password must be at least 8 characters long'),
    confirm_password: Yup.string()
        .required('Confirm New Password is required')
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
});

export default function ChangePassword() {
    const formik = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
            ChangePasswordApi(values).then((response) => {
                notifySuccess(response?.message || 'Password changed successfully');
                resetForm();
            }).catch((error) => {
                notifyError(error.response?.data?.message || 'Error changing password');
            });
        }
    });

    return (
        <VStack p={4} w="100%" alignItems="start" bg="#FBFBFB" mb={8} as="form" onSubmit={formik.handleSubmit}>
            <Text color="#575757" fontSize="sm" fontWeight={600} pb={4}>
                Change Password
            </Text>
            <Divider borderWidth="1px" borderColor="#EDECF1" alignItems="center" w="100%" />

            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacing="6"
                justifyContent="space-around"
                py={4}
                px={{ base: 4, md: 0 }}
                w={{ base: '100%', md: '85%', lg: '65%' }}
            >
                <VStack align="start">
                    <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">Old Password</Text>
                    <FormControl isInvalid={formik.errors.old_password && formik.touched.old_password}>
                        <Input
                            type="password"
                            id="old_password"
                            name="old_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.old_password}
                            rounded={26}
                            placeholder="Old Password"
                            py={4}
                            fontSize="xs"
                            bg="#FFFFFF"
                        />
                        <FormErrorMessage>{formik.errors.old_password}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align="start">
                    <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">New Password</Text>
                    <FormControl isInvalid={formik.errors.new_password && formik.touched.new_password}>
                        <Input
                            type="password"
                            id="new_password"
                            name="new_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.new_password}
                            rounded={26}
                            placeholder="New Password"
                            py={4}
                            fontSize="xs"
                            bg="#FFFFFF"
                        />
                        <FormErrorMessage>{formik.errors.new_password}</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack align="start">
                    <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">Confirm New Password</Text>
                    <FormControl isInvalid={formik.errors.confirm_password && formik.touched.confirm_password}>
                        <Input
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirm_password}
                            rounded={26}
                            placeholder="Confirm New Password"
                            py={4}
                            fontSize="xs"
                            bg="#FFFFFF"
                        />
                        <FormErrorMessage>{formik.errors.confirm_password}</FormErrorMessage>
                    </FormControl>
                </VStack>
            </SimpleGrid>

            <Button
                fontSize="xs"
                type="submit"
                colorScheme="red"
                rounded="22px"
                w={120}
                h={10}
                style={{
                    boxShadow: '0px 10px 20px #00000029'
                }}
            >
                Update
            </Button>
        </VStack>
    );
}
