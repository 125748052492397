import { Flex, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function BottomBarStatic() {
  const location = useLocation();

  return (
    <Flex
      as="footer"
      bg="#142846"
      color="#FFFFFF"
      direction={{ base: 'column-reverse', md: 'row' }}
      justify="space-around"
      align="center"
      fontSize="12px"
      py={4}
      width={"100%"}
      bottom="0"
      left="0"
    >
      <Text opacity="0.7">
        © 2022 CARS FOR SALE & LOANS MARKETPLACE
      </Text>
      <Stack
        align="center"
        direction={{ base: 'column-reverse', md: 'row' }}
        spacing={{ base: '2', md: '6' }}
        opacity="0.7"
      >
        <Link to="/faqs">
          <Text
            _hover={{ color: '#25BC9D' }}
            color={location.pathname.includes("faqs") ? "#25BC9D" : ''}
          >
            Help & Support
          </Text>
        </Link>
        <Link to="/terms-and-condition">
          <Text
            _hover={{ color: '#25BC9D' }}
            color={location.pathname.includes("terms-and-condition") ? "#25BC9D" : ''}
          >
            Terms and Conditions
          </Text>
        </Link>
        <Link to="/privacy-policy">
          <Text
            _hover={{ color: '#25BC9D' }}
            color={location.pathname.includes("privacy-policy") ? "#25BC9D" : ''}
          >
            Privacy Policy
          </Text>
        </Link>
      </Stack>
    </Flex>
  );
}
