import {
  Button,
  Card,
  Container,
  Flex,
  FormControl,
  HStack,
  Image,
  Input,
  SimpleGrid,
  Box,
  Text,
  VStack,
  Select,
  Checkbox,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useFormik } from "formik";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Divider } from "@chakra-ui/react";
import { useAuth } from "../../providers/AuthProvider";
import { ReCaptcha } from "../ReCaptcha/ReCaptcha";
import * as Yup from 'yup';
import { notifyError, notifySuccess } from "../utils/Toast/ToastNotifications";
import { SignUp } from "../../Axios/Api";

const validationSchema = Yup.object({
    email: Yup.string().email('*Invalid email address').required('*Email is required'),
    password: Yup.string().required('*Password is required'),
    confirmPassword: Yup.string()
        .required('*Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Password Does Not Match'),
    fname: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, '*First Name should not contain numbers or symbols')
        .required('*First Name is required'),
    lname: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, '*Last Name should not contain numbers or symbols')
        .required('*Last Name is required'),
    phone: Yup.string()
        .matches(/^\d{10}$/, '*Phone Number must be 10 digits long')
        .required('*Phone Number is required'),
    city: Yup.string().required('*City is required'),
    state: Yup.string().required('*State is required'),
    lamount: Yup.number().required('*Loan amount is required'),
    dpamount:  Yup.number().required('*Down payment amount is required'),
    ccscore: Yup.number().required('*Current credit score is required'),
});
export default function GetCarLoan() {
  const { logged } = useAuth();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const formik = useFormik({
    initialValues: {
        fname: "",
        lname: "",
        phone: "",
        email: "",
        city: "",
        state: "",
        password: "",
        confirmPassword: "",
      lamount: "",
      dpamount: "",
      ccscore: "",
    },
    validationSchema: validationSchema,
        onSubmit: values => {
            if (currentStep === 0) {
                handleContinue();
            } else {
                handleSubmit(values);
            }
        },
        validateOnChange: true,
        validateOnBlur: true,
  });
  const handleSubmit = (values) => {
    SignUp(values)
        .then((response) => {
            notifySuccess(response.message);
            navigate('/verificationcode', { state: { refId: response.data.id, email: values.email, vrf: 1 } });
        }).catch((error) => {
            notifyError(error.response.data.message);
        });
};

const handleContinue = () => {
    formik.validateForm().then(errors => {
        console.log(errors);
        if (Object.keys(errors).length === 5) {
            setCurrentStep(currentStep + 1);
        } else {
            notifyError('Fill all required fields correctly!');
        }
    });
};
  return (
    <>
      {logged ? (
        <Card
          bg="white"
          borderRadius={"48px"}
          style={{
            boxShadow: "0px 10px 99px #00000029",
          }}
          p={{ base: "6", md: "8" }}
          maxW="100%"
          mx={{ md: "15%" }}
        >
          <VStack
            maxW="100%"
            w="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Text
              textAlign="center"
              w={{ base: "100%", md: "80%" }}
              fontSize={{ base: "md", md: "xl" }}
              fontWeight={"500"}
              color={"#153373"}
              opacity={"0.8"}
            >
              Get a Loan For a car
            </Text>
            <Divider
              borderWidth={"1px"}
              borderColor={"#e3e1e1"}
              alignItems={"center"}
              w={"85%"}
              my={4}
            />
          </VStack>

          <VStack>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={{ base: "6", md: "12" }}
              w={"85%"}
              pb={18}
            >
              <VStack align={"start"}>
                <Text
                  color={"#707070"}
                  fontSize={"sm"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  Loan Amount
                </Text>
                <FormControl>
                  <Input
                    type="number"
                    id="lamount"
                    name="lamount"
                    onChange={formik.handleChange}
                    value={formik.values.lamount}
                    required={true}
                    rounded={26}
                    placeholder="Enter Loan Amount"
                    fontSize={"xs"}
                    py={4}
                  />
                </FormControl>
              </VStack>

              <VStack align={"start"}>
                <Text
                  color={"#707070"}
                  fontSize={"sm"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  Down Payment Amount
                </Text>
                <FormControl>
                  <Input
                    type="number"
                    id="dpamount"
                    name="dpamount"
                    onChange={formik.handleChange}
                    value={formik.values.dpamount}
                    required={true}
                    rounded={26}
                    placeholder="Down Payment Amount"
                    py={4}
                    fontSize={"xs"}
                  />
                </FormControl>
              </VStack>

              <VStack align={"start"}>
                <Text
                  color={"#707070"}
                  opacity={"0.8"}
                  fontSize={"sm"}
                  fontWeight={"400"}
                >
                  Current Credit Score
                </Text>
                <FormControl>
                  <Select
                    placeholder="select"
                    id="dpamount"
                    name="dpamount"
                    onChange={formik.handleChange}
                    value={formik.values.dpamount}
                    required
                    rounded={26}
                    bg={"#fffff"}
                    fontSize={"xs"}
                    color={"#707070"}
                  >
                    <option value="option1">1</option>
                    <option value="option1">2</option>
                    <option value="option2">3</option>
                    <option value="option3">4</option>
                  </Select>
                </FormControl>
              </VStack>
            </SimpleGrid>

            {/* <HStack
                            bg={'#F2F2F2'}
                            border='2px'
                            borderColor='#adacac'
                            w={{ md: '100%', lg: '25%' }}
                            mb={4}
                            p={4}
                            justifyContent={'space-between'}
                        >
                            <HStack>
                                <Checkbox size='lg' backgroundColor={'white'} borderColor='#adacac' ></Checkbox>
                                <Text
                                    color={'#707070'}
                                    fontSize={'13px'}
                                >
                                    I'm not a robot
                                </Text>
                            </HStack>
                            <Image src='./captcha.png' height={30} />
                        </HStack> */}
            <ReCaptcha onVerifyCaptcha={(token) => console.log(token)} />
            <HStack mb={4}>
              <Checkbox
                size="sm"
                backgroundColor={"white"}
                borderColor="#adacac"
              ></Checkbox>
              <Text color={"#707070"} fontSize={"12px"} fontWeight={400}>
                I accept the
              </Text>
              <Text fontSize={"12px"} fontWeight={400} color="#153373">
                Terms & Conditions
              </Text>
            </HStack>
          </VStack>

          <Button
            onClick={() => {
              navigate("/all-request");
            }}
            colorScheme="red"
            py={6}
            rounded={"34px"}
            w={{ base: "60%", md: "30%", lg: "20%" }}
            position={"absolute"}
            bottom={"0"}
            left={"50%"}
            transform={"translate(-50%, 50%)"}
            fontSize={"xs"}
          >
            Submit
          </Button>
        </Card>
      ) : (
        <Card
          bg="white"
          borderRadius={"48px"}
          style={{
            boxShadow: "0px 10px 99px #00000029",
          }}
          p={{ base: "6", md: "8" }}
          maxW="100%"
          mx={{ md: "15%" }}
        >
          <VStack
            maxW="100%"
            w="100%"
            spacing="8"
            justifyContent="center"
            alignItems="center"
          >
            <HStack w="100%" justifyContent="start">
              <HStack w={{ base: "20%", md: "8%" }}>
                {currentStep < 1 ? (
                  <></>
                ) : (
                  <Box
                    bg="gray.200"
                    w="50px"
                    h="50px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    rounded="50%"
                  >
                    <FaArrowLeftLong
                      onClick={() => setCurrentStep(currentStep - 1)}
                    />
                  </Box>
                )}
              </HStack>
              <Text
                textAlign={{ base: "start", md: "center" }}
                w={{ base: "100%", md: "80%" }}
                fontSize={{ base: "md", md: "xl" }}
                fontWeight={"500"}
                color={"#153373"}
                opacity={"0.8"}
                pb={6}
              >
                Get a Loan For a car
              </Text>
            </HStack>
          </VStack>

          <HStack maxW="100%" justifyContent="center">
            <Flex
              w={{ md: "100%", lg: "45%" }}
              bg={"white"}
              borderRadius={"48px"}
              justify={"space-between"}
              style={{
                boxShadow: "0px 10px 99px #00000029",
              }}
            >
              <HStack p={4}>
                <Image src={"./check.png"} h={35} />
                <Text
                  color={"#153373"}
                  fontSize={{ base: "12px" }}
                  fontWeight={"500"}
                  opacity={"0.8"}
                >
                  Step 1
                </Text>
              </HStack>

              {currentStep === 1 ? (
                <HStack p={4}>
                  <Image src={"./done_outline.png"} h={45} />
                  <Text
                    color={"#153373"}
                    fontSize={{ base: "12px" }}
                    fontWeight={"500"}
                    opacity={"0.8"}
                  >
                    Step 2
                  </Text>
                </HStack>
              ) : currentStep === 2 ? (
                <>
                  <HStack p={4}>
                    <Image src={"./check.png"} h={35} />
                    <Text
                      color={"#153373"}
                      fontSize={{ base: "12px" }}
                      fontWeight={"500"}
                      opacity={"0.8"}
                    >
                      Step 2
                    </Text>
                  </HStack>
                </>
              ) : (
                <>
                  <HStack p={4}>
                    <Container
                      w={8}
                      h={8}
                      borderRadius={50}
                      bg={"#EFEFEF"}
                    ></Container>
                    <Text
                      color={"#153373"}
                      fontSize={{ base: "12px" }}
                      fontWeight={"500"}
                      opacity={"0.8"}
                    >
                      Step 2
                    </Text>
                  </HStack>
                </>
              )}

              {currentStep === 2 ? (
                <HStack p={4}>
                  <Image src={"./done_outline.png"} h={45} />
                  <Text
                    color={"#153373"}
                    fontSize={{ base: "12px" }}
                    fontWeight={"500"}
                    opacity={"0.8"}
                  >
                    Step 3
                  </Text>
                </HStack>
              ) : (
                <>
                  <HStack p={4}>
                    <Container
                      w={8}
                      h={8}
                      borderRadius={50}
                      bg={"#EFEFEF"}
                    ></Container>
                    <Text
                      color={"#153373"}
                      fontSize={{ base: "12px" }}
                      fontWeight={"500"}
                      opacity={"0.8"}
                    >
                      Step 3
                    </Text>
                  </HStack>
                </>
              )}
            </Flex>
          </HStack>

          {currentStep === 0 ? (
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing="8"
              width={"auto"}
              justifyContent={"space-evenly"}
              py={4}
              mx={{ base: "4", md: 16 }}
            >
              <VStack align={"start"}>
                <Text
                  fontSize={"sm"}
                  color={"#707070"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  First Name
                </Text>
                <FormControl isInvalid={formik.touched.fname && formik.errors.fname}>
                <Input
                    type='text'
                    id='fname'
                    name='fname'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fname}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='First Name'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.fname}</FormErrorMessage>
            </FormControl>
              </VStack>
              <VStack align={"start"}>
                <Text
                  fontSize={"sm"}
                  color={"#707070"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  Last Name
                </Text>
                <FormControl isInvalid={formik.touched.lname && formik.errors.lname}>
                <Input
                    type='text'
                    id='lname'
                    name='lname'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lname}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='Last Name'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.lname}</FormErrorMessage>
            </FormControl>
              </VStack>
              <VStack align={"start"}>
                <Text
                  fontSize={"sm"}
                  color={"#707070"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  Cell Phone Number
                </Text>
                <FormControl isInvalid={formik.touched.phone && formik.errors.phone}>
                <Input
                    type='number'
                    id='phone'
                    name='phone'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='Phone Number'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>
              </VStack>
              <VStack align={"start"}>
                <Text
                  fontSize={"sm"}
                  color={"#707070"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  Email ID
                </Text>
                <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                            <Input
                                type='text'
                                id='email'
                                name='email'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                rounded={34}
                                bg={'#FFFFFF'}
                                placeholder='Email address'
                                py={6}
                                fontSize={'sm'}
                            />
                            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                        </FormControl>
              </VStack>
              <VStack align={"start"}>
                <Text
                  fontSize={"sm"}
                  color={"#707070"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  City
                </Text>
                <FormControl isInvalid={formik.touched.city && formik.errors.city}>
                <Input
                    type='text'
                    id='city'
                    name='city'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='City'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
            </FormControl>
              </VStack>
              <VStack align={"start"}>
                <Text
                  fontSize={"sm"}
                  color={"#707070"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  State
                </Text>
                <FormControl isInvalid={formik.touched.state && formik.errors.state}>
                <Input
                    type='text'
                    id='state'
                    name='state'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                    rounded={34}
                    bg={'#FFFFFF'}
                    placeholder='State'
                    py={4}
                    fontSize={'sm'}
                />
                <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
            </FormControl>
              </VStack>
            </SimpleGrid>
          ) : (
            <></>
          )}

          {currentStep === 1 ? (
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing="8"
              width={"auto"}
              justifyContent={"space-between"}
              pt={8}
              pb={18}
              mx={{ base: "4", md: 16 }}
            >
              <VStack align={"start"}>
                <Text
                  fontSize={"sm"}
                  color={"#707070"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  Create New Password
                </Text>
                <FormControl isInvalid={formik.touched.password && formik.errors.password}>
                            <Input
                                type='password'
                                id='password'
                                name='password'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                rounded={34}
                                bg={'#FFFFFF'}
                                placeholder='Create New Password'
                                py={6}
                                fontSize={'sm'}
                            />
                            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                        </FormControl>
              </VStack>

              <VStack align={"start"}>
                <Text
                  fontSize={"sm"}
                  color={"#707070"}
                  opacity={"0.8"}
                  fontWeight={"400"}
                >
                  Confirm Password
                </Text>
                <FormControl isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}>
                            <Input
                                type='password'
                                id='confirmPassword'
                                name='confirmPassword'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                                rounded={34}
                                bg={'#FFFFFF'}
                                placeholder='Confirm Password'
                                py={6}
                                fontSize={'sm'}
                            />
                            <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                        </FormControl>
              </VStack>
            </SimpleGrid>
          ) : (
            <></>
          )}

          {currentStep === 2 ? (
            <VStack>
              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                spacing="8"
                width={{ base: "100%", md: "auto" }}
                justifyContent={"space-between"}
                pt={8}
                pb={18}
              >
                <VStack align={"start"}>
                  <Text
                    fontSize={"sm"}
                    color={"#707070"}
                    opacity={"0.8"}
                    fontWeight={"400"}
                  >
                    Loan Amount
                  </Text>
                  <FormControl>
                    <Input
                      type="number"
                      id="lamount"
                      name="lamount"
                      onChange={formik.handleChange}
                      value={formik.values.lamount}
                      required={true}
                      rounded={26}
                      placeholder="Enter Loan Amount"
                      py={4}
                      fontSize={"xs"}
                    />
                  </FormControl>
                </VStack>

                <VStack align={"start"}>
                  <Text
                    fontSize={"sm"}
                    color={"#707070"}
                    opacity={"0.8"}
                    fontWeight={"400"}
                  >
                    Down Payment Amount
                  </Text>
                  <FormControl>
                    <Input
                      type="number"
                      id="dpamount"
                      name="dpamount"
                      onChange={formik.handleChange}
                      value={formik.values.dpamount}
                      required={true}
                      rounded={26}
                      placeholder="Down Payment Amount"
                      py={4}
                      fontSize={"xs"}
                    />
                  </FormControl>
                </VStack>

                <VStack align={"start"}>
                  <Text
                    fontSize={"sm"}
                    color={"#707070"}
                    opacity={"0.8"}
                    fontWeight={"400"}
                  >
                    Current Credit Score
                  </Text>
                  <FormControl>
                    <Select
                      placeholder="select"
                      id="dpamount"
                      name="dpamount"
                      onChange={formik.handleChange}
                      value={formik.values.dpamount}
                      required
                      rounded={26}
                      bg={"#fffff"}
                      fontSize={"xs"}
                    >
                      <option value="option1">1</option>
                      <option value="option1">2</option>
                      <option value="option2">3</option>
                      <option value="option3">4</option>
                    </Select>
                  </FormControl>
                </VStack>
              </SimpleGrid>

              <HStack
                bg={"#F2F2F2"}
                border="2px"
                borderColor="#adacac"
                w={{ md: "100%", lg: "25%" }}
                mb={4}
                p={4}
                justifyContent={"space-between"}
              >
                <HStack>
                  <Checkbox
                    size="lg"
                    backgroundColor={"white"}
                    borderColor="#adacac"
                  ></Checkbox>
                  <Text color={"#707070"} fontSize={"13px"}>
                    I'm not a robot
                  </Text>
                </HStack>
                <Image src="./captcha.png" height={30} />
              </HStack>

              <HStack mb={4}>
                <Checkbox
                  size="sm"
                  backgroundColor={"white"}
                  borderColor="#adacac"
                ></Checkbox>
                <Text color={"#707070"} fontSize={"12px"} fontWeight={400}>
                  I accept the
                </Text>
                <Text fontSize={"12px"} fontWeight={400} color="#153373">
                  Terms & Conditions
                </Text>
              </HStack>
            </VStack>
          ) : (
            <></>
          )}

          {/* button  */}
          {currentStep < 1 ? (
            <Button
            onClick={handleContinue}
              colorScheme="red"
              py={6}
              rounded={"34px"}
              w={{ base: "60%", md: "30%", lg: "20%" }}
              position={"absolute"}
              bottom={"0"}
              left={"50%"}
              transform={"translate(-50%, 50%)"}
              fontSize={"xs"}
            >
              Continue
            </Button>
          ) : currentStep < 2 ? (
            <Button
            onClick={handleContinue}
              colorScheme="red"
              py={6}
              rounded={"34px"}
              w={{ base: "60%", md: "30%", lg: "20%" }}
              position={"absolute"}
              bottom={"0"}
              left={"50%"}
              transform={"translate(-50%, 50%)"}
              fontSize={"xs"}
            >
              Continue
            </Button>
          ) : (
            <Button
              onClick={() => {
                navigate("/verificationcode");
              }}
              colorScheme="red"
              py={6}
              rounded={"34px"}
              w={{ base: "60%", md: "30%", lg: "20%" }}
              position={"absolute"}
              bottom={"0"}
              left={"50%"}
              transform={"translate(-50%, 50%)"}
              fontSize={"xs"}
            >
              Send OTP
            </Button>
          )}
        </Card>
      )}
    </>
  );
}
