import React from "react";
import {
  Tr,
  Td,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import CustomButton from "../../CustomButton/CustomButton";

const PushNotificationsRow = ({
  _id,
  title,
  details,
  createdAt,
  showDate,
  onEdit,
  onDelete,
  onSend,
  actions = false,
  showId = false,
}) => {
  // Responsive text size and hiding table cells on small screens
  const textSize = useBreakpointValue({ base: "xs", md: "sm" });
  const hideOnSmallScreens = useBreakpointValue({
    base: "none",
    md: "table-cell",
  });

  return (
    <Tr>
      {/* Show ID column conditionally */}
      {_id && showId && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          cursor="pointer"
        >
          #{_id?.slice(0, 7)}
        </Td>
      )}

      {/* Show the date added, conditionally hide on small screens */}
      {showDate && (
        <Td
          fontSize={textSize}
          display={hideOnSmallScreens}
          whiteSpace="pre-wrap"
          wordWrap="break-word"
          textOverflow="ellipsis"
        >
          {dayjs(createdAt).format("DD-MM-YYYY")}
        </Td>
      )}

      {/* Title column */}
      {title && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap="break-word"
          textOverflow="ellipsis"
        >
          {title}
        </Td>
      )}
      {/* Details column */}
      {details && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap="break-word"
          textOverflow="ellipsis"
        >
          {details}
        </Td>
      )}

      {/* Actions column */}
      {actions && (
        <Td>
          {/* Stack to make buttons responsive with space between them */}
          <Stack direction={{ base: "column", md: "row" }} spacing={3}>
            <CustomButton title="Send" onClick={onSend} bg="#25BC9D" py={20} px={60}/>
            <CustomButton title="Edit" onClick={onEdit}  py={20} px={60} />
            <CustomButton title="Delete" onClick={onDelete} bg="#E22937"  py={20} px={60}/>
          </Stack>
        </Td>
      )}
    </Tr>
  );
};

export default PushNotificationsRow;
