import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import NoDataComponent from "../../../components/utils/NoDataComponent";
import { HStack, SimpleGrid, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import { GetVehicleByIdApi } from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import * as animationData from "../../../Assets/Icons/ShakeHand.json";
import TableComp from "../../../components/Table/TableComp";
import CarRow from "../../../components/car/CarRow/CarRow";
import BackButton from "../../../components/BackButton/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import CustCard from "../../../components/utils/CustCard";

export default function CarsLoanScreen() {
  const location = useLocation();
  const { id } = location.state || {};
  const { logged } = useAuth();
  const [vehicles, setVehicles] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true);
      try {
        const response = await GetVehicleByIdApi(id);
        setVehicles(response.data);
      } catch (error) {
        console.error("Failed to fetch vehicles", error);
      }
      setLoading(false);
    };

    fetchVehicles();
  }, [id]);

  const handleCheckboxChange = () => {};
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const titles = [
    { title: "Request Id" },
    { title: "Requested By" },
    { title: "Submission Date" },
    { title: "Submission Time" },
    { title: "Status" },
  ];

  const cards = [
    {
      title: "0",
      subtitle: "Total Applied Users",
    },
    {
      title: "$0",
      subtitle: "Total Approved Loan",
    },
  ];

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <BackButton />
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={"46px"}
                opacity={1}
              >
                Car Loan{" "}
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"95%"} h={"auto"} minHeight={"100vh"} py={"8"}>
        {loading ? (
          <CustSpinner />
        ) : (
          <VStack w={"95%"} h={"auto"} py={"8"}>
            <HStack
              h={"fit-content"}
              w={"100%"}
              justify={"space-between"}
              align={"center"}
              p={2}
              m={1}
            >
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                w={"fit-content"}
                alignSelf={"flex-start"}
                justifyItems={"flex-start"}
                py={12}
                p={15}
                spacing={5}
                rowGap={50}
              >
                {cards.map((item, index) => (
                  <CustCard
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                  />
                ))}
              </SimpleGrid>
            </HStack>

            <HStack
              h={"fit-content"}
              w={"100%"}
              justify={"flex-start"}
              align={"center"}
              textAlign={"left"}
              p={2}
              mt={10}
            >
              <Text
                fontWeight={"500"}
                color={"#1D1C1A"}
                opacity={"1"}
                fontSize={"25px"}
              >
                Loan Requests
              </Text>
            </HStack>
            {titles ? (
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                justifyItems={"center"}
                alignItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <TableComp title={titles} emptyEColumn={true}>
                  {vehicles?.vehicleDetails &&
                  vehicles.vehicleDetails.length > 0 ? (
                    vehicles.vehicleDetails?.map((vehicle) => (
                      <CarRow
                        key={vehicle._id}
                        _id={vehicle._id}
                        vin={vehicle.vin}
                        miles={vehicle.miles}
                        year={vehicle.year}
                        make={vehicle.make}
                        vehicleModel={vehicle.vehicleModel}
                        price={vehicle.price}
                        buyRequestCount={vehicle.buyRequestCount}
                        tradeRequestCount={vehicle.tradeRequestCount}
                        onCheckboxChange={handleCheckboxChange}
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No Requests available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            ) : (
              <NoDataComponent type="nocars" />
            )}
          </VStack>
        )}
      </VStack>

      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
