import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import NoDataComponent from "../../../components/utils/NoDataComponent";
import { HStack, SimpleGrid, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  DeleteUserApi,
  GetAllUsersDetailsApi,
  SuspendUserApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import CustCard from "../../../components/utils/CustCard";
import TableComp from "../../../components/Table/TableComp";
import BackButton from "../../../components/BackButton/BackButton";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import SearchWithFilter from "../../../components/SearchWithFilter/SearchWithFilter";
import UserRow from "../../../components/car/UserRow/UserRow";
import SuspendModal from "../../../components/SuspendModal/SuspendModal";
import {
  notifyError,
  notifySuccess,
} from "../../../components/utils/Toast/ToastNotifications";

export default function UserManagementScreen() {
  const { logged } = useAuth();
  const [users, setUsers] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);

  const cards = [
    { title: users?.totalUsers || "0", subtitle: "Total Users" },
    { title: users?.subscribedUsers || "0", subtitle: "Subscribed Users" },
    { title: users?.freeUsers || "0", subtitle: "Free Users" },
  ];

  const titles = [
    { title: "Name" },
    { title: "Cell Phone Number" },
    { title: "Email ID" },
    { title: "City" },
    { title: "State" },
    { title: "Account Status" },
    { title: "Joined Date" },
    { title: "Actions" },
  ];

  useEffect(() => {
    if (logged) {
      fetchUsers();
    }
    // eslint-disable-next-line
  }, [logged]);

  const fetchUsers = (values = {}) => {
    if (!logged) return;

    setLoading(true);
    GetAllUsersDetailsApi(values)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((err) => {
        console.error(err);
        setUsers([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleSuspend = (value) => {
    if (!selectedUser) return;

    const newAccountStatus = selectedUser.account_status === 4 ? 1 : 4;

    const values = {
      userId: selectedUser._id,
      account_status: newAccountStatus,
      reason: value?.reason || "",
    };

    SuspendUserApi(values)
      .then((response) => {
        console.log(response);
        notifySuccess(response.message);
        setUsers((prevState) => ({
          ...prevState,
          users: prevState.users.map((user) =>
            user._id === selectedUser._id
              ? { ...user, account_status: newAccountStatus }
              : user
          ),
        }));

        handleCloseModal();
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  const handleDelete = () => {
    if (!selectedUser) return;
    const newAccountStatus = selectedUser.account_status === 4 ? 1 : 4;
    DeleteUserApi({ userId: selectedUser._id })
      .then((response) => {
        notifySuccess(response.message);
        setUsers((prevState) => ({
          ...prevState,
          users: prevState.users.map((user) =>
            user._id === selectedUser._id
              ? { ...user, account_status: newAccountStatus }
              : user
          ),
        }));
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <BackButton />
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={"46px"}
                opacity={1}
              >
                User Management
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"95%"} h={"auto"} py={"8"}>
        {loading ? (
          <CustSpinner />
        ) : (
          <VStack w={"95%"} h={"auto"} minHeight={"100vh"} py={"8"}>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              w={"fit-content"}
              alignSelf={"flex-start"}
              justifyItems={"flex-start"}
              py={12}
              p={15}
              spacing={5}
              rowGap={50}
            >
              {cards.map((item, index) => (
                <CustCard
                  key={index}
                  title={item.title}
                  subtitle={item.subtitle}
                />
              ))}
            </SimpleGrid>
            <HStack
              h={"fit-content"}
              w={"100%"}
              justify={"space-between"}
              align={"center"}
              textAlign={"left"}
              p={2}
              mt={10}
            >
              <Text
                fontWeight={"500"}
                color={"#1D1C1A"}
                opacity={"1"}
                fontSize={"25px"}
              >
                Users Details
              </Text>
              <SearchWithFilter onApplySearchFilter={fetchUsers} />
            </HStack>
            {titles ? (
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                justifyItems={"center"}
                alignItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <TableComp
                  title={titles}
                  pagination={users.pagination}
                  onPageChange={fetchUsers}
                  displayPagination={true}
                >
                  {users && users?.users?.length > 0 ? (
                    users?.users?.map((user) => (
                      <UserRow
                        key={user._id}
                        _id={user._id}
                        name={user.name}
                        phoneNumber={user.phoneNumber}
                        email={user.email}
                        city={user.city}
                        state={user.state}
                        account_status={user.account_status}
                        createdAt={user.createdAt}
                        showDate={true}
                        actions={true}
                        onSuspend={() => {
                          user.account_status === 1
                            ? handleOpenModal(user)
                            : setSelectedUser(user);
                          handleSuspend();
                        }}
                        onDelete={handleDelete}
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No Users available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            ) : (
              <NoDataComponent type="nocars" />
            )}
          </VStack>
        )}
      </VStack>
      <SuspendModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSuspend}
        userData={selectedUser}
      />
      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
