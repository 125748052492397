import React from 'react';
import NavBar from '../../components/home/navBar/NavBar';
import ButtomBar from '../../components/home/footer/BottomBar'
import { Text, VStack } from '@chakra-ui/react';
import GeneralInformation from '../../components/user/GeneralInformation';
import SavedCars from '../../components/user/SavedCars';
import SubscriptionPlanDetails from '../../components/user/SubscriptionPlanDetails';
import SavedCards from '../../components/user/SavedCards';
import ChangePassword from '../../components/user/ChangePassword';
import AuthenticatedLayout from '../../components/auth/AuthenticatedLayout';

export default function ManageProfile() {
  return (
    <AuthenticatedLayout>
     <NavBar />
     <VStack w={'100%'} alignItems={'start'} px={{base:8, lg:16}}>
        <Text fontSize={{base:'lg',md:'2xl'}} color={'#1D1C1A'} fontWeight={600} py={8}>
            Manage Profile
        </Text>

        <GeneralInformation />
{/* 
        <SavedCars />

        <SubscriptionPlanDetails />

        <SavedCards /> */}

        <ChangePassword />
     </VStack>
     <ButtomBar />
    </AuthenticatedLayout>
  )
}
