import React, { useState } from "react";
import {
  Tr,
  Td,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { RiMoreLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { CdnLink } from "../../utils/ProviderFunctions";
import Img from "../../../Assets/Images/defaultCarImage.png";
import dayjs from "dayjs";

const UserSuggestionsRow = ({
  _id,
  city,
  email,
  name,
  phoneNumber,
  suggestions,
  onEdit,
  onDelete,
  checkbox = false,
  actions = false,
  showId = false,
}) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    // onCheckboxChange(checked, _id);
  };

  // Responsive values
  const textSize = useBreakpointValue({ base: "xs", md: "sm" });

  return (
    <Tr>
      {/* Checkbox */}
      {checkbox && (
        <Td>
          <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
        </Td>
      )}

      {_id && showId && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          // onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
        >
          #{_id?.slice(0, 7)}
        </Td>
      )}

      {name && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          // onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {name}
        </Td>
      )}

      {/* phoneNumber */}
      {phoneNumber && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >{`${phoneNumber || "-"}`}</Td>
      )}

      {/* email */}

      {email && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {email || "-"}
        </Td>
      )}

      {/* state */}
      {suggestions && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {suggestions || "-"}
        </Td>
      )}

      {/* Actions */}
      {actions && (
        <Td>
          <Menu>
            <MenuButton>
              <RiMoreLine />
            </MenuButton>
            <MenuList minWidth="120px">
              <MenuItem onClick={onEdit}>
                <Text>Edit</Text>
              </MenuItem>
              <MenuItem onClick={onDelete}>
                <Text color="#FC4656">Delete</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
    </Tr>
  );
};

export default UserSuggestionsRow;
