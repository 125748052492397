import { createContext, useContext, useEffect, useMemo, useState } from "react";
import Cookies from 'js-cookie';
import { LogOutApi } from "../Axios/Api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [logged, setLogged] = useState(false);

    const handleSetToken = (newToken) => {
        if (newToken == null) {
            Cookies.remove("Token");
        } else {
            Cookies.set('Token', newToken, { 
                expires: 7, 
                secure: true ,
                sameSite: 'Lax' 
            });
        }
    };

    const handleSetUser = (userData) => {
        setUser(userData);
        if (userData == null) {
            localStorage.removeItem("user");
        } else {
            localStorage.setItem("user", JSON.stringify(userData));
        }
    };

    const login = (userData, token) => {
        handleSetUser(userData);
        handleSetToken(token);
        setLogged(true);
        setLoading(false);
    };

    const logout = () => {
        handleSetToken(null);
        handleSetUser(null);
        setLogged(false);
        setLoading(false);
        LogOutApi()
        .then((response)=>{
            console.log('Logout response:', response);
        })
        .catch((error)=>{
            console.log('Logout error:', error.response);
        });
    };

    const updateUserData = (key, value) => {
        setUser(prevUser => {
            const updatedUser = { ...prevUser, [key]: value };
            localStorage.setItem("user", JSON.stringify(updatedUser));
            return updatedUser;
        });
    };

    useEffect(() => {
        const tkn = Cookies.get("Token");
        const usr = localStorage.getItem("user");

        if (tkn && tkn !== "null" && usr && usr !== "null") {
            handleSetToken(tkn);
            handleSetUser(JSON.parse(usr));
            setLogged(true);
        } else {
            handleSetToken(null);
            handleSetUser(null);
            setLogged(false);
        }
        setLoading(false);
    }, []);

    const contextValue = useMemo(() => ({
        loading,
        logged,
        token: Cookies.get("Token"),
        user,
        login,
        logout,
        updateUserData,
    }), [loading, logged, user]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
