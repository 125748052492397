import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import {  Box, HStack, SimpleGrid, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  CreateNewPlanApi,
  CreateNewReportApi,
  DeleteSubscriptionPlanApi,
  GetAllSubscriptionPlansApi,
  UpdatePlanApi,
  UpdateReportApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import CustomButton from "../../../components/CustomButton/CustomButton";
import TableComp from "../../../components/Table/TableComp";
import { notifyError, notifySuccess } from "../../../components/utils/Toast/ToastNotifications";
import SubscriptionUserRow from "../../../components/car/SubscriptionRows/SubscriptionUserRow";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton/BackButton";
import SubscriptionPlansModal from "../../../components/SubscriptionPlansModal/SubscriptionPlansModal";

export default function ManagePlansScreen() {
  const { logged } = useAuth();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const navigate = useNavigate();
  const titles = [
    { title: "ID" },
    { title: "Plan Name" },
    { title: "Amount" },
    { title: "No. of Days" },
    { title: "Status" },
  ];

  const handleOpenModal = (report = null) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  const handleSubmit = (reportData) => {
    if (selectedReport) {
      UpdatePlanApi({ ...selectedReport, ...reportData })
        .then((response) => {
          notifySuccess(response.message);
          setPlans((prevReports) =>
            prevReports.map((item) =>
              item._id === selectedReport._id ? { ...item, ...reportData } : item
            )
          );
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    } else {
      // Create new Plan
      CreateNewPlanApi(reportData)
        .then((response) => {
          notifySuccess(response.message);
          setPlans((prevReports) => [...prevReports, response.data]);
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    }
    handleCloseModal();
  };



  const fetchReports = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await GetAllSubscriptionPlansApi();
      if (response.data) {
        setPlans(response.data);
      } else {
        setPlans([]);
      }
    } catch (error) {
      setError("Failed to fetch Plans. Please try again later.");
    }
    setLoading(false);
  };
  
  useEffect(() => {
    fetchReports();
  }, []);

  const handleDeletePlan = (id) => {
    const ids = [id];
    DeleteSubscriptionPlanApi(ids)
      .then((response) => {
        notifySuccess(response.message);
        setPlans((prevFaqs) => prevFaqs.filter((item) => item._id !== id));
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };


  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={{ base: "28px", md: "40px", lg: "46px" }}
                opacity={1}
              >
                 <BackButton />
                Manage Your Plans
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"100%"} height={'100%'} spacing={0}>
        {loading ? (
          <CustSpinner />
        ) : error ? (
          <Text color="red.500" py={8}>
            {error}
          </Text>
        ) : plans?.length === 0 ? (
          <Text color="gray.500" py={8}>
            No Plans available at the moment.
          </Text>
        ) : (
          <>
          
            <VStack w={"95%"} h={"auto"} minHeight={"100vh"} py={"8"}>
            <HStack
              w={"100%"}
              justifyContent={"flex-end"}
              justifyItems={'center'}
              alignItems={'flex-start'}
              alignContent={'center'}
              backgroundColor={"#fff"}
              pt={{ base: 4, md: 8 }}
              px={{ base: 4, md: 8, lg: 0 }}
              // flexWrap={'wrap'}
              flexDirection={{ base: "column", md: "row" }}
            >
            <Box mt={{ base: 4, md: 0 }} alignSelf={{ base: "center", md: "flex-start" }}>
                  <CustomButton
                    title={"Add New Plan"}
                    onClick={() => handleOpenModal()}
                  />
                </Box>
            </HStack>
            <HStack
              h={"fit-content"}
              w={"100%"}
              justify={'space-between'}
              align={"center"}
              textAlign={"left"}
              p={2}
              mt={10}
            >
              <Text
                fontWeight={"500"}
                color={"#1D1C1A"}
                opacity={"1"}
                fontSize={"25px"}
              >
                Available Plans
              </Text>

            </HStack>
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                justifyItems={"center"}
                alignItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <TableComp
                  title={titles}
                  
                >
                  {plans?.length > 0 ? (
                    plans?.map((item) => (
                      <SubscriptionUserRow
                        key={item._id}
                        _id={item._id}
                        showId={true}
                        name={item?.planName || "-"}
                        dateOfSub={item.dateOfSubscription}
                        amount={item?.amount || "0"}
                        noOfDays={item?.no_of_days || "0"}
                        status={item.status}
                        actions={true}
                        onEdit={() => handleOpenModal(item)}
                        onDelete={() => handleDeletePlan(item._id)}
                        
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No Plans available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            </VStack>
          </>
        )}
      </VStack>
      <SubscriptionPlansModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        reportsData={selectedReport}
      />
      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
