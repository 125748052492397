import React, { useEffect, useState } from "react";
import { Flex, Box, Image, VStack } from "@chakra-ui/react";
import ButtomBar from "../home/footer/BottomBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";

export default function NotAuthPendingLayout({ children }) {
  const { logged, user } = useAuth();
  const navigate = useNavigate();

  const [proceed, setProceed] = useState(false);

  useEffect(() => {
    if (!logged) {
      setProceed(true);
    } else if (logged && user.account_status === 0) {
      navigate("/verificationcode");
    } else {
      navigate("/");
    }
     // eslint-disable-next-line
  }, [logged]);

  return (
    <>
      {proceed && !logged && (
        <Box w="100vw" h="100vh">
          <Flex
            w="100%"
            h={{ base: "100%", md: "100%" }}
            style={{
              backgroundImage: "url('./auth_bg.png')",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
            justifyContent={["end", "end", "end", "end"]}
          >
            <Box w={{ base: "100%", sm: "60%", md: "40%" }}>
              {/* <Text mx={8} my={8} pr={12} color={'#FFFFFF'} align={{ base: 'center', md: 'right' }}>
                        Questions? Call us: <span style={{ color: '#25BC9D' }}> +1 (949) 702-7156</span>
                    </Text> */}
              <VStack w="100%" my={12} px={{ base: 4, lg: 12 }} align={"start"}>
                <Image src="/auth_logo.png" h={140} />
                {children}
              </VStack>
            </Box>
          </Flex>
          <ButtomBar />
        </Box>
      )}
    </>
  );
}
