import React, { useState, useCallback } from 'react';
import { 
  InputGroup, 
  InputLeftElement, 
  InputRightElement, 
  Input, 
  IconButton, 
  Popover, 
  PopoverTrigger, 
  PopoverContent, 
  PopoverArrow, 
  PopoverBody, 
  VStack, 
  Select,
  Button,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai'; 
import { FiFilter } from 'react-icons/fi'; 
import { debounce } from 'lodash';
import CustomButton from '../CustomButton/CustomButton';
import { states } from '../utils/DataArray';

const SearchWithFilter = ({ onApplySearchFilter }) => {
  const [searchText, setSearchText] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [account_status, setAccountStatus] = useState('');

  const debouncedSearch = useCallback(
    debounce((search) => {
      onApplySearchFilter({ search, city, state, account_status });
    }, 500),
    [onApplySearchFilter]
  );

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    debouncedSearch(value); 
  };

  const handleApplyFilter = () => {
    onApplySearchFilter({  city, state, account_status });
  };

  return (
    <InputGroup maxW="400px" width="100%" zIndex="1"> 
      <InputLeftElement pointerEvents="none">
        <AiOutlineSearch color="#707070" />
      </InputLeftElement>
      
      <Input
        type="text"
        placeholder="Search..."
        value={searchText}
        onChange={handleChange}
        focusBorderColor="#707070"
        borderColor="#707070"
        borderRadius="100px"
        borderWidth="2px"
        _placeholder={{ color: '#707070' }}
      />
      
      <InputRightElement>
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <IconButton
              icon={<FiFilter />}
              aria-label="Filter"
              bg="transparent"
              color="#707070"
              _hover={{ bg: 'gray.100' }}
              borderEndRadius={100}
            />
          </PopoverTrigger>
          <PopoverContent w="400px" p={4} zIndex="2" borderRadius={25}> 
            <PopoverArrow />
            <PopoverBody>
              <VStack spacing={4}>
                <Input
                  placeholder="Enter city"
                  value={city}
                  onChange={(e) => setCity(e.target.value || '')}
                  focusBorderColor="#707070"
                  borderColor="#D9D9D9"
                  borderRadius={26}
                  />
                <Select
                 position={'relative'}
                 placeholder="Select state"
                 value={state}
                 onChange={(e) => setState(e.target.value || '')}
                 borderColor="#D9D9D9"
                 borderRadius={26}
                 focusBorderColor="#707070"
                 >{
                  states.map((item,i)=><option value={item.value}>{item.label}</option>)
                  }
                </Select>
                <Select
                  placeholder="Select account status"
                  value={account_status}
                  onChange={(e) => setAccountStatus(e.target.value || '')}
                  borderRadius={26}
                  focusBorderColor="#707070"
                  borderColor="#D9D9D9"
                >
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                  <option value={0}>Pending</option>
                </Select>

                <CustomButton title={'Apply Filter'} py={0} px={120}  onClick={handleApplyFilter}/>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchWithFilter;
