import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import Quill from 'quill';
import "react-quill/dist/quill.snow.css";
import { VStack, Button } from "@chakra-ui/react";
import QuillTable from 'quill-table';
import ImageResize from "quill-image-resize-module-react";
import './style.css';
import CustomButton from "../CustomButton/CustomButton";
const QuillEditor = ({ initialValue,placeholder, onSave }) => {
  const [value, setValue] = useState(initialValue || "");

  const handleSave = () => {
    onSave(value);
  };

  Quill.register({
    'modules/table': QuillTable,
    'modules/imageResize': ImageResize,
  });

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
      [{ font: [] }],
      ["clean"]
    ],
    
    imageResize: {
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };

  const formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  useEffect(() => {
    ReactQuill.Quill.register("modules/imageResize", ImageResize);
  }, []);

  return (
    <VStack
      w="80%"
      h="100%"
      align="center"
      justify='center'  
      spacing={5} 
    >
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={setValue}
        placeholder={placeholder}
        style={{
          minHeight: "80%",
          padding: "10px",
          borderRadius: "48px",
          width: "100%",
          alignSelf:'center'
        }}
      />
      <CustomButton
      bg={'#E22937'}
      onClick={handleSave}
      title={'Save'}
      px={120}
      />
    </VStack>
  );
};

export default QuillEditor;
