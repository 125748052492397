import React from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import { HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import CustNavCard from "../../../components/utils/CustNavCard";
import { LiaUserEditSolid } from "react-icons/lia";
import { LiaBell } from "react-icons/lia";


export default function SettingsScreen() {
  const { logged } = useAuth();


  





  const cards = [
    {
      title: "Push Notification",
      icon: <LiaBell size={60} color="#fff" />,
      goto: "/push-notifications",
    },
    {
      title: "Manage Profile",
      icon: <LiaUserEditSolid size={60} color="#fff" />,
      goto: "/manage-profile",
    },
  ];

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={"46px"}
                opacity={1}
              >
                Settings{" "}
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"100%"} h={"auto"} minHeight={"100vh"} py={"8"} px={"20"}>

          <VStack w={"100%"} h={"auto"} py={"8"}>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              w={"100%"}
              alignSelf={"flex-start"}
              justifyContent={'flex-start'}
              justifyItems={"flex-start"}
              py={12}
              p={15}
              spacing={{ base: 55, md: 185, lg: 255 }}
              rowGap={50}
            >
              {cards.map((item, index) => (
                <CustNavCard
                  key={index}
                  title={item.title}
                  icon={item.icon}
                  goto={item.goto}
                />
              ))}
            </SimpleGrid>
          </VStack>

      </VStack>

      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
