import React from 'react'
import {
    VStack,
    Heading,
    Text,
    Button,
    FormControl,
    Input,
    FormErrorMessage,

} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import NotAuthLayout from '../../components/auth/NotAuthLayout';
import { notifyError, notifySuccess } from '../../components/utils/Toast/ToastNotifications';
import { ForgotPasswordAPI } from '../../Axios/Api';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
    });
    
    const formik = useFormik({
        initialValues: {
            email: "",
        },
    
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur:true,
        onSubmit: (values) => {
            ForgotPasswordAPI(values)
                .then((response) => {
                    notifySuccess(response.message);
                    navigate('/verificationcode', { state: { refId: response.data.id ,email:formik.values.email,vrf:0} });
                }).catch((error) => {
                    notifyError(error.response.data.message);
                });
        }
    });

    return (
        <NotAuthLayout>
             <VStack w='100%' align={'start'} my={6}>
                    <VStack color={'#ffffff'} align={'start'}>
                        <Heading fontSize='2xl' fontWeight={'bold'}>Forgot Password</Heading>
                        <Text fontSize={'sm'} mb={2} opacity={'0.7'}>Please submit your email address to reset.</Text>
                    </VStack>

                    <FormControl isInvalid={formik.errors.email && formik.touched.email}>
                        <Input
                            type='email'
                            id='email'
                            name='email'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} 
                            value={formik.values.email}
                            required={true}
                            rounded={34}
                            bg={'#FFFFFF'}
                            placeholder='Email address'
                            py={6}
                            fontSize={'sm'}
                            sx={{ textTransform: 'lowercase' }}

                        />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>

                    <Button mt={4} type='submit' 
                     onClick={() => {
                            formik.handleSubmit();
                            
                        }} 
                        colorScheme="red" py={6} rounded={'34px'} width="200px" >
                        Submit
                    </Button>
                    <Link to='/login'>
                        <Text mt={2} color={'#FFFFFF'} align={'right'} opacity={'0.7'} fontSize={'sm'}>Remember Password?<span
                            style={{ color: '#25BC9D', fontWeight: '500' }}> Signin</span></Text>
                    </Link>
                </VStack>
        </NotAuthLayout>
    );
}
