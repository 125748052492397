import React, { useState } from "react";
import {
  Tr,
  Td,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { RiMoreLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { CdnLink } from "../../utils/ProviderFunctions";
import Img from "../../../Assets/Images/defaultCarImage.png";
import dayjs from "dayjs";

const BuyRequestRow = ({
  _id,
  city,
  email,
  name,
  phoneNumber,
  state,
  createdAt,
  onCheckboxChange,
  onEdit,
  onDelete,
  showTime = false,
  showDate = false,
  account_status,
  checkbox = false,
  actions = false,
  showId = false,
}) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    onCheckboxChange(checked, _id);
  };

  // Responsive values
  const textSize = useBreakpointValue({ base: "xs", md: "sm" });
  const hideOnSmallScreens = useBreakpointValue({
    base: "none",
    md: "table-cell",
  });

  return (
    <Tr>
      {/* Checkbox */}
      {checkbox && (
        <Td>
          <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
        </Td>
      )}

      {_id && showId && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
        >
          #{_id?.slice(0, 7)}
        </Td>
      )}

      {name && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {name}
        </Td>
      )}

      {/* phoneNumber */}
      {phoneNumber && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >{`${phoneNumber || "-"}`}</Td>
      )}

      {/* email */}

      {email && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {email || "-"}
        </Td>
      )}

      {/* state */}
      {city && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {city || "-"}
        </Td>
      )}

      {/* state */}
      {state && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {state || "-"}
        </Td>
      )}

      {/* account_status */}
      {account_status !== undefined && (
  <Td
    fontSize={textSize}
    color={account_status === 1 ? "#25BC9D" : "#BCB025"}
    cursor="pointer"
    whiteSpace="pre-wrap"
    wordWrap={true}
    textOverflow="ellipsis"
  >
    {account_status === 1 ? "Active" : "Inactive"}
  </Td>
)}


      {/* Date Added (hidden on small screens) */}
      {showDate && (
        <Td
          fontSize={textSize}
          display={hideOnSmallScreens}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {dayjs(createdAt).format("DD-MM-YYYY")}
        </Td>
      )}

      {/* Time Added (hidden on small screens) */}
      {showTime && (
        <Td
          fontSize={textSize}
          display={hideOnSmallScreens}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {dayjs(createdAt).format("hh:mm A")}
        </Td>
      )}

      {/* Actions */}
      {actions && (
        <Td>
          <Menu>
            <MenuButton>
              <RiMoreLine />
            </MenuButton>
            <MenuList minWidth="120px">
              <MenuItem onClick={onEdit}>
                <Text>Edit</Text>
              </MenuItem>
              <MenuItem onClick={onDelete}>
                <Text color="#FC4656">Delete</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
    </Tr>
  );
};

export default BuyRequestRow;
