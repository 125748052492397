import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import { Box, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  CreateNewFaqApi,
  DeleteFaqApi,
  GetAllFaqApi,
  UpdateFaqApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import BackButton from "../../../components/BackButton/BackButton";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CustFaqCard from "../../../components/utils/CustFaqCard";
import FaqModal from "../../../components/FaqModal/FaqModal";
import {
  notifyError,
  notifySuccess,
} from "../../../components/utils/Toast/ToastNotifications";

export default function FaqScreen() {
  const { logged } = useAuth();
  const [faq, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  
  const handleOpenModal = (faq = null) => {
    setSelectedFaq(faq);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFaq(null);
  };

  const handleSubmit = (faq) => {
    if (selectedFaq) {
      UpdateFaqApi(faq)
        .then((response) => {
          notifySuccess(response.message);
          setFaqs((prevFaqs) =>
            prevFaqs.map((item) =>
              item._id === selectedFaq._id ? { ...faq, id: item._id } : item
            )
          );
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    } else {
      CreateNewFaqApi(faq)
        .then((response) => {
          notifySuccess(response.message);
          const newFaq = { ...faq, id: Date.now() };
          setFaqs((prevFaqs) => [...prevFaqs, newFaq]);
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    }
  };

  const handleDeleteFaq = (id) => {
    DeleteFaqApi(id)
      .then((response) => {
        notifySuccess(response.message);
        setFaqs((prevFaqs) => prevFaqs.filter((item) => item._id !== id));
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  useEffect(() => {
    const fetchFaqs = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await GetAllFaqApi();
        if (response?.data) {
          setFaqs(response.data);
        } else {
          setFaqs([]);
        }
      } catch (error) {
        setError("Failed to fetch FAQs. Please try again later.");
      }
      setLoading(false);
    };

    fetchFaqs();
  }, []);

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={{ base: "28px", md: "40px", lg: "46px" }}
                opacity={1}
              >
                <BackButton />
                FAQ
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"100%"} minHeight={"100vh"} spacing={0}>
        {loading && faq ? (
          <CustSpinner />
        ) : error ? (
          <Text color="red.500" py={8}>
            {error}
          </Text>
        ) : faq.length === 0 ? (
          <Text color="gray.500" py={8}>
            No FAQs available at the moment.
          </Text>
        ) : (
          <>
            <HStack
              w={"100%"}
              justifyContent={"flex-end"}
              backgroundColor={"#fff"}
              pt={{ base: 4, md: 8 }}
              px={{ base: 4, md: 8, lg: 20 }}
            >
              <CustomButton
                title={"Create New Faq"}
                onClick={() => handleOpenModal()}
              />
            </HStack>
            <Box
              w={"100%"}
              h={"100%"}
              maxHeight={"100vh"}
              overflowY={"auto"}
              py={{ base: 4, md: 8 }}
              px={{ base: 4, md: 8, lg: 20 }}
              css={{
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "transparent",
                },
              }}
            >
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                spacing={{ base: 6, md: 8 }}
                css={{
                  backgroundColor: "white",
                }}
              >
                {faq.map((item, index) => {
                  return (
                    <CustFaqCard
                      key={index}
                      title={item.question}
                      subtitle={item.answer}
                      onEdit={() => handleOpenModal(item)}
                      onDelete={() => handleDeleteFaq(item._id)}
                    />
                  );
                })}
              </SimpleGrid>
            </Box>
          </>
        )}
      </VStack>
      <FaqModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        faqData={selectedFaq}
      />
      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
