import React from 'react'
import {
    VStack,
    Heading,
    Text,
    Button,
    FormControl,
    Input,
    FormErrorMessage,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordApi } from '../../Axios/Api';
import { notifyError, notifySuccess } from '../../components/utils/Toast/ToastNotifications';
import NotAuthPendingLayout from '../../components/auth/NotAuthPendingLayout';

export default function CreateNewPassword() {
    const location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state || {};

    // Define the validation schema
    const validationSchema = Yup.object({
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: email,
            password: "",
            confirmPassword: "",
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur:true,
        onSubmit: async (values) => {
            ResetPasswordApi(values).then((response) => {
                notifySuccess(response.message);
                navigate('/login');
            }).catch((error) => {
                notifyError(error.response.data.message)
            });
        }
    });

    return (
        <NotAuthPendingLayout>
            <form onSubmit={formik.handleSubmit}>
                <VStack w='100%' align={'start'} my={6}>
                    <VStack color={'#ffffff'} alignItems={'start'}>
                        <Heading fontSize='2xl' fontWeight={'bold'}>Create New Password</Heading>
                        <Text fontSize='sm' mb={2} opacity={'0.7'}>Your new password must be different from previously used passwords</Text>
                    </VStack>
                    <FormControl isInvalid={formik.errors.password && formik.touched.password}>
                        <Input
                            type='password'
                            id='password'
                            name='password'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            required={true}
                            rounded={34}
                            bg={'#FFFFFF'}
                            placeholder='Password'
                            py={6}
                            fontSize={'sm'}
                        />
                        <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                    </FormControl>
                    
                    <FormControl isInvalid={formik.errors.confirmPassword && formik.touched.confirmPassword}>
                        <Input
                            type='password'
                            id='confirmPassword'
                            name='confirmPassword'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            required={true}
                            rounded={34}
                            bg={'#FFFFFF'}
                            placeholder='Confirm Password'
                            py={6}
                            fontSize={'sm'}
                        />
                        <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                    </FormControl>

                    <Button type='submit'
                        mt={5}
                        colorScheme="red" py={6} rounded={'34px'} width="200px" >
                        Submit
                    </Button>
                </VStack>
            </form>
        </NotAuthPendingLayout>
    );
}
