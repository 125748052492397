import {
  Card,
  Tbody,
  Tr,
  Thead,
  Table,
  Th,
  Checkbox,
  TableContainer,
  Divider,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Pagination from "../Pagination/Pagination";

const TableComp = ({
  title,
  children,
  emptySColumnCount = 0,
  emptyEColumnCount = 0,
  checkBox = false,
  pagination = { currentPage: 1, totalPages: 1, pageSize: 10, totalItems: 7 },
  displayPagination = false,
  onPageChange,
}) => {
  const cardPadding = useBreakpointValue({ base: 4, md: 6, lg: 10 });
  const tableSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });
  const checkboxWidth = useBreakpointValue({ base: "5%", md: "9%" });
  const emptyColumnWidth = useBreakpointValue({ base: "10%", md: "20%" });
  const fontSize = useBreakpointValue({ base: "xs", md: "sm", lg: "md" });

  const [currentPage, setCurrentPage] = useState(pagination.currentPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (onPageChange) {
      onPageChange({ page: newPage });
    }
  };

  const renderEmptyColumns = (count) => {
    const columns = [];
    for (let i = 0; i < count; i++) {
      columns.push(<Th key={`empty-${i}`} w={emptyColumnWidth}></Th>);
    }
    return columns;
  };

  return (
    <Card
      backgroundColor={"#fff"}
      borderRadius={"35px"}
      style={{
        boxShadow: "0px 10px 99px #00000029",
      }}
      textAlign={"center"}
      alignSelf={"center"}
      w={"100%"}
      h={"auto"}
      p={cardPadding}
    >
      <TableContainer
        w={"full"}
        maxH={"80vh"}
        overflowY={"auto"}
        overflowX={"auto"}
      >
        <Table variant="striped" size={tableSize}>
          <Thead>
            <Tr>
              {checkBox && (
                <Th w={checkboxWidth}>
                  <Checkbox />
                </Th>
              )}
              {renderEmptyColumns(emptySColumnCount)}
              {title &&
                title.map((item, index) => (
                  <Th
                    key={index}
                    color={"#000"}
                    fontSize={fontSize}
                    whiteSpace="pre-wrap"
                    wordWrap={true}
                    textOverflow="ellipsis"
                  >
                    {item.title}
                  </Th>
                ))}
              {renderEmptyColumns(emptyEColumnCount)}
            </Tr>
          </Thead>
          <Divider />
          <Tbody>{children}</Tbody>
        </Table>
      </TableContainer>

      {/* Pagination Component */}
      {displayPagination && (
        <Pagination
          currentPage={currentPage}
          totalPages={pagination.totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Card>
  );
};

export default TableComp;
