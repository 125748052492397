import {
  Button,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Divider,
  Skeleton,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Avatar from "../../Assets/Images/Avatar.jpg";
import { UpdateUserDataApi, UploadUserProfileApi } from "../../Axios/Api";
import { notifyError, notifySuccess } from "../utils/Toast/ToastNotifications";
import { useAuth } from "../../providers/AuthProvider";
import { CdnLink } from "../utils/ProviderFunctions";

const validationSchema = Yup.object({
  fname: Yup.string().required("First Name is required"),
  lname: Yup.string().required("Last Name is required"),
  phone: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "Phone number is not valid"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
});

export default function GeneralInformation() {
  const { user, updateUserData } = useAuth();
  const [profileImage, setProfileImage] = useState(
    CdnLink(user?.pic) || Avatar
  );
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      fname: user?.firstName || "",
      lname: user?.lastName || "",
      phone: user?.phoneNumber || "",
      email: user?.email || "",
      city: user?.city || "",
      state: user?.state || "",
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      UpdateUserDataApi(values)
        .then((response) => {
          updateUserData(response.data);
          notifySuccess(response?.message);
        })
        .catch((error) => {
          notifyError(
            error.response?.data?.message || "Unable to Update Profile"
          );
        });
    },
  });

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append("profile", event.target.files[0]);
      setLoading(true);
      UploadUserProfileApi({ formData })
        .then((response) => {
          if (response.data.key) {
            updateUserData("pic", response.data.key);
            setProfileImage(response.data.location);
            notifySuccess(response.data.message);
          } else {
            notifyError(response.data.message);
          }
        })
        .catch((error) => {
          const errorMessage =
            error?.response?.data?.message || "Error uploading profile picture";
          notifyError(errorMessage);
        });
      setLoading(false);
    }
  };

  return (
    <VStack
      p={4}
      w="100%"
      alignItems="start"
      bg="#FBFBFB"
      mb={8}
      as="form"
      onSubmit={formik.handleSubmit}
    >
      <Text color="#575757" fontSize="sm" fontWeight={600} pb={4}>
        General Information
      </Text>
      <Divider
        borderWidth="1px"
        borderColor="#EDECF1"
        alignItems="center"
        w="100%"
      />

      <Stack
        direction={{ base: "column-reverse", md: "row" }}
        w={{ base: "100%", md: "90%", lg: "75%" }}
        justifyContent="space-between"
        pt={2}
        alignItems="center"
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing="6"
          justifyContent="space-around"
          py={4}
          px={{ base: 4, md: 0 }}
          w={{ base: "100%", md: "85%", lg: "75%" }}
        >
          <VStack align="start">
            <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">
              First Name
            </Text>
            <FormControl
              isInvalid={formik.errors.fname && formik.touched.fname}
            >
              <Input
                type="text"
                id="fname"
                name="fname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fname}
                rounded={26}
                placeholder="Your First name"
                py={4}
                fontSize="xs"
                bg="#FFFFFF"
              />
              <FormErrorMessage>{formik.errors.fname}</FormErrorMessage>
            </FormControl>
          </VStack>

          <VStack align="start">
            <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">
              Last Name
            </Text>
            <FormControl
              isInvalid={formik.errors.lname && formik.touched.lname}
            >
              <Input
                type="text"
                id="lname"
                name="lname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lname}
                rounded={26}
                placeholder="Your Last Name"
                py={4}
                fontSize="xs"
                bg="#FFFFFF"
              />
              <FormErrorMessage>{formik.errors.lname}</FormErrorMessage>
            </FormControl>
          </VStack>

          <VStack align="start">
            <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">
              Email
            </Text>
            <FormControl
              isInvalid={formik.errors.email && formik.touched.email}
            >
              <Input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                rounded={26}
                placeholder="Your Email"
                py={4}
                fontSize="xs"
                bg="#FFFFFF"
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
          </VStack>

          <VStack align="start">
            <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">
              Mobile Number
            </Text>
            <FormControl
              isInvalid={formik.errors.phone && formik.touched.phone}
            >
              <Input
                type="text"
                id="phone"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                rounded={26}
                placeholder="Your Phone Number"
                py={4}
                fontSize="xs"
                bg="#FFFFFF"
              />
              <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>
          </VStack>

          <VStack align="start">
            <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">
              City
            </Text>
            <FormControl isInvalid={formik.errors.city && formik.touched.city}>
              <Input
                type="text"
                id="city"
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                rounded={26}
                placeholder="City"
                py={4}
                fontSize="xs"
                bg="#FFFFFF"
              />
              <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
            </FormControl>
          </VStack>

          <VStack align="start">
            <Text fontSize="xs" color="#707070" opacity="0.8" fontWeight="400">
              State
            </Text>
            <FormControl
              isInvalid={formik.errors.state && formik.touched.state}
            >
              <Input
                type="text"
                id="state"
                name="state"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                rounded={26}
                placeholder="State"
                py={4}
                fontSize="xs"
                bg="#FFFFFF"
              />
              <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
            </FormControl>
          </VStack>
        </SimpleGrid>

        <VStack spacing={0} pt={2}>
          {loading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <Image
              src={CdnLink(user?.pic) || Avatar}
              h={120}
              w={120}
              borderRadius={100}
              loading="lazy"
              objectFit="cover"
              alt="User Profile"
              fallback={<Skeleton height="120px" borderRadius={100} />}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="imageUpload"
            disabled={loading}
          />
          <Button
            as="label"
            htmlFor="imageUpload"
            fontSize="xs"
            colorScheme="teal"
            disabled={loading}
          >
            Upload
          </Button>
        </VStack>
      </Stack>

      <Button
        fontSize="xs"
        type="submit"
        colorScheme="red"
        rounded="22px"
        w={120}
        h={10}
        style={{ boxShadow: "0px 10px 20px #00000029" }}
      >
        Update
      </Button>
    </VStack>
  );
}
