
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Register from './pages/auth/Register';
import FinalRegister from './pages/auth/FinalRegister';
import RegisterDone from './pages/auth/RegisterDone';
import VerificationCode from './pages/auth/VerificationCode';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
// import Home from './pages/home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import Faq from './components/sections/Faq';
import TermsAndCondition from './components/sections/TermsAndCondition';
import PrivacyPolicy from './components/sections/PrivacyPolicy';
import PrivateRoutes from './components/utils/PrivateRoutes';
import AuthProvider from './providers/AuthProvider';
import GetALoanCar from './pages/home/GetALoanCar';
import SellACar from './pages/home/SellACar';
import CarDetails from './pages/home/CarDetails';
import SubscriptionPlan from './pages/home/SubscriptionPlan';
import MarketValue from './pages/home/MarketValue';
import AllRequest from './pages/home/AllRequest';
import ManageProfile from './pages/user/ManageProfile';
import UserDetails from './pages/user/UserDetails';
import NoCarAvailable from './pages/home/NoCarAvailable';
import RequestTrade from './pages/home/RequestTrade';
import ToastNotifications from './components/utils/Toast/ToastNotifications';
import CreateNewPassword from './pages/auth/CreateNewPassword';
import CarsScreen from './pages/Dashboard/Cars/CarsScreen';
import CarsBuyRequestScreen from './pages/Dashboard/Cars/CarsBuyRequestScreen';
import CarsTradeRequestScreen from './pages/Dashboard/Cars/CarsTradeRequestScreen';
import CarsLoanScreen from './pages/Dashboard/Cars/CarsLoanScreen';
import UserManagementScreen from './pages/Dashboard/User/UserManagementScreen';
import ContentManagementScreen from './pages/Dashboard/Content Management/ContentManagementScreen';
import FaqScreen from './pages/Dashboard/Content Management/FaqScreen';
import PageNotFound from './components/404/PageNotFound';
import UserSuggestionsScreen from './pages/Dashboard/Content Management/UserSuggestionsScreen';
import TCScreen from './pages/Dashboard/Content Management/TCScreen';
import PPScreen from './pages/Dashboard/Content Management/PPScreen';
import ReportsScreen from './pages/Dashboard/Reports/ReportsScreen';
import SubscriptionsScreen from './pages/Dashboard/Subscriptions/SubscriptionsScreen';
import ManagePlansScreen from './pages/Dashboard/Subscriptions/ManagePlansScreen';
import SettingsScreen from './pages/Dashboard/SettingsScreen/SettingsScreen';
import PushNotificationsScreen from './pages/Dashboard/SettingsScreen/PushNotificationsScreen';


function App() {
  return (
    <AuthProvider>
<ToastNotifications />
      <BrowserRouter>
        <Routes>
          {/* Public routes , can access without login  */}
          {/* <Route path='/' element={<Home />} /> */}
          <Route path='/' element={<Dashboard />} />
          <Route path='/cars' element={<CarsScreen />} />
          <Route path='/cars-buy-request' element={<CarsBuyRequestScreen />} />
          <Route path='/cars-trade-request' element={<CarsTradeRequestScreen />} />
          <Route path='/car-loan' element={<CarsLoanScreen />} />
          <Route path='/users' element={<UserManagementScreen />} />
          <Route path='/content-management' element={<ContentManagementScreen />} />
          <Route path='/settings' element={<SettingsScreen />} />
          <Route path='/push-notifications' element={<PushNotificationsScreen />} />
          <Route path='/faq' element={<FaqScreen />} />
          <Route path='/user-suggestions' element={<UserSuggestionsScreen />} />
          <Route path='/terms-and-condition' element={<TCScreen />} />
          <Route path='/privacy-policy' element={<PPScreen />} />
          <Route path='/reports' element={<ReportsScreen />} />
          <Route path='/subscriptions' element={<SubscriptionsScreen />} />
          <Route path='/manage-plans' element={<ManagePlansScreen />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/register-2' element={<FinalRegister />} />
          <Route path='/register-done' element={<RegisterDone />} />
          <Route path='/verificationcode' element={<VerificationCode />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/CreateNewPassword' element={<CreateNewPassword />} />
          <Route path='/faqs' element={<Faq />} />
          <Route path='/terms-and-condition' element={<TermsAndCondition />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          {/* <Route path='/buy-a-car' element={<Dashboard />} /> */}
          <Route path='/get-a-car-loan' element={<GetALoanCar />} />
          <Route path='/sell-a-car' element={<SellACar />} />
          <Route path='/car-details' element={<CarDetails />} />
          <Route path='/subscription-plan' element={<SubscriptionPlan />} />

          {/* private route for logged in user only  */}
          <Route element={<PrivateRoutes />}>
          <Route path='/market-value' element={<MarketValue />} />            
          <Route path='/all-request' element={<AllRequest />} />            
          <Route path='/no-car-available' element={<NoCarAvailable />} />            
          <Route path='/manage-profile' element={<ManageProfile />} />            
          <Route path='/user-details' element={<UserDetails />} />            
          <Route path='/request-trade' element={<RequestTrade />} />            
          <Route path='*' element={<PageNotFound />} />            
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
