import React from "react";
import NavBar from "../home/navBar/NavBar";
import Img from '../../Assets/Images/defaultCarImage.png';
import BottomBarStatic from "../home/footer/BottomBarSatic";
import DefaultLayout from "../auth/DefaultLayout";
import {  Image,   Skeleton,   Text,   VStack } from "@chakra-ui/react";
import NotFoundImage from '../../Assets/Images/404.png'
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";

export default function PageNotFound() {
  const navigate = useNavigate();


  return (
    <DefaultLayout>
      <NavBar />
      <VStack w={"100%"} h={"100vh"} py={"8"} px={"20"}>
      <Image
          src={NotFoundImage}
          alt='car image'
          width={'70%'}
          maxHeight={'100vh'}
          fit={'cover'}
          align={'center'}
          borderRadius='lg'
          fallbackSrc={Img}
          fallback={<Skeleton height="190px" borderRadius={'lg'} />}
        />
        <VStack w={"100%"} h={"auto"} py={"8"} px={"20"} spacing={0}>

         <Text
            color={"#153373"}
            opacity={"1"}
            fontSize={{ base: "40px", md: "42px", lg: "44px", xl: "46px" }}
            fontWeight={"600"}
            isTruncated
            whiteSpace="pre-wrap"
            wordWrap="break-word"
            textOverflow="ellipsis"
            >
            Error 404 - Page Not Found
          </Text>
         <Text
            color={"#707070"}
            opacity={"0.7"}
            fontSize={{ base: "22px", md: "24px", lg: "26px", xl: "28px" }}
            fontWeight={"400"}
            isTruncated
            whiteSpace="pre-wrap"
            wordWrap="break-word"
            textOverflow="ellipsis"
            >
            The page that you requested was not found
          </Text>
            </VStack>
          <CustomButton title={'Back To Home Page'} onClick={()=>navigate('/')}/>
      </VStack>

      <BottomBarStatic />
    </DefaultLayout>
  );
}
