import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import {  HStack, SimpleGrid, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  CreateNewReportApi,
  DeleteReportApi,
  GetAllReportsApi,
  UpdateReportApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import CustomButton from "../../../components/CustomButton/CustomButton";
import ReportModal from "../../../components/ReportModal/ReportModal";
import TableComp from "../../../components/Table/TableComp";
import ReportRow from "../../../components/car/ReportRow/ReportRow";
import { notifyError, notifySuccess } from "../../../components/utils/Toast/ToastNotifications";

export default function ReportsScreen() {
  const { logged } = useAuth();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const titles = [
    { title: "Report Name" },
    { title: "Report Price" },
    { title: "Commission Per Sale" },
    { title: "Active/Inactive" },
  ];

  const handleOpenModal = (report = null) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  const handleSubmit = (reportData) => {
    if (selectedReport) {
      UpdateReportApi({ ...selectedReport, ...reportData })
        .then((response) => {
          notifySuccess(response.message);
          setReports((prevReports) =>
            prevReports.map((item) =>
              item._id === selectedReport._id ? { ...item, ...reportData } : item
            )
          );
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    } else {
      // Create new report
      CreateNewReportApi(reportData)
        .then((response) => {
          notifySuccess(response.message);
          setReports((prevReports) => [...prevReports, response.data]);
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    }
    handleCloseModal();
  };

  const handleDeleteReport = (id) => {
    const ids = [id];
    DeleteReportApi(ids)
      .then((response) => {
        notifySuccess(response.message);
        setReports((prevReports) => prevReports.filter((item) => item._id !== id));
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  const handleUpdateStatus = (report) => {
    UpdateReportApi(report)
      .then((response) => {
        notifySuccess(response.message);
        setReports((prevReports) =>
          prevReports.map((item) =>
            item._id === report._id ? report : item
          )
        );
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };

  const fetchReports = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await GetAllReportsApi();
      if (response?.data) {
        setReports(response.data);
      } else {
        setReports([]);
      }
    } catch (error) {
      setError("Failed to fetch Reports. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReports();
  }, []);



  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={{ base: "28px", md: "40px", lg: "46px" }}
                opacity={1}
              >
                Report Management
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"100%"} minHeight={"100vh"} spacing={0}>
        {loading ? (
          <CustSpinner />
        ) : error ? (
          <Text color="red.500" py={8}>
            {error}
          </Text>
        ) : reports.length === 0 ? (
          <Text color="gray.500" py={8}>
            No Reports available at the moment.
          </Text>
        ) : (
          <>
            <HStack
              w={"100%"}
              justifyContent={"flex-end"}
              backgroundColor={"#fff"}
              pt={{ base: 4, md: 8 }}
              px={{ base: 4, md: 8, lg: 20 }}
            >
              <CustomButton
                title={"Create New Report Type"}
                onClick={() => handleOpenModal()}
              />
            </HStack>
            <VStack w={"95%"} h={"auto"} minHeight={"100vh"} py={"8"}>
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                justifyItems={"center"}
                alignItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <TableComp
                  title={titles}
                  emptyEColumnCount={2}
                >
                  {reports.length > 0 ? (
                    reports?.map((report) => (
                      <ReportRow
                        key={report._id}
                        _id={report._id}
                        report_name={report.report_name}
                        report_price={report.report_price}
                        commission_per_sale={report.commission_per_sale}
                        status={report.status}
                        onUpdate={handleUpdateStatus}
                        actions={true}
                        onDelete={() => handleDeleteReport(report._id)}
                        onEdit={() => handleOpenModal(report)}
                        onChangeStatus={handleUpdateStatus}
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No Reports available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            </VStack>
          </>
        )}
      </VStack>
      <ReportModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        reportsData={selectedReport}
      />
      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
