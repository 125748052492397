import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import { HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import { GetVehicleByIdApi } from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import * as animationData from "../../../Assets/Icons/ShakeHand.json";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { MdMessage } from "react-icons/md";
import { FaFileSignature } from "react-icons/fa";
import CustNavCard from "../../../components/utils/CustNavCard";

export default function ContentManagementScreen() {
  const location = useLocation();
  const { id } = location.state || {};
  const { logged } = useAuth();
  const [vehicles, setVehicles] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true);
      try {
        const response = await GetVehicleByIdApi(id);
        setVehicles(response.data);
      } catch (error) {
        console.error("Failed to fetch vehicles", error);
      }
      setLoading(false);
    };

    fetchVehicles();
  }, [id]);

  const handleCheckboxChange = () => {};
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const cards = [
    {
      title: "FAQ",
      icon: <BiSolidMessageSquareEdit size={60} color="#fff" />,
      goto: "/faq",
    },
    {
      title: "User Suggestions",
      icon: <MdMessage size={60} color="#fff" />,
      goto: "/user-suggestions",
    },
    {
      title: "Terms & Condition",
      icon: <FaFileSignature size={60} color="#fff" />,
      goto: "/terms-and-condition",
    },
    {
      title: "Privacy Policy",
      icon: <FaFileSignature size={60} color="#fff" />,
      goto: "/privacy-policy",
    },
  ];

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={"46px"}
                opacity={1}
              >
                Content Management{" "}
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"100%"} h={"auto"} minHeight={"100vh"} py={"8"} px={"20"}>
        {loading ? (
          <CustSpinner />
        ) : (
          <VStack w={"100%"} h={"auto"} py={"8"}>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 4 }}
              w={"100%"}
              alignSelf={"flex-start"}
              justifyItems={"flex-start"}
              py={12}
              p={15}
              spacing={{ base: 55, md: 185, lg: 255 }}
              rowGap={50}
            >
              {cards.map((item, index) => (
                <CustNavCard
                  key={index}
                  title={item.title}
                  icon={item.icon}
                  goto={item.goto}
                />
              ))}
            </SimpleGrid>
          </VStack>
        )}
      </VStack>

      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
