import React, { useState } from 'react';
import {
    VStack,
    Heading,
    Text,
    Button,
    FormControl,
    FormErrorMessage,
    PinInput,
    PinInputField,
    HStack,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResendOtpApi, VerifyOtpApi } from '../../Axios/Api';
import { notifyError, notifySuccess } from '../../components/utils/Toast/ToastNotifications';
import { useAuth } from '../../providers/AuthProvider';
import NotAuthLayout from '../../components/auth/NotAuthLayout';

export default function VerificationCode() {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { refId, email, vrf } = location.state || {};
    const { login } = useAuth(); 
    let fg = vrf;

    const handleOtpChange = (value) => {
        setOtp(value);
        if (value.length === 4) {
            setError('');
        }
    };

    const validateOtp = () => {
        if (otp.length !== 4) {
            setError('Please enter a valid 4-digit PIN.');
            return false;
        }
        return true;
    };

    const VerifyOtp = () => {
        if (!validateOtp()) {
            return;
        }
        VerifyOtpApi({
            refId,
            email,
            otp,
        })
            .then((response) => {
                console.log(response);
                notifySuccess(response.message);
                if (fg === 0) {
                    navigate('/CreateNewPassword', { state: { refId: response.data.id, email: email } });
                } else {
                    const user = { ...response.data, token: '' };
                    const token = response.data.token;
                    login(user, token);
                }
            })
            .catch((error) => {
                notifyError(error.response.data.message);
            });
    };

    const ResendOtp = () => {


        ResendOtpApi({
            userEmail:email,
            vrf
        })
            .then((response) => {
                console.log(response);
                notifySuccess(response.message);
            })
            .catch((error) => {
                notifyError(error.response.data.message);
            });
    };

    return (
        <NotAuthLayout>
            <VStack w='100%' align={'start'} my={6}>
                <VStack color={'#ffffff'} align={'start'}>
                    <Heading fontSize='3xl' fontWeight={'bold'}>
                        Enter Verification Code
                    </Heading>
                    <Text fontSize='1xl' mb={2} opacity={'0.7'}>
                        Please, check your email. We’ve sent you the code at {email}
                    </Text>
                </VStack>

                <HStack>
                    <FormControl isInvalid={error}>
                        <PinInput value={otp} onChange={handleOtpChange} otp type="alphanumeric" mask>
                            <PinInputField
                                bg={'#FFFFFF'}
                                rounded={12}
                                h={12}
                                w={12}
                                fontSize={20}
                                mr={2}
                            />
                            <PinInputField
                                bg={'#FFFFFF'}
                                rounded={12}
                                h={12}
                                w={12}
                                fontSize={20}
                                mr={2}
                            />
                            <PinInputField
                                bg={'#FFFFFF'}
                                rounded={12}
                                h={12}
                                w={12}
                                fontSize={20}
                                mr={2}
                            />
                            <PinInputField
                                bg={'#FFFFFF'}
                                rounded={12}
                                h={12}
                                w={12}
                                fontSize={20}
                                mr={2}
                            />
                        </PinInput>
                        {error && <FormErrorMessage>{error}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                <Button onClick={VerifyOtp} mt={6} colorScheme="red" py={6} rounded={'34px'} width="200px">
                    Submit
                </Button>

                <Text mt={2} color={'#FFFFFF'} align={'right'} opacity={'0.7'} cursor="pointer">
                    Didn't receive a code?{' '}
                    <span style={{ color: '#25BC9D', fontWeight: '500' }} onClick={ResendOtp} >Resend Code</span>
                </Text>
            </VStack>
        </NotAuthLayout>
    );
}
