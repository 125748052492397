import {
  VStack,
  IconButton,
  Text,
  Stack,
  Menu,
  MenuButton,
  HStack,
  MenuList,
  MenuItem,
  Box,
  Container,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoEllipsisVertical } from "react-icons/io5";
import { Divider } from "@chakra-ui/react";
import { HiOutlineWallet } from "react-icons/hi2";
import { LuGalleryVerticalEnd } from "react-icons/lu";
import { FiHelpCircle } from "react-icons/fi";
import { PiSignOutBold } from "react-icons/pi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";
import { IoMdClose } from "react-icons/io";
import { IoMdNotifications } from "react-icons/io";
import { AiOutlineCar } from "react-icons/ai";
import { FaRegCreditCard } from "react-icons/fa6";
import Avatar from "../../../Assets/Images/Avatar.jpg";
import { CdnLink } from "../../utils/ProviderFunctions";

export default function LoggedOptions({ changeDisplay }) {
  const location = useLocation();
  const { logout, user } = useAuth();
  const [profileImage, setProfileImage] = useState(
    CdnLink(user?.pic) || Avatar
  );

  let Navigate = useNavigate();

  const handleSubmit = async () => {
    await logout();
    Navigate("/login");
  };
  const displayName =
    user?.firstName || user?.lastName
      ? `${user?.firstName || ""} ${user?.lastName || ""}`.trim()
      : "CLMPD User";

  useEffect(() => {
    setProfileImage(CdnLink(user?.pic) || Avatar);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* web view */}
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing="4"
        ml={8}
        display={{ base: "none", md: "flex" }}
      >
        <Menu display={{ base: "flex", md: "none" }}>
          <MenuButton>
            <IoMdNotifications color="white" size="28" />

            <Container>
              <Box
                bg="#E22937"
                w="15px"
                h="15px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                rounded="50%"
                position={"absolute"}
                top="4"
              >
                <Text color={"#FFFFFF"} fontSize={"6px"}>
                  2
                </Text>
              </Box>
            </Container>
          </MenuButton>
          <MenuList rounded="xl" bg="white" p="3">
            <Text
              color={{ base: "black", md: "grey" }}
              as="h3"
              fontSize="md"
              py="2"
            >
              Notifications
            </Text>
            <MenuItem maxWidth="300px">
              <VStack alignItems="start">
                <HStack>
                  <Box
                    bg="#9F9F9F"
                    w="30px"
                    h="30px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    rounded="50%"
                  >
                    <IoMdNotifications color="white" size="18" />
                  </Box>

                  <VStack spacing="0.5" alignItems={"start"}>
                    <Text color={{ base: "black", md: "grey" }}>Jane Doe</Text>
                    <Text
                      color={{ base: "black", md: "grey" }}
                      fontSize={"9px"}
                    >
                      2 hrs ago
                    </Text>
                  </VStack>
                </HStack>
                <Text color={"#111111"} opacity={"0.8"} fontSize={"10px"}>
                  lorem ispas dasjd asdasdkajs dk lorem ispas dasjd lorem ispas
                  dasjd lorem ispas dasjd{" "}
                </Text>
                <Divider
                  borderWidth={"0.5px"}
                  borderColor={"#ebebeb"}
                  w={"100%"}
                />
              </VStack>
            </MenuItem>
            <MenuItem maxWidth="300px">
              <VStack alignItems="start">
                <HStack>
                  <Box
                    bg="#9F9F9F"
                    w="30px"
                    h="30px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    rounded="50%"
                  >
                    <IoMdNotifications color="white" size="18" />
                  </Box>
                  <VStack spacing="0.5" alignItems={"start"}>
                    <Text color={{ base: "black", md: "grey" }}>Jane Doe</Text>
                    <Text
                      color={{ base: "black", md: "grey" }}
                      fontSize={"9px"}
                    >
                      2 hrs ago
                    </Text>
                  </VStack>
                </HStack>
                <Text color={"#111111"} opacity={"0.8"} fontSize={"10px"}>
                  lorem ispas dasjd asdasdkajs dk lorem ispas dasjd lorem ispas
                  dasjd lorem ispas dasjd{" "}
                </Text>
              </VStack>
            </MenuItem>
          </MenuList>
        </Menu>

        <Divider
          display={{ base: "none", md: "flex" }}
          borderWidth={"1px"}
          borderColor={"#0CA7E2"}
          h={"32px"}
          orientation="vertical"
        />

        <Menu>
          <MenuButton>
            <HStack spacing="2">
              <Text color={{ base: "black", md: "white" }} opacity={"0.8"}>
                {displayName}
              </Text>
              <Image src={profileImage} h={10} w={10} borderRadius={100} />
              <IoEllipsisVertical color="white" size="20" opacity={"0.8"} />
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem minH="40px">
              <LuGalleryVerticalEnd color="#153373" />
              <Link to={"/settings"}>
                <Text pl={1} color="#153373">
                  Settings
                </Text>
              </Link>
            </MenuItem>
            <MenuItem minH="40px" onClick={handleSubmit}>
              <PiSignOutBold color="#E20000" />
              <Text pl={1} color="#E20000">
                Sign Out
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>

      {/* Mobile view */}
      <VStack w={"100%"} display={{ base: "flex", md: "none" }}>
        <VStack w={"100%"} bg={"#d4e1fc"}>
          <VStack
            w={"100%"}
            alignItems={"end"}
            justifyItems={"end"}
            mt={4}
            mr={6}
          >
            <IconButton
              aria-label="Open Menu"
              size={"sm"}
              icon={<IoMdClose />}
              onClick={() => changeDisplay("none")}
            />
          </VStack>

          <VStack w={"100%"} mt={2}>
            <Image src={profileImage} h={100} w={100} borderRadius={100} />
            <Text color={"#575757"}>{displayName}</Text>
          </VStack>

          <Divider borderWidth={"1px"} borderColor={"#f2f2f2"} />
        </VStack>

        <VStack
          p={2}
          px={5}
          justifyContent={"space-evenly"}
          w={"100%"}
          alignItems={"start"}
        >
          <HStack spacing={1}>
            <HiOutlineWallet
              height={"200px"}
              width={"200px"}
              color={location.pathname === "/" ? "#06A7E2" : "#575757"}
            />
            <Link to={"/"}>
              <Text
                opacity={"0.8"}
                _hover={{ color: "#25BC9D" }}
                color={location.pathname === "/" ? "#25BC9D" : ""}
              >
                Home
              </Text>
            </Link>
          </HStack>
          <HStack spacing={1}>
            <LuGalleryVerticalEnd
              color={
                location.pathname.includes("content-management")
                  ? "#06A7E2"
                  : "#575757"
              }
            />
            <Link to={"/content-management"}>
              <Text
                opacity={"0.8"}
                _hover={{ color: "#25BC9D" }}
                color={
                  location.pathname.includes("content-management")
                    ? "#25BC9D"
                    : ""
                }
              >
                Content Management
              </Text>
            </Link>
          </HStack>
          <HStack spacing={1}>
            <LuGalleryVerticalEnd
              color={
                location.pathname.includes("settings") ? "#06A7E2" : "#575757"
              }
            />
            <Link to={"/settings"}>
              <Text
                pl={1}
                color={location.pathname.includes("settings") ? "#25BC9D" : ""}
              >
                Settings
              </Text>
            </Link>
          </HStack>
        </VStack>
        <Divider borderWidth={"1px"} borderColor={"#f2f2f2"} />

        <VStack
          p={2}
          px={5}
          justifyContent={"space-evenly"}
          w={"100%"}
          alignItems={"start"}
        >
          <HStack spacing={1}>
            <AiOutlineCar
              color={
                location.pathname.includes("reports") ? "#06A7E2" : "#575757"
              }
            />
            <Link to={"/reports"}>
              <Text
                opacity={"0.8"}
                _hover={{ color: "#25BC9D" }}
                color={location.pathname.includes("reports") ? "#25BC9D" : ""}
              >
                Reports
              </Text>
            </Link>
          </HStack>
          <HStack spacing={1}>
            <FaRegCreditCard
              color={
                location.pathname.includes("Subscriptions")
                  ? "#06A7E2"
                  : "#575757"
              }
            />
            <Link to={"/subscriptions"}>
              <Text
                opacity={"0.8"}
                _hover={{ color: "#25BC9D" }}
                color={
                  location.pathname.includes("Subscriptions") ? "#25BC9D" : ""
                }
              >
                Subscriptions
              </Text>
            </Link>
          </HStack>
        </VStack>

        <Divider borderWidth={"1px"} borderColor={"#f2f2f2"} />
        <VStack
          p={2}
          px={5}
          justifyContent={"space-evenly"}
          w={"100%"}
          alignItems={"start"}
        >
          <HStack spacing={1}>
            <FiHelpCircle
              color={location.pathname.includes("faqs") ? "#06A7E2" : "#575757"}
            />
            <Link to={"/faqs"}>
              <Text
                pl={1}
                color={
                  location.pathname.includes("faqs") ? "#06A7E2" : "#575757"
                }
              >
                Help & Support
              </Text>
            </Link>
          </HStack>

          <HStack spacing={1} onClick={handleSubmit}>
            <PiSignOutBold color="#E20000" />
            <Text pl={1} color="#E20000">
              Sign Out
            </Text>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
}
