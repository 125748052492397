import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  VStack,
  FormErrorMessage,
  FormLabel,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Textarea,
  SimpleGrid,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

// Yup validation schema for the FAQ form
const validationSchema = Yup.object({
  planName: Yup.string().required("Plan Name is required"),
  amount: Yup.number().required("Amount is required"),
  no_of_days: Yup.number().required("No of days is required"),
  features: Yup.string().required("Features is required"),
  status : Yup.boolean().required('Status is required')
});

export default function SubscriptionPlansModal({ isOpen, onClose, onSubmit, reportsData = {} }) {
  const formik = useFormik({
    initialValues: {
      id: reportsData?._id || "", 
      planName: reportsData?.planName || "",
      amount: reportsData?.amount || "",
      no_of_days: reportsData?.no_of_days || "",
      features: reportsData?.features || "",
      status: reportsData?.status || false, 
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values); 
      formik.resetForm();
      onClose();
    },
  });

  useEffect(() => {
    if (reportsData) {
      formik.setValues({
        id: reportsData._id || "",
        planName: reportsData.planName || "",
        amount: reportsData.amount || "",
        no_of_days: reportsData.no_of_days || "",
        features: reportsData.features || "",
        status: reportsData?.status || false, 
      });
    }
    // eslint-disable-next-line
  }, [reportsData]); 
  console.log(formik.values);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent width={"100%"} height={"fit-content"} borderRadius={48} p={5}>
        <ModalHeader
          fontWeight={"600"}
          color={"#153373"}
          letterSpacing={"-2.53px"}
          fontSize={"31px"}
          opacity={1}
        >
          {reportsData ? "Edit Plan" : "Create New Plan"} 
        </ModalHeader>
        <ModalCloseButton onClick={onClose} right={8} top={6} />
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.touched.planName && formik.errors.planName}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                  Subscription Plan Name:
                </FormLabel>
                <Input
                  name="planName"
                  placeholder="Enter Plan Name"
                  value={formik.values.planName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.planName && formik.errors.planName}
                  inputMode="text"
                  type="text"
                  
                />
                <FormErrorMessage>{formik.errors.planName}</FormErrorMessage>
              </FormControl>
              <SimpleGrid
              columns={{ base: 1, md: 2, lg: 2 }}
              w={"100%"}
              spacing={4} pb={4}>


              <FormControl
                isInvalid={formik.touched.amount && formik.errors.amount}
              >
                <FormLabel  fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                 Amount:
                </FormLabel>
                <InputGroup>
                <InputLeftAddon children="$" rounded={26} py={6}
                  fontSize={"xs"} bg={'#fff'} borderRight={'none'}/>
                <Input
                  name="amount"
                  placeholder="Amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  borderLeft={'none'}
                  fontSize={"xs"}
                  isInvalid={formik.touched.amount && formik.errors.amount}
                  inputMode="numeric"
                  type="number"
                />
                </InputGroup>
                <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
                </FormControl>
                <FormControl
                isInvalid={formik.touched.amount && formik.errors.amount}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                 No. of Days:
                </FormLabel>
                <InputGroup>
                <Input
                  name="no_of_days"
                  placeholder="No. of Days"
                  value={formik.values.no_of_days}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.no_of_days && formik.errors.no_of_days}
                  inputMode="numeric"
                  type="number"
                />
                </InputGroup>
                <FormErrorMessage>{formik.errors.no_of_days}</FormErrorMessage>


              </FormControl>
              </SimpleGrid>





              <FormControl
                isInvalid={formik.touched.features && formik.errors.features}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                Features:
                </FormLabel>
                <InputGroup>
                <Textarea
                  name="features"
                  placeholder="features"
                  value={formik.values.features}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.features && formik.errors.features}
                  rows={8}
                  resize={"none"}
                />

                </InputGroup>
                <FormErrorMessage>{formik.errors.features}</FormErrorMessage>
              </FormControl>
              <Stack align="center" justify={"center"} direction="row">
              <FormControl
                isInvalid={formik.touched.features && formik.errors.features}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                {formik.values.status ? "Active" : "Inactive"}
                </FormLabel>
                <Switch
                id="status"
                name="status"
              size="lg"
              isChecked={formik.values.status}
              onChange={formik.handleChange}
              sx={{
                "& .chakra-switch__track": {
                  bg: formik.values.status ? "#CEDDF9" : "#CEDDF9",
                },
                "& .chakra-switch__thumb": {
                  bg: "#153373",
                },
              }}
            />
                <FormErrorMessage>{formik.errors.features}</FormErrorMessage>
              </FormControl>
           
          </Stack>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={formik.handleSubmit} 
            colorScheme="red"
            py={6}
            rounded={"49px"}
            w={{ base: "50%", md: "50%", lg: "50%" }}
            position={"absolute"}
            bottom={"0"}
            left={"50%"}
            transform={"translate(-50%, 50%)"}
            fontSize={"xs"}
            whiteSpace="pre-wrap"
            wordWrap="break-word"
            textOverflow="ellipsis"
          >
            {reportsData ? "Update Plan" : "Create New Plan"} 
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
