import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  VStack,
  FormErrorMessage,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

// Yup validation schema for the FAQ form
const validationSchema = Yup.object({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
});

export default function FaqModal({ isOpen, onClose, onSubmit, faqData = {} }) {
  const formik = useFormik({
    initialValues: {
      id: faqData?._id || "", 
      question: faqData?.question || "",
      answer: faqData?.answer || "",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values); 
      formik.resetForm();
      onClose();
    },
  });

  useEffect(() => {

    if (faqData) {
      formik.setValues({
        id: faqData._id || "",
        question: faqData.question || "",
        answer: faqData.answer || "",
      });
    }
    // eslint-disable-next-line
  }, [faqData]); 

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent width={"100%"} height={"fit-content"} borderRadius={48} p={5}>
        <ModalHeader
          fontWeight={"600"}
          color={"#153373"}
          letterSpacing={"-2.53px"}
          fontSize={"31px"}
          opacity={1}
        >
          {faqData ? "Edit FAQ" : "Create New FAQ"} 
        </ModalHeader>
        <ModalCloseButton onClick={onClose} right={8} top={6} />
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.touched.question && formik.errors.question}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                  Question:
                </FormLabel>
                <Input
                  name="question"
                  placeholder="FAQ Question"
                  value={formik.values.question}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.question && formik.errors.question}
                />
                <FormErrorMessage>{formik.errors.question}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.touched.answer && formik.errors.answer}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                  Answer:
                </FormLabel>
                <Textarea
                  name="answer"
                  placeholder="FAQ Answer"
                  value={formik.values.answer}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.answer && formik.errors.answer}
                  rows={8}
                  resize={"none"}
                />
                <FormErrorMessage>{formik.errors.answer}</FormErrorMessage>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={formik.handleSubmit} 
            colorScheme="red"
            py={6}
            rounded={"49px"}
            w={{ base: "50%", md: "50%", lg: "50%" }}
            position={"absolute"}
            bottom={"0"}
            left={"50%"}
            transform={"translate(-50%, 50%)"}
            fontSize={"xs"}
            whiteSpace="pre-wrap"
            wordWrap="break-word"
            textOverflow="ellipsis"
          >
            {faqData ? "Update FAQ" : "Create New FAQ"} 
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
