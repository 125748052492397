import React, { useState } from "react";
import {
  Tr,
  Td,
  Image,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Skeleton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { RiMoreLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { CdnLink } from "../../utils/ProviderFunctions";
import Img from "../../../Assets/Images/defaultCarImage.png";
import dayjs from "dayjs";

const CarRow = ({
  _id,
  vin,
  year,
  make,
  vehicleModel,
  price,
  tradeRequestCount,
  buyRequestCount,
  miles,
  vehiclePhotos,
  vehicleColorExterior,
  vehicleColorInterior,
  createdAt,
  vehicleCondition,
  onCheckboxChange,
  actions = false,
  showId = false,
}) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    onCheckboxChange(checked, _id);
  };

  const renderVehicleStatus = () => {
    if (!vehicleCondition) return "No Condition Data";

    const totalScore = vehicleCondition?.totalScore || 0;
    if (totalScore < 60) {
      return (
        <Text fontSize="xs" color="#E22937">
          Incomplete Form
        </Text>
      );
    }
    return (
      <Text fontSize="xs" color="#06A7E2">
        Complete Form
      </Text>
    );
  };

  // Responsive values
  const imageSize = useBreakpointValue({ base: 50, md: 70 });
  const textSize = useBreakpointValue({ base: "xs", md: "sm" });
  const hideOnSmallScreens = useBreakpointValue({
    base: "none",
    md: "table-cell",
  });

  return (
    <Tr>
      {/* Checkbox */}
      <Td>
        <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
      </Td>

      {/* Vehicle Image */}
      {vehiclePhotos && (
        <Td p={0}>
          {vehiclePhotos?.[0] ? (
            <Image
              src={CdnLink(vehiclePhotos[0])}
              fallbackSrc={Img}
              height={imageSize}
              width={imageSize}
              objectFit="cover"
              borderRadius="md"
              fallback={
                <Skeleton height={`${imageSize}px`} borderRadius="lg" />
              }
            />
          ) : (
            <Image
              src={Img}
              height={imageSize}
              width={imageSize}
              objectFit="cover"
              borderRadius="md"
            />
          )}
        </Td>
      )}

      {_id && showId && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
        >
          #{_id?.slice(0, 7)}
        </Td>
      )}

      {vin && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
        >
          #{vin}
        </Td>
      )}

      {/* Mileage */}
      {miles && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >{`${miles || "-"} miles`}</Td>
      )}

      {/* Year */}

      {year && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {year || "-"}
        </Td>
      )}

      {/* Make */}
      {make && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {make || "-"}
        </Td>
      )}

      {/* Model */}
      {vehicleModel && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {vehicleModel || "-"}
        </Td>
      )}

      {/* Price */}
      {price && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >{`$${price || "-"}`}</Td>
      )}

      {/* Buy Request Count */}
      {(buyRequestCount && buyRequestCount == 0 ? false : true) && (
        <Td
          color="#06A7E2"
          onClick={() => navigate("/cars-buy-request", { state: { id: _id } })}
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
          cursor="pointer"
        >
          {buyRequestCount || "0"}
        </Td>
      )}

      {/* Trade Request Count */}
      {(tradeRequestCount && tradeRequestCount == 0 ? false : true) && (
        <Td
          color="#06A7E2"
          onClick={() =>
            navigate("/cars-trade-request", { state: { id: _id } })
          }
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
          cursor="pointer"
        >
          {tradeRequestCount || "0"}
        </Td>
      )}

      {/* Vehicle Color (hidden on small screens) */}
      {(vehicleColorExterior || vehicleColorInterior) && (
        <Td
          fontSize={textSize}
          display={hideOnSmallScreens}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {`Ext: ${vehicleColorExterior || "-"}, Int: ${
            vehicleColorInterior || "-"
          }`}
        </Td>
      )}

      {/* Date Added (hidden on small screens) */}
      {createdAt && (
        <Td
          fontSize={textSize}
          display={hideOnSmallScreens}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {dayjs(createdAt).format("DD-MM-YYYY")}
        </Td>
      )}

      {/* Time Added (hidden on small screens) */}
      {createdAt && (
        <Td
          fontSize={textSize}
          display={hideOnSmallScreens}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {dayjs(createdAt).format("hh:mm A")}
        </Td>
      )}

      {/* Vehicle Status */}
      {vehicleCondition && <Td fontSize={textSize}>{renderVehicleStatus()}</Td>}

      {/* Actions */}
      {actions && (
        <Td>
          <Menu>
            <MenuButton>
              <RiMoreLine />
            </MenuButton>
            <MenuList minWidth="120px">
              <MenuItem onClick={() => navigate(`/sell-a-car/${_id}`)}>
                <Text>Edit</Text>
              </MenuItem>
              <MenuItem>
                <Text color="#FC4656">Delete</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
    </Tr>
  );
};

export default CarRow;
