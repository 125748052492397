import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  VStack,
  FormErrorMessage,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

export default function PushNotificationModal({ isOpen, onClose, onSubmit, pushNotificationData = {} }) {
  const formik = useFormik({
    initialValues: {
      id: pushNotificationData?._id || "", 
      title: pushNotificationData?.title || "",
      description: pushNotificationData?.description || "",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values); 
      formik.resetForm();
      onClose();
    },
  });

  useEffect(() => {
    if (pushNotificationData) {
      formik.setValues({
        id: pushNotificationData._id || "",
        title: pushNotificationData.title || "",
        description: pushNotificationData.description || "",
      });
    }
    // eslint-disable-next-line
  }, [pushNotificationData]); 

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent width={"100%"} height={"fit-content"} borderRadius={48} p={5}>
        <ModalHeader
          fontWeight={"600"}
          color={"#153373"}
          letterSpacing={"-2.53px"}
          fontSize={"31px"}
          opacity={1}
        >
          {pushNotificationData ? "Edit Push Notification" : "Create New Notification"} 
        </ModalHeader>
        <ModalCloseButton onClick={onClose} right={8} top={6} />
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.touched.title && formik.errors.title}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                  Title:
                </FormLabel>
                <Input
                  name="title"
                  placeholder="Push Notification title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.title && formik.errors.title}
                />
                <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.touched.description && formik.errors.description}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                  Description:
                </FormLabel>
                <Textarea
                  name="description"
                  placeholder="Push Notification description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.description && formik.errors.description}
                  rows={8}
                  resize={"none"}
                />
                <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={formik.handleSubmit} 
            colorScheme="red"
            py={6}
            rounded={"49px"}
            w={{ base: "50%", md: "50%", lg: "50%" }}
            position={"absolute"}
            bottom={"0"}
            left={"50%"}
            transform={"translate(-50%, 50%)"}
            fontSize={"xs"}
            whiteSpace="pre-wrap"
            wordWrap="break-word"
            textOverflow="ellipsis"
          >
            {pushNotificationData ? "Update Notification" : "Create New Notification"} 
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
