import { apiRequest } from "./ApiProvider";

// export const getSomeData = () => {
//     return apiRequest({
//       endpoint: "/",
//       method: "get",
//     });
//   };

//========================================================== Auth API's =================================================================================================

// Signup API for User
export const SignUp = (values) => {
    console.log('sign',values);
    return apiRequest({
        endpoint: "/signup",
        method: "post",
        data: { 
            firstName: values.fname, 
            lastName: values.lname,
            email: values.email, 
            city: values.city,
            state: values.state,
            phoneNumber:`${values.phone}`,
            password: values.password, 
            confirmPassword: values.confirmPassword,
        }, 
        contentType:"json",
      });
  };

// Login API for User
export const LoginApi = (values) => {
    return apiRequest({
        endpoint: "/admin/login",
        method: "post",
        data: { 
            email: values.email, 
            password: values.password,
            keepMeLoggedIn:values.keepMeLoggedIn
        },
        contentType:"json", 
      });
  };

// Login API for User
export const LogOutApi = () => {
    return apiRequest({
        endpoint: "/signout",
        method: "post",
        contentType:"json", 
      });
  };

// Reset Pass API for User
export const ResetPasswordApi = (values) => {
    return apiRequest({
        endpoint: "/reset-password",
        method: "post",
        data: { 
            userEmail:values.email,
            password: values.password ,
            confirmPassword: values.confirmPassword
        }, 
      });
  };

// Forget Pass API for User
export const ForgotPasswordAPI= (values) => {
    return apiRequest({
        endpoint: "/forgot-password-otp",
        method: "post",
        data: { 
            userEmail: values.email
        }, 
        contentType:"json",
      });
  };

// OTP Verification API 
export const VerifyOtpApi = ({
    // refId, 
    email, 
    otp
}) => {
    return apiRequest({
        endpoint: "/verify",
        method: "post",
        data: { 
            // refId, 
            userEmail:email, 
            otp
        }, 
        contentType:"json",
      });
  };

// Resend OTP
export const ResendOtpApi = ({
    userEmail,
    vrf
}) => {
    return apiRequest({
        endpoint: "/send-otp",
        method: "post",
        data: { 
            userEmail ,
            vrf
        }, 
        contentType:"json",
      });
  };







// ========================================================= Vehicle API's =============================================================================================

// Register Vehicles
export const RegisterVehicleApi = (
    values
) => {
    return apiRequest({
        endpoint: "/vehicle",
        method: "post",
        data:{
           vin:values.vin_number,
           miles:values.mile,
           year:values.year,
           make:values.make,
           vehicleModel:values.model,
           trim:values.trim,
           engine:values.engine,
           madeIn:values.made_in,
           style:values.style,
           standardSeating: values.s_seating,
           highwayMileage:values.h_milage,
           cityMileage:values.c_milage,
           fuelType:values.fuel_type,
           fuelCapacity: values.fuel_capacity,
           vehicleColorInterior: values.vehicle_color_i,
           vehicleColorExterior:values.vehicle_color_e,
           vehicleCondition:JSON.stringify(values.vehicleCondition)
        },
        contentType:"json",
      });
  };

// Upload Images
export const UploadImageApi = ({ vehicleId, values }) => {
    const formData = new FormData();
  
    if (values.vehiclePhotos?.length > 0) {
      values.vehiclePhotos.forEach((file) => {
        formData.append("vehicles", file); 
      });
    }
  
    return apiRequest({
      endpoint: `/vehicle/vehicle-images/${vehicleId}`,
      method: "post",
      data: formData,
      contentType: 'multipart/form-data',
    });
  };

// Get All Vehicles
export const GetAllVehicleApi = () => {
    return apiRequest({
        endpoint: "/vehicle",
        method: "get",
        contentType:"json",
      });
  };

// Get Vehicles by Id
export const GetVehicleByIdApi = (vehicleId) => {
    return apiRequest({
        endpoint: `/vehicle/${vehicleId}`,
        method: "get",
        contentType:"json",
      });
  };


// Add Vehicle To Bookmark APi
export const BookmarkVehicleApi = ( {vehicleId,isBookmarked} ) => {
  return apiRequest({
    endpoint: `/vehicle/bookmark`,
    method: "post",
    data: {
      vehicleId, 
      isBookmarked
        }, 
        contentType:"json",
  });
};

// Remove Vehicle To Bookmark APi
export const RemoveBookmarkVehicleApi = ( {vehicleId} ) => {
  return apiRequest({
    endpoint: `/vehicle/remove-bookmark`,
    method: "post",
    data: {
      vehicleId 
        }, 
        contentType:"json",
  });
};

// Get Vehicle To Bookmark APi
export const GetBookmarkVehicleApi = () => {
  return apiRequest({
    endpoint: `/vehicle/all-bookmarks`,
    method: "get",
    contentType:"json",
  });
};

//Search Filter Vehicle To Bookmark APi
export const SearchFilterVehicleApi = (filters) => {
  const { searchTerm,year, make, model, trims, priceRange, mileageRange, page, limit, sortField, sortOrder } = filters;
  return apiRequest({
    endpoint: `/vehicle/search-filter-vehicles`,
    method: "get",
    params: {search:searchTerm, year, make, model, trim:trims, priceRange, mileageRange, page, limit, sortField, sortOrder },
    contentType:"json",
  });
};

// Buy Vehicle APi
export const BuyVehicleApi = (vehicleId) => {
  return apiRequest({
    endpoint: `/buyrequest`,
    method: "post",
    data: {
      vehicleId 
        }, 
        contentType:"json",
  });
};


// ========================================================= Admin API's =============================================================================================




// Get All User's Details
export const GetAllUsersDetailsApi = (values) => {
  return apiRequest({
      endpoint: "/admin/user-details",
      method: "get",
      params: values,
      contentType:"json",
    });
};
export const SuspendUserApi = (values) => {
  return apiRequest({
    endpoint: "/admin/suspend-user",
    method: "patch",
    data: values,
    contentType:"json",
  });
};

// Delete FAQ
export const DeleteUserApi = (id) => {
  return apiRequest({
    endpoint: `/admin/delete-faq/${id}`, 
    method: "delete",
  });
};
////////////////////////////////////////////// Dashboard Statistics ////////////////////////////////////////////
// Get All User's Stat
export const GetAllUsersStatApi = (values) => {
  return apiRequest({
      endpoint: "/admin/user-report",
      method: "get",
      params: values,
      contentType:"json",
    });
};


////////////////////////////////////////////// FAQ ////////////////////////////////////////////
// Get All Vehicles
export const GetAllCarsDetailsApi = (values) => {
  return apiRequest({
      endpoint: "/admin/vehicle-summary",
      method: "get",
      params:values,
      contentType:"json",
    });
};

// Get Vehicles Buy Request by Id
export const GetVehicleBuyRequestByIdApi = (vehicleId) => {
  return apiRequest({
      endpoint: `/admin/vehicle-summary/buyrequest/${vehicleId}`,
      method: "get",
      contentType:"json",
    });
};



////////////////////////////////////////////// FAQ ////////////////////////////////////////////
// Get All FAQ
export const GetAllFaqApi = () => {
  return apiRequest({
    endpoint: "/admin/get-faqs",
    method: "get",
    contentType:"json",
  });
};

// Create new  FAQ
export const CreateNewFaqApi = (values) => {
  return apiRequest({
    endpoint: "/admin/create-faq",
    method: "post",
    data: values,
    contentType:"json",
  });
};

// Update FAQ
export const UpdateFaqApi = (values) => {
  return apiRequest({
    endpoint: `/admin/update-faq/${values.id}`,
    method: "patch",
    data: values,
    contentType:"json",
  });
};
// Delete FAQ
export const DeleteFaqApi = (id) => {
  return apiRequest({
    endpoint: `/admin/delete-faq/${id}`, 
    method: "delete",
  });
};



////////////////////////////////////////////// Suggestions //////////////////////////////////////////////////////////////////////

export const GetAllSuggestionsApi = (page) => {
  return apiRequest({
      endpoint: "/admin/get-suggestions",
      method: "get",
      params:page,
      contentType:"json",
    });
};




////////////////////////////////////////////// Terms and Conditions / Privacy Policy ////////////////////////////////////////////

export const GetTCApi = () => {
  return apiRequest({
      endpoint: "/admin/get-terms-privacy/terms_and_conditions",
      method: "get",
      contentType:"json",
    });
};

export const GetPPApi = () => {
  return apiRequest({
      endpoint: "/admin/get-terms-privacy/privacy_policy",
      method: "get",
      contentType:"json",
    });
};
export const UpdateTCPPApi = (value) => {
  return apiRequest({
      endpoint: "/admin/create-terms-privacy",
      method: "post",
      data:value,
      contentType:"json",
    });
};

////////////////////////////////////////////// Subscriptions ////////////////////////////////////////////

// Get All Subscriptions
export const GetAllSubscriptionsApi = (values) => {
  return apiRequest({
    endpoint: "/admin/get-subs-stat",
    method: "get",
    params: values,
    contentType:"json",
  });
};
// Get All Subscriptions Plans
export const GetAllSubscriptionPlansApi = () => {
  return apiRequest({
    endpoint: "/admin/get-subscriptions",
    method: "get",
    contentType:"json",
  });
};
// Create new report
export const CreateNewPlanApi = (values) => {
  return apiRequest({
    endpoint: "/admin/create-subscription",
    method: "post",
    data: values,
    contentType:"json",
  });
};
// Update report
export const UpdatePlanApi = (values) => {
  return apiRequest({
    endpoint: `/admin/update-subscription/${values._id}`,
    method: "patch",
    data: values,
    contentType:"json",
  });
};

// Delete Subscriptions Plans
export const DeleteSubscriptionPlanApi = (ids) => {
  console.log('plansIds',ids)
  return apiRequest({
    endpoint: `/admin/delete-subscription/${ids[0]}`, 
    data: { ids },
    method: "delete",
    contentType:"json",
  });
};

////////////////////////////////////////////// Reports ////////////////////////////////////////////

// Get All Reports
export const GetAllReportsApi = () => {
  return apiRequest({
    endpoint: "/admin/get-reports",
    method: "get",
    contentType:"json",
  });
};


// Create new report
export const CreateNewReportApi = (values) => {
  return apiRequest({
    endpoint: "/admin/create-report",
    method: "post",
    data: values,
    contentType:"json",
  });
};
// Update report
export const UpdateReportApi = (values) => {
  return apiRequest({
    endpoint: `/admin/update-report/${values._id}`,
    method: "patch",
    data: values,
    contentType:"json",
  });
};

// Delete report
export const DeleteReportApi = (reportIds) => {
  return apiRequest({
    endpoint: `/admin/delete-report/${reportIds[0]}`, 
    data: { reportIds },
    method: "delete",
    contentType:"json",
  });
};





////////////////////////////////////////////// Push Notifications ////////////////////////////////////////////
// Get All Push Notifications
export const GetAllPushNotificationsApi = () => {
  return apiRequest({
    endpoint: "/admin/get-notifications",
    method: "get",
    contentType:"json",
  });
};

// Create new push  Notifications
export const CreateNewPushNotificationApi = (values) => {
  return apiRequest({
    endpoint: "/admin/create-notification",
    method: "post",
    data: values,
    contentType:"json",
  });
};

// Update push notifications
export const UpdatePushNotificationApi = (values) => {
  return apiRequest({
    endpoint: `/admin/update-notification/${values.id}`,
    method: "patch",
    data: values,
    contentType:"json",
  });
};


// Delete push notifications
export const DeletePushNotificationApi = (notificationIds) => {
  return apiRequest({
    endpoint: `/admin/delete-notification/${notificationIds[0]}`, 
    data: { notificationIds },
    method: "delete",
    contentType:"json",
  });
};


// ========================================================= User API's =============================================================================================

// Background Auth API for User
export const BackgroundAuthApi = () => {
  return apiRequest({
      endpoint: "/user/",
      method: "get"
    });
};

// Profile Update APi
export const UploadUserProfileApi = ({ formData }) => {
  return apiRequest({
    endpoint: `/user/upload-profile-pic`,
    method: "post",
    data: formData,
    contentType: 'multipart/form-data',
  });
};

// Update User Data APi
export const UpdateUserDataApi = ( values ) => {
  return apiRequest({
    endpoint: `/user`,
    method: "put",
    data: {
            firstName: values.fname, 
            lastName: values.lname,
            email: values.email, 
            city: values.city,
            state: values.state,
            phoneNumber:values.phone,
        }, 
        contentType:"json",
  });
};

// Change Password API
export const ChangePasswordApi = (values) => {
  return apiRequest({
      endpoint: `/user/change-password`,
      method: "post",
      data: {
          oldPassword: values.old_password,
          newPassword: values.new_password,
          confirmNewPassword: values.confirm_password,
      },
      contentType: "json",
  });
};

// Submit Faq APi
export const SubmitFaqApi = (values) => {
  const { content } = values;
  return apiRequest({
    endpoint: `/user/faq`,
    method: "post",
    data: {
      content
    },
    contentType: 'json',
  });
};


// Get All User Vehicles
export const GetAllUserVehicleApi = () => {
  return apiRequest({
      endpoint: "/vehicle/user-vehicles",
      method: "get",
      contentType:"json",
    });
};

// Get User Vehicles by Id
export const GetUserVehicleByIdApi = (vehicleId) => {
  return apiRequest({
      endpoint: `/vehicle/user-vehicle/${vehicleId}`,
      method: "get",
      contentType:"json",
    });
};