import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import {  Box, HStack, SimpleGrid, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  CreateNewReportApi,
  GetAllSubscriptionsApi,
  UpdateReportApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import CustomButton from "../../../components/CustomButton/CustomButton";
import ReportModal from "../../../components/ReportModal/ReportModal";
import TableComp from "../../../components/Table/TableComp";
import { notifyError, notifySuccess } from "../../../components/utils/Toast/ToastNotifications";
import CustCard from "../../../components/utils/CustCard";
import SubscriptionUserRow from "../../../components/car/SubscriptionRows/SubscriptionUserRow";
import { useNavigate } from "react-router-dom";

export default function SubscriptionsScreen() {
  const { logged } = useAuth();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const navigate = useNavigate();
  const titles = [
    { title: "Name" },
    { title: "Date of Subscription" },
    { title: "Amount" },
    { title: "No. of Days" },
    { title: "Status" },
  ];

  const fetchSubscriptions = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const response = await GetAllSubscriptionsApi(values);
      console.log('response',response);
      if (response) {
        setSubscriptions(response);
      } else {
        setSubscriptions([]);
      }
    } catch (error) {
      setError("Failed to fetch Subscriptions. Please try again later.");
    }
    setLoading(false);
  };
  
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const cards = [
    {
      title: subscriptions?.totalSubscribedUsers ||  "0",
      subtitle: "Subscribed Users",
    },
    {
      title: subscriptions?.totalRevenue || "0",
      subtitle: "Revenue Generated",
    },

  ];

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={{ base: "28px", md: "40px", lg: "46px" }}
                opacity={1}
              >
                Subscriptions
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"100%"} height={'100%'} spacing={0}>
        {loading ? (
          <CustSpinner />
        ) : error ? (
          <Text color="red.500" py={8}>
            {error}
          </Text>
        ) : subscriptions?.subscriptions.length === 0 ? (
          <Text color="gray.500" py={8}>
            No Subscribed Users available at the moment.
          </Text>
        ) : (
          <>
          
            <VStack w={"95%"} h={"auto"} minHeight={"100vh"} py={"8"}>
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              justifyItems={'center'}
              alignItems={'flex-start'}
              alignContent={'center'}
              backgroundColor={"#fff"}
              pt={{ base: 4, md: 8 }}
              px={{ base: 4, md: 8, lg: 0 }}
              // flexWrap={'wrap'}
              flexDirection={{ base: "column", md: "row" }}
            >
               <SimpleGrid
              columns={{ base: 1, md: 1, lg: 2 }}
              w={"fit-content"}
              alignSelf={"flex-start"}
              justifyItems={'center'}
              py={12}
              p={15}
              spacing={5}
              rowGap={50}
              position={'relative'}
            >
              {cards.map((item, index) => (
                <CustCard
                  key={index}
                  title={item.title}
                  subtitle={item.subtitle}
                />
              ))}
            </SimpleGrid>
            <Box mt={{ base: 4, md: 0 }} alignSelf={{ base: "center", md: "flex-start" }}>
                  <CustomButton
                    title={"Manage Your Plans"}
                    onClick={() => navigate('/manage-plans')}
                  />
                </Box>
            </HStack>
            <HStack
              h={"fit-content"}
              w={"100%"}
              justify={'space-between'}
              align={"center"}
              textAlign={"left"}
              p={2}
              mt={10}
            >
              <Text
                fontWeight={"500"}
                color={"#1D1C1A"}
                opacity={"1"}
                fontSize={"25px"}
              >
                Subscribed Users
              </Text>

            </HStack>
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                justifyItems={"center"}
                alignItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <TableComp
                  title={titles}
                  pagination={subscriptions.pagination}
                  displayPagination={true}
                  onPageChange={fetchSubscriptions}
                >
                  {subscriptions?.subscriptions.length > 0 ? (
                    subscriptions?.subscriptions?.map((subscription) => (
                      <SubscriptionUserRow
                        key={subscription._id}
                        _id={subscription._id}
                        name={subscription?.name || "-"}
                        dateOfSub={subscription.dateOfSubscription}
                        amount={subscription?.amount || "0"}
                        noOfDays={subscription?.noOfDays || "0"}
                        status={subscription.status}

                        
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No subscriptions available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            </VStack>
          </>
        )}
      </VStack>
      {/* <ReportModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        reportsData={selectedReport}
      /> */}
      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
