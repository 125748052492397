import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  VStack,
  FormErrorMessage,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

// Yup validation schema for the FAQ form
const validationSchema = Yup.object({
  reason: Yup.string().required("Reason is required"),
});

export default function SuspendModal({ isOpen, onClose, onSubmit, userData = {} }) {
  const formik = useFormik({
    initialValues: {
      id: userData?._id || "", 
      reason: userData?.reason || "",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values); 
      formik.resetForm();
      onClose();
    },
  });

  useEffect(() => {

    if (userData) {
      formik.setValues({
        id: userData._id || "",
        reason: userData.reason || "",
      });
    }
    // eslint-disable-next-line
  }, [userData]); 

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent width={"100%"} height={"fit-content"} borderRadius={48} p={5}>
        <ModalHeader
          fontWeight={"600"}
          color={"#153373"}
          letterSpacing={"-2.53px"}
          fontSize={"31px"}
          opacity={1}
        >
          {userData ? "Edit reason" : "Create New reason"} 
        </ModalHeader>
        <ModalCloseButton onClick={onClose} right={8} top={6} />
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.touched.reason && formik.errors.reason}
              >
                <FormLabel fontSize={"18px"} fontWeight={"500"} opacity={0.8}>
                Reason:
                </FormLabel>
                <Textarea
                  name="reason"
                  placeholder="Reason for Suspending user."
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  rounded={26}
                  py={6}
                  fontSize={"xs"}
                  isInvalid={formik.touched.reason && formik.errors.reason}
                  rows={8}
                  resize={"none"}
                />
                <FormErrorMessage>{formik.errors.reason}</FormErrorMessage>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={formik.handleSubmit} 
            colorScheme="red"
            py={6}
            rounded={"49px"}
            w={{ base: "50%", md: "50%", lg: "50%" }}
            position={"absolute"}
            bottom={"0"}
            left={"50%"}
            transform={"translate(-50%, 50%)"}
            fontSize={"xs"}
            whiteSpace="pre-wrap"
            wordWrap="break-word"
            textOverflow="ellipsis"
          >
            {userData ? "Update reason" : "Create New reason"} 
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
