import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  CreateNewFaqApi,
  DeleteFaqApi,
  GetPPApi,
  UpdateFaqApi,
  UpdateTCPPApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import BackButton from "../../../components/BackButton/BackButton";
import {
  notifyError,
  notifySuccess,
} from "../../../components/utils/Toast/ToastNotifications";
import QuillEditor from "../../../components/QuillEditor/QuillEditor";

export default function PPScreen() {
  const { logged } = useAuth();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editorValue, setEditorValue] = useState("");

  const handleSave = (value) => {
    console.log("Saved content:", value);
    const values = { type: "privacy_policy", content: value };
    UpdateTCPPApi(values)
      .then((response) => {
        notifySuccess(response.message);
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };


  useEffect(() => {
    const fetchSuggestions = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await GetPPApi();
        console.log(response);
        if (response?.data) {
          setEditorValue(response.data.content);
        } else {
          setEditorValue("");
        }
      } catch (error) {
        setError("Failed to get Privacy Policy. Please try again later.");
      }
      setLoading(false);
    };

    fetchSuggestions();
  }, []);

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={{ base: "28px", md: "40px", lg: "46px" }}
                opacity={1}
              >
                <BackButton />
                Privacy Policy
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"95%"} h={"100%"} py={"8"}>
        {loading ? (
          <CustSpinner />
        ) : (
          <VStack w={"100%"} h={"100%"} py={"8"}>
            <QuillEditor
              initialValue={editorValue}
              placeholder={"Enter your Privacy Policy here..."}
              onSave={(value) => handleSave(value)}
            />
          </VStack>
        )}
      </VStack>
      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
