import React, { useState } from "react";
import {
  Tr,
  Td,
  Switch,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useBreakpointValue,
  Stack,
  Input,
} from "@chakra-ui/react";
import { RiMoreLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../CustomButton/CustomButton";
import dayjs from "dayjs";

const SubscriptionUserRow = ({
  _id,
  name,
  dateOfSub,
  amount,
  noOfDays,
  status=false,
  onEdit,
  onDelete,
  actions = false,
  showId = false,
}) => {
  const navigate = useNavigate();
  const textSize = useBreakpointValue({ base: "xs", md: "sm" });

  return (
    <Tr>
      {/* ID column */}
      {_id && showId && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          // onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
        >
          #{_id?.slice(0, 7)}
        </Td>
      )}

      {/* Subscriptions Name column */}
      {name && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          // onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {name}
        </Td>
      )}
      {/* Subscriptions date of subscriptions column */}
      {dateOfSub && (
        <Td
          fontSize={textSize}
          color="#000"
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {dayjs(dateOfSub).format("DD-MM-YYYY")}
        </Td>
      )}
      {/* Subscriptions amount column */}
      {amount && (
        <Td
          fontSize={textSize}
          color="#000"
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {amount}
        </Td>
      )}

      {/* Subscriptions no of days column */}
      {noOfDays && (
        <Td
          fontSize={textSize}
          color="#000"
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {noOfDays}
        </Td>
      )}

      {/* Subscriptions status column */}
      {status !== undefined && (
        <Td
          fontSize={textSize}
          color={status ? "#25BC9D" : "#BCB025"}
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {status ? "Active" : "Inactive"}
        </Td>
      )}

      {/* Actions */}
      {actions && (
        <Td>
          <Menu>
            <MenuButton>
              <RiMoreLine />
            </MenuButton>
            <MenuList minWidth="120px">
              <MenuItem onClick={onEdit}>
                <Text>Edit</Text>
              </MenuItem>
              <MenuItem onClick={onDelete}>
                <Text color="#FC4656">Delete</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
    </Tr>
  );
};

export default SubscriptionUserRow;
