import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastStyles.css'; // Custom CSS file for styling

const ToastNotifications = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" // You can choose between 'light', 'dark', or 'colored'
        toastClassName="custom-toast" // Custom class for styling the toast
        bodyClassName="custom-toast-body" // Custom class for styling the toast body
        closeButton={false} // Hides the default close button for a cleaner look
        icon={true} // Show icons based on the type of notification
      />
    </>
  );
};

export const notifySuccess = (message) => {
  toast.success(message, {
    icon: "✅", // Custom icon for success notifications
  });
};

export const notifyError = (message) => {
  toast.error(message, {
    icon: "❌", // Custom icon for error notifications
  });
};

export const notifyInfo = (message) => {
  toast.info(message, {
    icon: "ℹ️", // Custom icon for info notifications
  });
};

export const notifyWarning = (message) => {
  toast.warn(message, {
    icon: "⚠️", // Custom icon for warning notifications
  });
};

export default ToastNotifications;
