import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import {
  HStack,
  SimpleGrid,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  CreateNewPushNotificationApi,
  DeletePushNotificationApi,
  GetAllPushNotificationsApi,
  UpdatePushNotificationApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import BackButton from "../../../components/BackButton/BackButton";
import CustomButton from "../../../components/CustomButton/CustomButton";
import {
  notifyError,
  notifySuccess,
} from "../../../components/utils/Toast/ToastNotifications";
import TableComp from "../../../components/Table/TableComp";
import NoDataComponent from "../../../components/utils/NoDataComponent";
import PushNotificationsRow from "../../../components/car/PushNotificationsRow/PushNotificationsRow";
import PushNotificationModal from "../../../components/PushNotificationModal/PushNotificationModal";

export default function PushNotificationsScreen() {
  const { logged } = useAuth();
  const [pushNotifications, setPushNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleOpenModal = (noti = null) => {
    setNotification(noti);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNotification(null);
  };

  const handleSubmit = (noti) => {
    if (notification) {
      UpdatePushNotificationApi(noti)
        .then((response) => {
          notifySuccess(response.message);
          setPushNotifications((prevFaqs) =>
            prevFaqs.map((item) =>
              item._id === notification._id ? { ...noti, id: item._id } : item
            )
          );
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    } else {
      CreateNewPushNotificationApi(noti)
        .then((response) => {
          notifySuccess(response.message);
          const newFaq = { ...noti, id: Date.now() };
          setPushNotifications((prevFaqs) => [...prevFaqs, newFaq]);
        })
        .catch((error) => {
          notifyError(error.response.data.message);
        });
    }
  };

  const handleSend = () => {};

  const handleDelete = (id) => {
    const ids = [id];
    DeletePushNotificationApi(ids)
      .then((response) => {
        notifySuccess(response.message);
        setPushNotifications((prevFaqs) =>
          prevFaqs.filter((item) => item._id !== id)
        );
      })
      .catch((error) => {
        notifyError(error.response.data.message);
      });
  };
  const fetchPushNotifications = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await GetAllPushNotificationsApi();
      console.log(response);
      if (response?.data) {
        setPushNotifications(response.data);
      } else {
        setPushNotifications([]);
      }
    } catch (error) {
      setError("Failed to fetch FAQs. Please try again later.");
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchPushNotifications();
   // eslint-disable-next-line
  },[]);

  const titles = [{ title: "Date" },  { title: "Title" }, { title: "Detail's" }];
  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={{ base: "28px", md: "40px", lg: "46px" }}
                opacity={1}
              >
                <BackButton />
                Push Notification
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"100%"} minHeight={"100vh"} spacing={0}>
        {loading && pushNotifications ? (
          <CustSpinner />
        ) : error ? (
          <Text color="red.500" py={8}>
            {error}
          </Text>
        ) : pushNotifications.length === 0 ? (
          <Text color="gray.500" py={8}>
            No Push Notification available at the moment.
          </Text>
        ) : (
          <>
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              backgroundColor={"#fff"}
              pt={{ base: 4, md: 8 }}
              px={{ base: 4, md: 8, lg: 20 }}
            >
              <Text
                fontWeight={"600"}
                color={"#1D1C1A"}
                opacity={"0.8"}
                fontSize={"25px"}
                textAlign={"left"}
              >
                Notification List
              </Text>
              <CustomButton
                title={"Create Notification"}
                onClick={() => handleOpenModal()}
              />
            </HStack>
            {titles ? (
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                justifyItems={"center"}
                alignItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <TableComp title={titles}>
                  {pushNotifications && pushNotifications?.length > 0 ? (
                    pushNotifications?.map((notification) => (
                      <PushNotificationsRow
                        key={notification._id}
                        _id={notification._id}
                        title={notification.title}
                        details={notification.description}
                        createdAt={notification.createdAt}
                        showDate={true}
                        actions={true}
                        onSend={handleSend}
                        onDelete={()=>handleDelete(notification._id)}
                        onEdit={() => handleOpenModal(notification)}
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No Users available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            ) : (
              <NoDataComponent type="nouser" />
            )}
          </>
        )}
      </VStack>
      <PushNotificationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        pushNotificationData={notification}
      />
      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
