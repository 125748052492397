import React, { useState } from "react";
import {
  Tr,
  Td,
  Switch,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useBreakpointValue,
  Stack,
  Input,
} from "@chakra-ui/react";
import { RiMoreLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../CustomButton/CustomButton";

const ReportRow = ({
  _id,
  status: initialStatus,
  commission_per_sale: initialCommissionPerSale,
  report_name,
  report_price: initialReportPrice,
  onEdit,
  onDelete,
  onChangeStatus,
  onUpdate,
  actions = false,
  showId = false,
}) => {
  const navigate = useNavigate();
  const textSize = useBreakpointValue({ base: "xs", md: "sm" });

  const [reportPrice, setReportPrice] = useState(initialReportPrice);
  const [commissionPerSale, setCommissionPerSale] = useState(
    initialCommissionPerSale
  );
  const [status, setStatus] = useState(initialStatus);

  const handleUpdate = () => {
    onUpdate({
      _id,
      report_name,
      report_price: reportPrice,
      commission_per_sale: commissionPerSale,
      status,
    });
  };

  const handleStatusChange = () => {
    const newStatus = !status;
    setStatus(newStatus);
    onChangeStatus({
      _id,
      report_name,
      report_price: reportPrice,
      commission_per_sale: commissionPerSale,
      status: newStatus,
    });
  };

  return (
    <Tr>
      {/* ID column */}
      {_id && showId && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          // onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
        >
          #{_id?.slice(0, 7)}
        </Td>
      )}

      {/* Report Name column */}
      {report_name && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          // onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          {report_name}
        </Td>
      )}

      {/* Editable Report Price */}
      <Td
        fontSize={textSize}
        whiteSpace="pre-wrap"
        wordWrap={true}
        textOverflow="ellipsis"
      >
        <Input
          size="sm"
          value={reportPrice}
          onChange={(e) => setReportPrice(e.target.value)}
          placeholder="Report Price"
          bg={"#fff"}
          focusBorderColor="#707070"
          borderColor="#D9D9D9"
          borderRadius={26}
        />
      </Td>

      {/* Editable Commission Per Sale */}
      <Td
        fontSize={textSize}
        whiteSpace="pre-wrap"
        wordWrap={true}
        textOverflow="ellipsis"
      >
        <Input
          size="sm"
          value={commissionPerSale}
          onChange={(e) => setCommissionPerSale(e.target.value)}
          placeholder="Commission Per Sale"
          bg={"#fff"}
          focusBorderColor="#707070"
          borderColor="#D9D9D9"
          borderRadius={26}
        />
      </Td>

      {/* Status Switch */}
      {status !== "" && (
        <Td
          fontSize={textSize}
          whiteSpace="pre-wrap"
          wordWrap={true}
          textOverflow="ellipsis"
        >
          <Stack align="center" justify={"center"} direction="row">
            <Switch
              size="lg"
              isChecked={status}
              onChange={handleStatusChange}
              sx={{
                "& .chakra-switch__track": {
                  bg: status ? "#CEDDF9" : "#CEDDF9",
                },
                "& .chakra-switch__thumb": {
                  bg: "#153373",
                },
              }}
            />
          </Stack>
        </Td>
      )}

      {/* Update Button */}
      <Td>
        <CustomButton
          title={"Update"}
          bg={"#25BC9D"}
          py={0}
          px={55}
          onClick={handleUpdate}
        />
      </Td>

      {/* Actions */}
      {actions && (
        <Td>
          <Menu>
            <MenuButton>
              <RiMoreLine />
            </MenuButton>
            <MenuList minWidth="120px">
              <MenuItem onClick={onEdit}>
                <Text>Edit</Text>
              </MenuItem>
              <MenuItem onClick={onDelete}>
                <Text color="#FC4656">Delete</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
    </Tr>
  );
};

export default ReportRow;
