import { Card, Text, VStack, HStack, Input, FormControl, FormErrorMessage } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BarChart, Bar, XAxis, Legend, ResponsiveContainer, Tooltip, YAxis } from 'recharts';
import dayjs from 'dayjs'; 

const ChartComp = ({ data, incomeData, subtitle, count, showDatePicker = false, onDateChange }) => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dateError, setDateError] = useState('');

  const formatDate = (date) => dayjs(date).format('MM-DD-YYYY'); 

  const handleDateChange = (newFromDate, newToDate) => {
    const formattedFromDate = dayjs(newFromDate);
    const formattedToDate = dayjs(newToDate);

    if (formattedFromDate.isAfter(formattedToDate)) {
      setDateError('From date must be earlier than or equal to To date');
      return;
    }

    setDateError('');

    if (newFromDate && newToDate) {
      onDateChange({
        fromDate: formatDate(newFromDate),
        toDate: formatDate(newToDate),
      });
    }
  };

  const handleFromDateChange = (e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);

    handleDateChange(newFromDate, toDate);
  };

  const handleToDateChange = (e) => {
    const newToDate = e.target.value;
    setToDate(newToDate);

    handleDateChange(fromDate, newToDate);
  };

  const today = dayjs().format('YYYY-MM-DD');
  const minDate = '2000-01-01';

  return (
    <Card
      bg="white"
      borderRadius={"39px"}
      style={{
        boxShadow: "0px 10px 99px #00000029",
      }}
      textAlign={"center"}
      alignSelf={"center"}
      w={"95%"}
      h={481}
      p={10}
    >
      <HStack w={'100%'} align={'center'} justify={'space-between'}>
        <VStack align="flex-start" spacing={2} mb={6}>
          <Text
            fontSize="31px"
            fontWeight="600"
            letterSpacing="-1.71px"
            color="#153373"
            opacity="1"
          >
            Reports & Analytics
          </Text>

          {subtitle && (
            <HStack>
              <Text
                fontSize="18px"
                fontWeight="400"
                lineHeight="30px"
                color="#707070"
              >
                {subtitle}
              </Text>
              {count && (
                <Text
                  fontSize="18px"
                  fontWeight="500"
                  lineHeight="30px"
                  color="#153373"
                  ml={2}
                >
                  {count}
                </Text>
              )}
            </HStack>
          )}
        </VStack>

        {showDatePicker && (
          <HStack>
            <FormControl isInvalid={!!dateError}>
              <Input
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                max={today}
                min={minDate}
                placeholder="Select From Date"
                borderRadius={26}
                borderColor={'#EEEEEE'}
                />
              <FormErrorMessage>{dateError}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!dateError}>
              <Input
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                max={today}
                min={minDate}
                placeholder="Select To Date"
                borderRadius={26}
                borderColor={'#EEEEEE'}
              />
              <FormErrorMessage>{dateError}</FormErrorMessage>
            </FormControl>
          </HStack>
        )}
      </HStack>

      <ResponsiveContainer width="100%" height={"80%"}>
        <BarChart data={data || incomeData}>
          <Tooltip />
          <Legend
            layout="horizontal"
            align="right"
            verticalAlign="top"
            wrapperStyle={{
              marginRight: -20,
              marginTop: -16,
            }}
          />
          {data && (
            <Bar
              dataKey="Users"
              fill="#153373"
              radius={[0, 0, 0, 0]}
              barSize={25}
              name="Users"
            />
          )}
          {incomeData && (
            <Bar
              dataKey="Amount"
              fill="#153373"
              radius={[0, 0, 0, 0]}
              barSize={25}
              name="Amount"
            />
          )}
          <XAxis
            dataKey="month"
            axisLine={{ stroke: '#153373' }}
            tickLine={{ stroke: '#153373' }}
            tick={{ fill: '#707070' }}
          />
          <YAxis
            axisLine={{ stroke: '#153373' }}
            tickLine={{ stroke: '#153373' }}
            tick={{ fill: '#707070' }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ChartComp;
