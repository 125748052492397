import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
  } from "react-google-recaptcha-v3";
  import {  Checkbox, HStack, Image, Text } from "@chakra-ui/react";
  import React from "react";
  
  // Component to handle reCAPTCHA logic
  const CaptchaButton = ({ onVerifyCaptcha }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
  
    const clickHandler = async () => {
      if (!executeRecaptcha) {
        console.warn("Recaptcha not ready");
        return;
      }
  
      try {
        // Execute reCAPTCHA to get the token
        const token = await executeRecaptcha("contact");
  
        // Send the token to your backend or Google Enterprise endpoint
        const response = await fetch(
          `https://recaptchaenterprise.googleapis.com/v1/projects/childvt-386504/assessments?key=6LfFCTEqAAAAAKmGh6rY0LmOaWL73m-BhHMZrIJf`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              event: {
                token,
                siteKey: "6LfFCTEqAAAAAKmGh6rY0LmOaWL73m-BhHMZrIJf",
                expectedAction: "contact",
              },
            }),
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to verify reCAPTCHA.");
        }
  
        const data = await response.json();
        const score = data?.riskAnalysis?.score || 0;
  
        console.log("reCAPTCHA Score:", score);
  
        if (score >= 0.5) {
          console.log("Good interaction");
        } else {
          console.warn("Bot detected");
        }
  
        onVerifyCaptcha(token);
      } catch (error) {
        console.error("Error with reCAPTCHA:", error);
      }
    };
  
    return (
      <HStack
        bg={"#F2F2F2"}
        border="2px"
        borderColor="#adacac"
        w={{ md: "100%", lg: "25%" }}
        mb={4}
        p={4}
        justifyContent={"space-between"}
      >
        <HStack>
          <Checkbox
            size="lg"
            backgroundColor={"white"}
            borderColor="#adacac"
            onChange={clickHandler}
          ></Checkbox>
          <Text color={"#707070"} fontSize={"13px"}>
            I'm not a robot
          </Text>
        </HStack>
        <Image src='./captcha.png' height={30} alt="Captcha" />
      </HStack>
    );
  };
  
  // ReCaptcha Provider component
  export const ReCaptcha = ({ onVerifyCaptcha }) => (
    <GoogleReCaptchaProvider reCaptchaKey="6LfFCTEqAAAAAKmGh6rY0LmOaWL73m-BhHMZrIJf">
      <CaptchaButton onVerifyCaptcha={onVerifyCaptcha} />
    </GoogleReCaptchaProvider>
  );
  