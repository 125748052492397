import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
       // eslint-disable-next-line
import { BackgroundAuthApi } from "../../Axios/Api";
import { Box } from "@chakra-ui/react";
import CustSpinner from "../Spinner/CustSpinner";

export default function AuthenticatedLayout({ children }) {
         // eslint-disable-next-line
  const { logged, user, login, logout } = useAuth();
  const navigate = useNavigate();
  const [proceed, setProceed] = useState(true);
  const tkn = Cookies.get("Token");

  useEffect(() => {
      if (!tkn) {
          navigate('/login');
          return;
      }

      // BackgroundAuthApi().then((response) => {
      //     const userData = response.data;
      //     login(userData, tkn);
      // }).catch((error) => {
      //     logout();
      //     console.error('Error during authentication:', error.response?.data?.message);
      // });

      if (logged) {
          if (user.account_status === 1) {
              setProceed(true);
          } else if (user.account_status === 0) {
              navigate('/verificationcode');
          } else {
              navigate('/login');
          }
      }
             // eslint-disable-next-line
  }, []);

  return (
    <>
      {proceed && logged ? (
        <Box display="flex" flexDirection="column" height="100%" width="100vw">
          {children}
        </Box>
      )
      :(
        <CustSpinner />
      )
    
    }
    </>
  );
}
