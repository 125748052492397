import React, { useState } from 'react';
import { HStack, Button, Text } from '@chakra-ui/react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <HStack spacing={4} justify="center" mt={4}>
      <Button
        size="sm"
        onClick={handlePrevious}
        isDisabled={currentPage === 1}
      >
        Previous
      </Button>

      <Text fontSize="md">
        Page {currentPage} of {totalPages}
      </Text>

      <Button
        size="sm"
        onClick={handleNext}
        isDisabled={currentPage === totalPages}
      >
        Next
      </Button>
    </HStack>
  );
};

export default Pagination;
