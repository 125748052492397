import React from 'react';
import { Button } from '@chakra-ui/react';

const CustomButton = ({ title, onClick, disabled = false, bg, py = 35, px = 34, ...props }) => {
  return (
    <Button
      onClick={onClick}
      isDisabled={disabled}
      background="var(--unnamed-color-102756)"
      backgroundColor={bg ? bg : "#102756"}
      boxShadow="15px 15px 50px #00000029"
      border="1px solid #FFFFFF40"
      borderRadius="100px"
      opacity={disabled ? 0.5 : 1}
      _hover={{ opacity: 0.8 }}
      color="white"
      padding={`${py}px ${px}px`}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      whiteSpace="pre-wrap"
      textOverflow="ellipsis"
      {...props}  
    >
      {title}
    </Button>
  );
};

export default CustomButton;
