import { Button, Flex, HStack, Text, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaStop } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import NeedLoginSignup from '../../../models/NeedLoginSignup';
import { useAuth } from '../../../providers/AuthProvider';
import SubscriptionPlan from '../../../pages/home/SubscriptionPlan';

export default function CarDetailsTopBar({vehicle}) {
    const { logged,user } = useAuth();
    let belongs = false;
    useEffect(()=>{
        
        if (logged && user.id === vehicle.owner_id) {
            belongs = true;
        }
    },[]);
    const [isMakeOffer, setIsMakeOffer] = useState(false);
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            {/* Web View */}
            <Flex
                maxW={'100%'}
                style={{
                    // backgroundImage: "url('./sell_a_vehicle.png')",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center'
                }}
                display={['none', 'none', 'none', 'flex']}
            >
                <VStack w={'100%'} alignItems={'start'} px={86} py={6}>
                    <Text
                        color={'#FFFFFF'}
                        fontSize={{ base: '1xl', md: '2xl' }}
                        fontWeight={'500'}
                        letterSpacing={1}
                    >
                        {`${vehicle?.make || '-'} ${vehicle?.vehicleModel}`}
                    </Text>

                    <HStack w={'100%'} alignItems={'start'} spacing={16}>

                        <HStack color={'#FFFFFF'} w={'100%'} alignItems={'start'}>
                            <VStack w={'100%'} alignItems={'start'} justify={'space-between'} spacing={8}>
                                <Text w={'100%'} ml={4} fontSize={'xs'} fontWeight={600}>Vehicle Color</Text>
                                <Text textAlign={'start'} ml={4} fontSize={'xs'} fontWeight={600}>Make</Text>
                            </VStack>

                            <VStack w={'100%'} alignItems={'start'} spacing={8} >
                                <HStack>
                                    <HStack spacing={0}>
                                        <Text fontSize={'xs'} pr={1}>Interior:</Text>
                                        <FaStop color={`${vehicle?.vehicleColorInterior}`} />
                                        <Text fontSize={'xs'}>{vehicle?.vehicleColorInterior}</Text>
                                    </HStack>

                                    <HStack spacing={0}>
                                        <Text fontSize={'xs'} ml={2} pr={1}>Exterior:</Text>
                                        <FaStop color={`${vehicle?.vehicleColorExterior}`} />
                                        <Text fontSize={'xs'}>{vehicle?.vehicleColorExterior}</Text>
                                    </HStack>
                                </HStack>

                                <Text opacity={'0.9'} fontSize={'xs'}>{vehicle?.make}</Text>
                            </VStack>
                        </HStack>

                        <HStack w={'60%'}>
                            <HStack color={'#FFFFFF'} spacing={16}>
                                <VStack w={'100%'} alignItems={'start'} justify={'space-between'} spacing={8}>
                                    <Text ml={4} fontSize={'xs'} fontWeight={600}>Miles</Text>
                                    <Text ml={4} fontSize={'xs'} fontWeight={600}>Model</Text>
                                </VStack>

                                <VStack w={'100%'} alignItems={'start'} justify={'space-between'} spacing={8}>
                                    <Text opacity={'0.9'} fontSize={'xs'}>{vehicle?.miles}</Text>
                                    <Text opacity={'0.9'} fontSize={'xs'}>{vehicle?.vehicleModel || '-'}</Text>
                                </VStack>
                            </HStack>
                        </HStack>

                        <HStack w={'60%'}>
                            <HStack color={'#FFFFFF'} spacing={16}>
                                <VStack w={'100%'} alignItems={'start'} justify={'space-between'} spacing={8}>
                                    <Text ml={4} fontSize={'xs'} fontWeight={600}>Trim</Text>
                                    <Text ml={4} fontSize={'xs'} fontWeight={600}>Year</Text>
                                </VStack>

                                <VStack w={'100%'} alignItems={'start'} justify={'space-between'} spacing={8}>
                                    <Text opacity={'0.9'} fontSize={'xs'}>{vehicle?.trim}</Text>
                                    <Text opacity={'0.9'} fontSize={'xs'}>{vehicle?.year}</Text>
                                </VStack>
                            </HStack>
                        </HStack>

                        {/* {!belongs && <HStack w={'100%'} justifyContent={'center'}>
                            <VStack w={'100%'} mx={6}>
                                {isMakeOffer ?
                                    <Button
                                        colorScheme="red"
                                        rounded={'22px'}
                                        w={'100%'}
                                        h={10}
                                        fontSize={'10px'}
                                        onClick={() => {
                                            if (logged) {
                                                navigate('/subscription-plan');
                                            } else {
                                                onOpen()
                                            }
                                        }}
                                    >
                                        View Cantact Details
                                    </Button>
                                    :
                                    <Button
                                        colorScheme="red"
                                        rounded={'22px'}
                                        w={'100%'}
                                        h={10}
                                        fontSize={'xs'}
                                        onClick={() => {
                                            if (logged) {
                                                navigate('/subscription-plan');
                                                // <SubscriptionPlan />
                                            } else {
                                                onOpen()
                                            }
                                        }}
                                    >
                                        Make Offer
                                    </Button>
                                }
                                <HStack w={'100%'}>
                                    <Button
                                        backgroundColor="white"
                                        rounded={'22px'}
                                        w={'100%'}
                                        h={10}
                                        fontSize={'xs'}
                                        color={'#102756'}
                                        onClick={() => {
                                            if (logged) {
                                                navigate('/request-trade');
                                            } else {
                                                onOpen()
                                            }
                                        }}
                                    >
                                        Request Trade
                                    </Button>

                                    <Button
                                        variant='outline'
                                        rounded={'22px'}
                                        w={'100%'}
                                        h={10}
                                        fontSize={'xs'}
                                        color={'white'}
                                        onClick={() => {
                                            if (logged) {
                                                navigate('/get-a-car-loan');
                                            } else {
                                                onOpen()
                                            }

                                        }}
                                    >
                                        Get a Car Loan
                                    </Button>

                                    <NeedLoginSignup isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

                                </HStack>
                            </VStack>
                        </HStack>} */}


                    </HStack>
                </VStack>
            </Flex>

            {/* Mobile View */}
            <Flex
                maxW={'100%'}
                style={{
                    // backgroundImage: "url('./sell_a_vehicle.png')",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center'
                }}
                display={['flex', 'flex', 'flex', 'none']}
            >
                <VStack w={'100%'} alignItems={'start'} mx={6} py={4}>
                    <Text
                        color={'#FFFFFF'}
                        fontSize={{ base: '1xl', md: '2xl' }}
                        fontWeight={'500'}
                        letterSpacing={1}
                    >
                        RAM RAM PICKUP
                    </Text>

                    <VStack w={'100%'} alignItems={'start'} spacing={2}>

                        <VStack w={'100%'} alignItems={'start'} color={'white'}>
                            <Text fontSize={'xs'} fontWeight={600}>Vehicle Color:</Text>
                            <HStack>
                            <HStack spacing={0}>
                                <Text fontSize={'xs'} pr={1}>Interior:</Text>
                                <FaStop color='grey' />
                                <Text fontSize={'xs'}>Grey</Text>
                            </HStack>

                            <HStack spacing={0}>
                                <Text fontSize={'xs'} ml={1} pr={1}>Exterior:</Text>
                                <FaStop color='green' />
                                <Text fontSize={'xs'}>Green</Text>
                            </HStack>
                            </HStack>
                        </VStack>

                        <HStack w={'100%'} color={'white'}>
                            <HStack w={'50%'} alignItems={'start'}>
                                <Text fontSize={'xs'} fontWeight={600}>Make</Text>
                                <Text fontSize={'xs'}>-</Text>
                            </HStack>

                            <HStack w={'50%'} alignItems={'start'}>
                                <Text fontSize={'xs'} fontWeight={600}>Model</Text>
                                <Text fontSize={'xs'}>-</Text>
                            </HStack>
                        </HStack>

                        <HStack w={'100%'} color={'white'}>
                            <HStack w={'50%'} alignItems={'start'}>
                                <Text fontSize={'xs'} fontWeight={600}>Mile</Text>
                                <Text fontSize={'xs'}>131,576</Text>
                            </HStack>

                            <HStack w={'50%'} alignItems={'start'}>
                                <Text fontSize={'xs'} fontWeight={600}>Trim</Text>
                                <Text fontSize={'xs'}>Vroom</Text>
                            </HStack>
                        </HStack>

                        {/* <VStack w={'100%'} alignItems={'center'}>
                            {isMakeOffer ?
                                <Button
                                    colorScheme="red"
                                    rounded={'22px'}
                                    w={'50%'}
                                    h={10}
                                    fontSize={'10px'}
                                    onClick={() => {
                                        if (logged) {
                                            navigate('/subscription-plan');
                                        } else {
                                            onOpen()
                                        }
                                    }}
                                >
                                    View Cantact Details
                                </Button>
                                :
                                <Button
                                    colorScheme="red"
                                    rounded={'22px'}
                                    w={'50%'}
                                    h={10}
                                    fontSize={'10px'}
                                    onClick={() => {
                                        if (logged) {
                                            navigate('/subscription-plan');
                                        } else {
                                            onOpen()
                                        }
                                    }}
                                >
                                    Make Offer
                                </Button>
                            }
                            <HStack w={'100%'} justify={'center'}>
                                <Button
                                    backgroundColor="white"
                                    rounded={'22px'}
                                    w={'25%'}
                                    h={8}
                                    fontSize={'6px'}
                                    color={'#102756'}
                                    onClick={() => {
                                        if (logged) {
                                            navigate('/sell-a-car');
                                        } else {
                                            onOpen()
                                        }
                                    }}
                                >
                                    Request Trade
                                </Button>

                                <Button
                                    variant='outline'
                                    rounded={'22px'}
                                    w={'25%'}
                                    h={8}
                                    fontSize={'6px'}
                                    color={'white'}
                                    onClick={() => {
                                        if (logged) {
                                            navigate('/get-a-car-loan');
                                        } else {
                                            onOpen()
                                        }

                                    }}
                                >
                                    Get a Car Loan
                                </Button>

                            </HStack>
                        </VStack> */}
                    </VStack>
                </VStack>
            </Flex>
        </>
    )
}
