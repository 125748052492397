import React, { useState } from "react";
import {
  Tr,
  Td,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { RiMoreLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const UserRow = ({
  _id,
  city,
  email,
  name,
  phoneNumber,
  state,
  createdAt,
  onCheckboxChange,
  onSuspend,
  onDelete,
  showTime = false,
  showDate = false,
  account_status,
  checkbox = false,
  actions = false,
  showId = false,
}) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    onCheckboxChange(checked, _id);
  };

  const textSize = useBreakpointValue({ base: "xs", md: "sm" });
  const hideOnSmallScreens = useBreakpointValue({
    base: "none",
    md: "table-cell",
  });

  const renderAccountStatus = (status) => {
    switch (status) {
      case 1:
        return { text: "Active", color: "#25BC9D" }; 
      case 0:
        return { text: "Inactive", color: "#BCB025" }; 
      case 4:
        return { text: "Suspended", color: "#FC6C00" }; 
      case 5:
        return { text: "Deleted", color: "#FC4656" }; 
      default:
        return { text: "Unknown", color: "#A0AEC0" }; 
    }
  };

  const { text: statusText, color: statusColor } = renderAccountStatus(account_status);

  return (
    <Tr>
      {/* Checkbox */}
      {checkbox && (
        <Td>
          <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
        </Td>
      )}

      {_id && showId && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
        >
          #{_id?.slice(0, 7)}
        </Td>
      )}

      {name && (
        <Td
          fontSize={textSize}
          color="#06A7E2"
          onClick={() => navigate("/car-details", { state: { id: _id } })}
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap="break-word"
          textOverflow="ellipsis"
        >
          {name}
        </Td>
      )}

      {/* Phone Number */}
      {phoneNumber && (
        <Td fontSize={textSize} whiteSpace="pre-wrap" wordWrap="break-word" textOverflow="ellipsis">
          {phoneNumber || "-"}
        </Td>
      )}

      {/* Email */}
      {email && (
        <Td fontSize={textSize} whiteSpace="pre-wrap" wordWrap="break-word" textOverflow="ellipsis">
          {email || "-"}
        </Td>
      )}

      {/* City */}
      {city && (
        <Td fontSize={textSize} whiteSpace="pre-wrap" wordWrap="break-word" textOverflow="ellipsis">
          {city || "-"}
        </Td>
      )}

      {/* State */}
      {state && (
        <Td fontSize={textSize} whiteSpace="pre-wrap" wordWrap="break-word" textOverflow="ellipsis">
          {state || "-"}
        </Td>
      )}

      {/* Account Status */}
      {account_status !== undefined && (
        <Td
          fontSize={textSize}
          color={statusColor}
          cursor="pointer"
          whiteSpace="pre-wrap"
          wordWrap="break-word"
          textOverflow="ellipsis"
        >
          {statusText}
        </Td>
      )}

      {/* Date Added (hidden on small screens) */}
      {showDate && (
        <Td fontSize={textSize} display={hideOnSmallScreens} whiteSpace="pre-wrap" wordWrap="break-word" textOverflow="ellipsis">
          {dayjs(createdAt).format("DD-MM-YYYY")}
        </Td>
      )}

      {/* Time Added (hidden on small screens) */}
      {showTime && (
        <Td fontSize={textSize} display={hideOnSmallScreens} whiteSpace="pre-wrap" wordWrap="break-word" textOverflow="ellipsis">
          {dayjs(createdAt).format("hh:mm A")}
        </Td>
      )}

      {/* Actions */}
      {actions && (
        <Td>
          <Menu>
            <MenuButton>
              <RiMoreLine />
            </MenuButton>
            <MenuList minWidth="120px">
                <MenuItem onClick={onSuspend}>
              {account_status === 4 ? (
                  <Text>Resume</Text>
                ) : (
                  <Text>Suspend</Text>
              )}
                </MenuItem>
              <MenuItem onClick={onDelete}>
                <Text color="#FC4656">Delete</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      )}
    </Tr>
  );
};

export default UserRow;
