import {
    Container,
    Flex,
    HStack,
    Image,
    Text,
    VStack,
    Box
} from '@chakra-ui/react'
import React, { useState } from 'react'
import CheckVehicleInfo from './CheckVehicleInfo';
import * as Yup from 'yup';
import { FaArrowLeftLong } from "react-icons/fa6";
import VinNumber from './VinNumber';
import CarCondition from './CarCondition';
import UploadPicture from './UploadPicture';
import SubscriptionPlan from './SubscriptionPlan';
import SupplementalResourses from './SupplementalResourses';
// import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { RegisterVehicleApi, UploadImageApi } from '../../../Axios/Api';
import { notifyError, notifySuccess } from '../../utils/Toast/ToastNotifications';
import LoadingModal from '../../LoadingModal/LoadingModal';
// import { useNavigate } from 'react-router-dom';


const validationSchema = Yup.object({
    vin_number: Yup.string()
        .required('VIN number is required')
        .matches(/^[A-HJ-NPR-Z0-9]{17}$/, 'Invalid VIN number'),
    mile: Yup.number()
        .required('Mileage is required')
        .positive('Mileage must be a positive number')
        .integer('Mileage must be an integer'),
    year: Yup.number()
        .required('Year is required')
        .min(2000, 'Year must be greater than or equal to 2000')
        .max(new Date().getFullYear(), `Year cannot be greater than ${new Date().getFullYear()}`),
    make: Yup.string().required('Make is required'),
    model: Yup.string().required('Model is required'),
    trim: Yup.string().required('Trim is required'),
    engine: Yup.string().required('Engine type is required'),
    // .matches(/^[A-HJ-NPR-Z0-9]$/, 'Invalid Engine number'),
    made_in: Yup.string().required('Country of manufacture is required'),
    style: Yup.string().required('Style is required'),
    s_seating: Yup.number()
        .required('Seating capacity is required')
        .positive('Seating capacity must be a positive number')
        .integer('Seating capacity must be an integer'),
    h_milage: Yup.string()
        .required('Highway mileage is required'),
        // .positive('Highway mileage must be a positive number'),
    c_milage: Yup.string()
        .required('City mileage is required'),
        // .positive('City mileage must be a positive number'),
    fuel_type: Yup.string().required('Fuel type is required'),
    fuel_capacity: Yup.string()
        .required('Fuel capacity is required'),
        // .positive('Fuel capacity must be a positive number'),
    vehicle_color_i: Yup.string().required('Interior color is required'),
    vehicle_color_e: Yup.string().required('Exterior color is required'),
    vehiclePhotos: Yup.array()
        .of(
            Yup.mixed().test(
                'fileSize',
                'File size is too large',
                value => value && value.size <= 25 * 1024 * 1024 
            )
        )
        // .test('required', 'At least one photo is required', value => value && value.length > 0 )
        .test('required', 'All photo is required', value => value && value.length === 7),

});




export default function SellaCar() {
    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    // const navigate = useNavigate();

    const handleSubmit =(values)=>{
        setIsLoading(true);
        RegisterVehicleApi(values)
        .then((response)=>{
            notifySuccess(response.message);
            UploadImage({vehicleId:response.data._id,values});
        }).catch((error)=>{
            notifyError(error?.response?.data?.message)
        })
        setTimeout(() => {
            setIsLoading(false);
          }, 3000);

    }

    const UploadImage =({vehicleId,values})=>{

        UploadImageApi({vehicleId,values})
        .then((response)=>{
            notifySuccess(response.message);
            setCurrentStep(currentStep + 1);
            // navigate('/verificationcode', { state: { refId: response.data.id } });
        }).catch((error)=>{
            notifyError(error.response.data.message)
        })

    }

    const formik = useFormik({
        initialValues: {
            vin_number: "",
            mile: "",
            year: "",
            make: "",
            model: "",
            trim: "",
            engine: "",
            made_in: "",
            style: "",
            s_seating: "",
            h_milage: "",
            c_milage: "",
            fuel_type: "",
            fuel_capacity: "",
            vehicle_color_i: "",
            vehicle_color_e: "",
            vehiclePhotos: [],
            vehicleCondition:{
                drivability:{
                    answers:[false,false,false,false]
                },
                safety:{
                    answers:[false,false,false,false,false,false,false]
                },
                appearance:{
                    answers:[false,false,false,false]
                },
                conform:{
                    answers:[false,false,false]
                },
                paperwork:{
                    answers:[false],
                    sellerDesciption:""
                },

            }
        },
        validationSchema: validationSchema, 
        onSubmit:values=>handleSubmit(values),
        validateOnChange: true,
        validateOnBlur:true,
    });


    return (

        <>
         <LoadingModal isOpen={isLoading} />
        <VStack py={6}>

            <HStack w={{ base: '100%', md: '90%' }} pl={{ base: '4', md: '0' }}>
                <HStack>
                    {currentStep < 1 ?
                        <></>
                        :
                        <Box bg='gray.200' w='40px' h='40px' display='flex' justifyContent='center' alignItems='center' rounded='50%'>
                            <FaArrowLeftLong onClick={() => setCurrentStep(currentStep - 1)} />
                        </Box>
                    }
                </HStack>
                <Text
                    w={{ base: '60%', md: '52%' }}
                    textAlign={{ base: `${currentStep < 1 ? 'end' : 'center'}`, md: 'end' }}
                    fontSize={'xl'}
                    color={'#1D1C1A'}
                    fontWeight={600}
                    opacity={'0.9'}
                >
                    Sell a Car
                </Text>
            </HStack>

            <HStack w='100%' justifyContent='center'>

                {/* Mobile View */}
                <Flex
                    mt={4}
                    w={'45%'}
                    alignItems={'center'}
                    bg={'white'}
                    borderRadius={'48px'}
                    style={{
                        boxShadow: '0px 10px 99px #00000029'
                    }}
                    fontSize={{ base: 'xs' }}
                    display={{ base: 'flex', md: 'none', lg: 'none' }}
                >

                    {currentStep === 0 ?
                        <HStack p={2} ml={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 1</Text>
                        </HStack>
                        : currentStep === 1 ?
                        <HStack p={2} ml={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                        </HStack>
                        : currentStep === 2 ?
                        <HStack p={2} ml={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 3</Text>
                        </HStack>
                        : currentStep === 3 ?
                        <HStack p={2} ml={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 4</Text>
                        </HStack>
                        : currentStep === 4 ?
                        <HStack p={2} ml={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 5</Text>
                        </HStack>
                        :
                        <HStack p={2} ml={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 6</Text>
                        </HStack>
                    }

                </Flex>

                {/* web View */}
                <Flex
                    w={{ md: '90%', lg: '52%' }}
                    bg={'white'}
                    borderRadius={'48px'}
                    justify={'space-between'}
                    style={{
                        boxShadow: '0px 10px 99px #00000029'
                    }}
                    fontSize={{ base: 'xs' }}
                    display={{ base: 'none', md: 'flex', lg: 'flex' }}
                >

                    {currentStep === 0 ?
                        <HStack p={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 1</Text>
                        </HStack>
                        :
                        <>
                            <HStack p={4}>
                                <Image src={'./check.png'} h={35} />
                                <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 1</Text>
                            </HStack>
                        </>
                    }

                    {currentStep === 1 ?
                        <HStack p={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                        </HStack>
                        : currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6 ?
                            <>
                                <HStack p={4}>
                                    <Image src={'./check.png'} h={35} />
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                                </HStack>
                            </>
                            :
                            <>
                                <HStack p={4}>
                                    <Container w={8} h={8} borderRadius={50} bg={'#EFEFEF'} ></Container>
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 2</Text>
                                </HStack>
                            </>
                    }

                    {currentStep === 2 ?
                        <HStack p={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 3</Text>
                        </HStack>
                        : currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6 ?
                            <>
                                <HStack p={4}>
                                    <Image src={'./check.png'} h={35} />
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 3</Text>
                                </HStack>
                            </>
                            :
                            <>
                                <HStack p={4}>
                                    <Container w={8} h={8} borderRadius={50} bg={'#EFEFEF'} ></Container>
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 3</Text>
                                </HStack>
                            </>
                    }

                    {currentStep === 3 ?
                        <HStack p={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 4</Text>
                        </HStack>
                        : currentStep === 4 || currentStep === 5 || currentStep === 6 ?
                            <>
                                <HStack p={4}>
                                    <Image src={'./check.png'} h={35} />
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 4</Text>
                                </HStack>
                            </>
                            :
                            <>
                                <HStack p={4}>
                                    <Container w={8} h={8} borderRadius={50} bg={'#EFEFEF'} ></Container>
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 4</Text>
                                </HStack>
                            </>
                    }

                    {currentStep === 4 ?
                        <HStack p={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 5</Text>
                        </HStack>
                        : currentStep === 5 || currentStep === 6 ?
                            <>
                                <HStack p={4}>
                                    <Image src={'./check.png'} h={35} />
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 5</Text>
                                </HStack>
                            </>
                            :
                            <>
                                <HStack p={4}>
                                    <Container w={8} h={8} borderRadius={50} bg={'#EFEFEF'} ></Container>
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 5</Text>
                                </HStack>
                            </>
                    }

                    {currentStep === 5 ?
                        <HStack p={4}>
                            <Image src={'./done_outline.png'} h={45} />
                            <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 6</Text>
                        </HStack>
                        : currentStep === 6 ?
                            <>
                                <HStack p={4}>
                                    <Image src={'./check.png'} h={35} />
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 6</Text>
                                </HStack>
                            </>
                            :
                            <>
                                <HStack p={4}>
                                    <Container w={8} h={8} borderRadius={50} bg={'#EFEFEF'} ></Container>
                                    <Text color={'#153373'} fontSize={'xs'} fontWeight={'500'} opacity={'0.8'}>Step 6</Text>
                                </HStack>
                            </>
                    }
                </Flex>
            </HStack>

            { currentStep === 0 ?
                <VinNumber currentStep={currentStep} setCurrentStep={setCurrentStep} formik={formik}/>
                : <></>
            }

            { currentStep === 1 ?
                <CheckVehicleInfo currentStep={currentStep} setCurrentStep={setCurrentStep} formik={formik} />
                : <></>
            }

            { currentStep === 2 ?
                <UploadPicture currentStep={currentStep} setCurrentStep={setCurrentStep} formik={formik} />
                : <></>
            }

            { currentStep === 3 ?
                <CarCondition currentStep={currentStep} setCurrentStep={setCurrentStep} formik={formik} />
                : <></>
            }

            { currentStep === 4 ?
                <SubscriptionPlan currentStep={currentStep} setCurrentStep={setCurrentStep} formik={formik} />
                : <></>
            }

            { currentStep === 5 ?
                <SupplementalResourses currentStep={currentStep} setCurrentStep={setCurrentStep} formik={formik} />
                : <></>
            }
        </VStack>
        </>

    )
}
