import React, { useEffect, useState } from "react";
import NavBar from "../../../components/home/navBar/NavBar";
import BreadCrumb from "../../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../../components/Spinner/CustSpinner";
import NoDataComponent from "../../../components/utils/NoDataComponent";
import { HStack, SimpleGrid, Td, Text, Tr, VStack } from "@chakra-ui/react";
import { useAuth } from "../../../providers/AuthProvider";
import {
  GetVehicleBuyRequestByIdApi,
} from "../../../Axios/Api";
import BottomBarStatic from "../../../components/home/footer/BottomBarSatic";
import TableComp from "../../../components/Table/TableComp";
import BackButton from "../../../components/BackButton/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedLayout from "../../../components/auth/AuthenticatedLayout";
import BuyRequestRow from "../../../components/car/BuyRequestRow/BuyRequestRow";

export default function CarsBuyRequestScreen() {
  const location = useLocation();
  const { id } = location.state || {};
  const { logged } = useAuth();
  const [vehicles, setVehicles] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true);
      try {
        const response = await GetVehicleBuyRequestByIdApi(id);
        setVehicles(response);
      } catch (error) {
        console.error("Failed to fetch vehicles", error);
      }
      setLoading(false);
    };

    fetchVehicles();
  }, [id]);



  const titles = [
    { title: "Request Id" },
    { title: "Requested By" },
    { title: "Buy Request Date" },
    { title: "Buy Request Offer" },
    { title: "Type Of User" },
  ];

  const carDetails = [
    {
      title: "VIN",
      subtitle: vehicles?.buyRequests[0]?.vehicleDetails?.vin || "-",
    },
    {
      title: "Current Miles",
      subtitle: vehicles?.buyRequests[0]?.vehicleDetails?.miles || "-",
    },
    {
      title: "Year",
      subtitle: vehicles?.buyRequests[0]?.vehicleDetails?.year || "-",
    },
    {
      title: "Make",
      subtitle: vehicles?.buyRequests[0]?.vehicleDetails?.make || "-",
    },
    {
      title: "Model",
      subtitle: vehicles?.buyRequests[0]?.vehicleDetails?.vehicleModel || "-",
    },
    {
      title: "Original Price",
      subtitle:
        `$${vehicles?.buyRequests[0]?.vehicleDetails?.price || 0}` || "-",
    },
  ];

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && (
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <HStack align={"center"} justify={"center"}>
              <BackButton />
              <Text
                fontWeight={"600"}
                color={"#fff"}
                letterSpacing={"-2.53px"}
                fontSize={"46px"}
                opacity={1}
              >
                Buy Requests{" "}
              </Text>
            </HStack>
          }
        />
      )}

      <VStack w={"95%"} h={"auto"} minHeight={"100vh"} py={"8"}>
        {loading ? (
          <CustSpinner />
        ) : (
          <VStack w={"95%"} h={"auto"} py={"8"}>
            <HStack
              h={"fit-content"}
              w={"100%"}
              justify={"space-between"}
              align={"center"}
              p={2}
              m={1}
            >
              <Text
                fontWeight={"600"}
                color={"#1D1C1A"}
                opacity={"0.8"}
                fontSize={"25px"}
                textAlign={"left"}
              >
                Vehicle Specifications
              </Text>
              <Text
                fontWeight={"600"}
                color={"#153373"}
                opacity={"0.8"}
                fontSize={"16px"}
                textAlign={"right"}
                onClick={() =>
                  navigate("/car-details", {
                    state: { id: vehicles?.buyRequests[0]?.vehicleDetails._id },
                  })
                }
                cursor="pointer"
              >
                More Car Details
              </Text>
            </HStack>
            <HStack
              w={"100%"}
              justify={{ base: "flex-start", md: "space-evenly" }}
              align={"flex-start"}
              flexWrap="wrap"
              spacing={4}
            >
              {carDetails &&
                carDetails.map((item, index) => {
                  return (
                    <VStack
                      key={index}
                      w={{ base: "100%", md: "45%", lg: "30%" }}
                      mb={{ base: 4, md: 0 }}
                    >
                      <Text
                        fontSize={{ base: "16px", md: "18px" }}
                        fontWeight="500"
                        color="#000"
                        wordBreak="break-word"
                      >
                        {item.title}
                      </Text>
                      <Text
                        fontSize={{ base: "14px", md: "18px" }}
                        fontWeight="500"
                        color="#707070"
                        ml={2}
                        wordBreak="break-word"
                      >
                        {item.subtitle}
                      </Text>
                    </VStack>
                  );
                })}
            </HStack>

            <HStack
              h={"fit-content"}
              w={"100%"}
              justify={"flex-start"}
              align={"center"}
              textAlign={"left"}
              p={2}
              mt={10}
            >
              <Text
                fontWeight={"500"}
                color={"#1D1C1A"}
                opacity={"1"}
                fontSize={"25px"}
              >
                Buy Requests
              </Text>
            </HStack>
            {titles ? (
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100%"}
                justifyItems={"center"}
                alignItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <TableComp title={titles}>
                  {vehicles?.buyRequests[0]?.buyers &&
                  vehicles?.buyRequests[0]?.buyers.length > 0 ? (
                    vehicles?.buyRequests[0]?.buyers?.map((requests) => (
                      <BuyRequestRow
                        key={requests.requestDetails._id}
                        _id={requests.requestDetails._id}
                        name={`${requests.buyerDetails.firstName} ${requests.buyerDetails.lastName}`}
                        createdAt={requests.requestDetails.createdAt}
                        showDate={true}
                        showId={true}
                      />
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="10" textAlign="center" py={10}>
                        No vehicles available
                      </Td>
                    </Tr>
                  )}
                </TableComp>
              </SimpleGrid>
            ) : (
              <NoDataComponent type="nocars" />
            )}
          </VStack>
        )}
      </VStack>

      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
