import {
  Flex,
  HStack,
  Image,
  Button,
  Text,
  IconButton,
  VStack,
  Menu,
  MenuButton,
  Container,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { useAuth } from "../../../providers/AuthProvider";
import LoggedOptions from "./LoggedOptions";
import { IoMdNotifications } from "react-icons/io";
import { Divider } from "@chakra-ui/react";

export default function NavBar() {
  const [display, changeDisplay] = useState("none");
  const location = useLocation();
  const { logged } = useAuth();

  return (
    <Flex
      maxW="100%"
      h={"8%"}
      bg={"#102756"}
      fontSize="12px"
      position={"sticky"}
      top={0}
      zIndex={100}
    >
      <HStack
        w={"100%"}
        p={4}
        justifyContent={{
          base: "space-evenly",
          md: "space-between",
        }}
        mx={{ base: "0", md: "12" }}
      >
        <Image
          src="/logo.png"
          display={{ base: "block", md: "flex" }}
          h={{ base: "5", md: "30" }}
          maxW={{ base: "300px", md: "330px" }}
          objectFit="contain"
          alt="Logo"
        />

        <HStack
          color={"#FFFFFF"}
          spacing={4}
          display={["none", "none", "none", "flex"]}
        >
          <Link to={"/"}>
            <Text
              opacity={"0.8"}
              _hover={{ color: "#25BC9D" }}
              color={location.pathname === "/" ? "#25BC9D" : ""}
            >
              Home
            </Text>
          </Link>

          <Link to={"/content-management"}>
            <Text
              opacity={"0.8"}
              _hover={{ color: "#25BC9D" }}
              color={
                location.pathname.includes("content-management")
                  ? "#25BC9D"
                  : ""
              }
            >
              Content Management
            </Text>
          </Link>

          <Link to={"/reports"}>
            <Text
              opacity={"0.8"}
              _hover={{ color: "#25BC9D" }}
              color={location.pathname.includes("reports") ? "#25BC9D" : ""}
            >
              Reports
            </Text>
          </Link>
          <Link to={"/subscriptions"}>
            <Text
              opacity={"0.8"}
              _hover={{ color: "#25BC9D" }}
              color={
                location.pathname.includes("Subscriptions") ? "#25BC9D" : ""
              }
            >
              Subscriptions
            </Text>
          </Link>

          {logged ? (
            <>
              <Flex display={["none", "none", "flex", "flex"]}>
                <LoggedOptions />
              </Flex>
            </>
          ) : (
            <HStack display={["none", "none", "none", "flex"]} mx={8}>
              <Link to={"/login"}>
                <Button
                  fontSize={"xs"}
                  type="submit"
                  bg="#FFFFFF"
                  rounded={"22px"}
                  w={120}
                  h={10}
                >
                  Log In
                </Button>
              </Link>
              <Link to={"/register"}>
                <Button
                  fontSize={"xs"}
                  type="submit"
                  colorScheme="red"
                  rounded={"22px"}
                  w={120}
                  h={10}
                >
                  Sign Up
                </Button>
              </Link>
            </HStack>
          )}
        </HStack>

        {/* Mobile Navbar Menu */}

        <HStack
          display={{ base: "flex", md: "none" }}
          w={"100%"}
          justify={"space-between"}
          mx={4}
        >
          <Image
            src="/short_logo.png"
            display={{ base: "flex", md: "none" }}
            h={{ base: "30" }}
          />

          <HStack>
            {logged ? (
              <>
                <Menu display={{ base: "flex", md: "none" }}>
                  <MenuButton>
                    <IoMdNotifications color="white" size="28" />

                    <Container>
                      <Box
                        bg="#E22937"
                        w="15px"
                        h="15px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        rounded="50%"
                        position={"absolute"}
                        top="4"
                      >
                        <Text color={"#FFFFFF"} fontSize={"6px"}>
                          2
                        </Text>
                      </Box>
                    </Container>
                  </MenuButton>
                  <MenuList rounded="xl" bg="white" p="3">
                    <Text
                      color={{ base: "black", md: "grey" }}
                      as="h5"
                      fontSize="md"
                      py="2"
                    >
                      Notifications
                    </Text>
                    <MenuItem maxWidth="240px">
                      <VStack alignItems="start">
                        <HStack>
                          <Box
                            bg="#9F9F9F"
                            w="30px"
                            h="30px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            rounded="50%"
                          >
                            <IoMdNotifications color="white" size="18" />
                          </Box>

                          <VStack spacing="0.3" alignItems={"start"}>
                            <Text color={{ base: "black", md: "grey" }}>
                              Jane Doe
                            </Text>
                            <Text
                              color={{ base: "black", md: "grey" }}
                              fontSize={"9px"}
                            >
                              2 hrs ago
                            </Text>
                          </VStack>
                        </HStack>
                        <Text
                          color={"#111111"}
                          opacity={"0.8"}
                          fontSize={"10px"}
                        >
                          lorem ispas dasjd asdasdkajs dk lorem ispas dasjd
                          lorem ispas dasjd lorem ispas dasjd{" "}
                        </Text>
                        <Divider
                          borderWidth={"0.5px"}
                          borderColor={"#ebebeb"}
                          w={"100%"}
                        />
                      </VStack>
                    </MenuItem>
                    <MenuItem maxWidth="240px">
                      <VStack alignItems="start">
                        <HStack>
                          <Box
                            bg="#9F9F9F"
                            w="30px"
                            h="30px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            rounded="50%"
                          >
                            <IoMdNotifications color="white" size="18" />
                          </Box>
                          <VStack spacing="0.5" alignItems={"start"}>
                            <Text color={{ base: "black", md: "grey" }}>
                              Jane Doe
                            </Text>
                            <Text
                              color={{ base: "black", md: "grey" }}
                              fontSize={"9px"}
                            >
                              2 hrs ago
                            </Text>
                          </VStack>
                        </HStack>
                        <Text
                          color={"#111111"}
                          opacity={"0.8"}
                          fontSize={"10px"}
                        >
                          lorem ispas dasjd asdasdkajs dk lorem ispas dasjd
                          lorem ispas dasjd lorem ispas dasjd{" "}
                        </Text>
                      </VStack>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </>
            ) : (
              <></>
            )}
            <IconButton
              aria-label="Open Menu"
              size="sm"
              icon={<IoMenu />}
              display={["flex", "flex", "flex", "none"]}
              onClick={() => changeDisplay("flex")}
            />
          </HStack>
        </HStack>

        <Flex
          w={"50%"}
          bg={"#FFFFFF"}
          zIndex={20}
          h={"100vh"}
          pos={"fixed"}
          top={"0"}
          left={"0"}
          overflow={"auto"}
          flexDir={"column"}
          display={display}
        >
          <VStack>
            {logged ? (
              <LoggedOptions changeDisplay={changeDisplay} />
            ) : (
              // <HStack display={['none', 'none', 'none', 'flex']}>
              <>
                <VStack
                  w={"100%"}
                  alignItems={"end"}
                  justifyItems={"end"}
                  mt={4}
                  mr={6}
                >
                  <IconButton
                    aria-label="Open Menu"
                    size={"sm"}
                    icon={<IoMdClose />}
                    onClick={() => changeDisplay("none")}
                  />
                </VStack>

                {/* <VStack spacing={2} py={4} alignItems={'start'}>
                                <Link to={'/'}>
                        <Text
                            opacity={'0.8'}
                            _hover={{ color: '#25BC9D' }}
                            color={location.pathname === "/" ? "#25BC9D" : ''}
                        >
                            Home</Text>
                    </Link>

                    <Link to={'/content-management'}>
                        <Text
                            opacity={'0.8'}
                            _hover={{ color: '#25BC9D' }}
                            color={location.pathname.includes("content-management") ? "#25BC9D" : ''}
                        >
                            Content Management</Text>
                    </Link>

                    <Link to={'/reports'}>
                        <Text
                            opacity={'0.8'}
                            _hover={{ color: '#25BC9D' }}
                            color={location.pathname.includes("reports") ? "#25BC9D" : ''}
                        >
                            Reports</Text>
                    </Link>
                    <Link to={'/subscriptions'}>
                        <Text
                            opacity={'0.8'}
                            _hover={{ color: '#25BC9D' }}
                            color={location.pathname.includes("Subscriptions") ? "#25BC9D" : ''}
                        >
                            Subscriptions</Text>
                    </Link>
                                </VStack> */}

                <VStack mt={2}>
                  <Link to={"/login"}>
                    <Button
                      type="submit"
                      bg="#153373"
                      color={"#ffffff"}
                      rounded={"22px"}
                      w={120}
                      h={10}
                    >
                      Login
                    </Button>
                  </Link>
                  <Link to={"/register"}>
                    <Button
                      type="submit"
                      colorScheme="red"
                      rounded={"22px"}
                      w={120}
                      h={10}
                    >
                      Sign Up
                    </Button>
                  </Link>
                </VStack>
              </>
            )}
          </VStack>
        </Flex>
      </HStack>
    </Flex>
  );
}
