import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { useAuth } from '../../providers/AuthProvider';
import { notifyError } from '../utils/Toast/ToastNotifications';
import { GetAllUserVehicleApi } from '../../Axios/Api';
import CarRow from './CarRow/CarRow';

export default function MyListings() {
    const { logged } = useAuth();
    const [isMylisting, setIsMyListing] = useState([]);

    useEffect(() => {
        const handleMyListing = async () => {
            if (!logged)return;
            try {
                const response = await GetAllUserVehicleApi();
                setIsMyListing(response.data)
            } catch (error) {
              notifyError(error.response.data.message);
            }
          };
        handleMyListing();
    }, [logged]);
  
   




    const handleCheckboxChange = async () => {
      if (!logged)return;
      
      try {

        //   const response = await GetAllUserVehicleApi();
        //   setIsMyListing(...response.data)
      } catch (error) {
        notifyError(error.response.data.message);
      }
    };
    return (
        <TableContainer w={'100%'} maxH={'100vh'} overflowY={'auto'}>
            <Table variant='striped'>
                <Thead >
                    <Tr>
                        <Th w={12}> <Checkbox /> </Th>
                        <Th w={20}></Th>
                        <Th>Request Id</Th>
                        <Th>Submission Date</Th>
                        <Th>Submission Time</Th>
                        <Th>Status</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>



         {isMylisting && isMylisting.length > 0 ? isMylisting?.map(vehicle => (
          <CarRow
            key={vehicle._id}
            _id={vehicle._id}
            vehiclePhotos={vehicle.vehiclePhotos}
            createdAt={vehicle.createdAt}
            onCheckboxChange={handleCheckboxChange}
          />
        )) : (
            <Tr>
            <Td colSpan="7" textAlign="center" py={10}>
              No vehicles available
            </Td>
          </Tr>
        )}

                </Tbody>
            </Table>
        </TableContainer>
    )
}
