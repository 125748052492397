import { Card, Text, HStack } from '@chakra-ui/react';
import React from 'react';

const CustCard = ({ title, subtitle, key = 0 }) => {
  return (
    <Card
      key={key}
      bg="white"
      borderRadius={"39px"}
      style={{
        boxShadow: "0px 10px 99px #00000029",
      }}
      textAlign={"center"}
      minWidth={297}
      minHeight={149}
      p={8}
    >
      <HStack
        h={"50%"}
        w={"100%"}
        justify={'flex-start'}
        align={'center'}
        overflow="hidden"
      >
        <Text
          color={"#153373"}
          opacity={"0.8"}
          fontSize={['24px', '30px', '36px', '39px']} 
          isTruncated
        >
          {title}
        </Text>
      </HStack>
      <HStack
        h={"50%"}
        w={"100%"}
        justify={'flex-start'}
        align={'center'}
        overflow="hidden"
      >
        <Text
          color={"#000"}
          opacity={"0.8"}
          fontSize={['16px', '18px', '20px', '22px']} 
          isTruncated
        >
          {subtitle}
        </Text>
      </HStack>
    </Card>
  );
};

export default CustCard;
