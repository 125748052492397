import React, { useEffect, useState } from 'react'
import CarDetailsTopBar from '../../components/home/navBar/CarDetailsTopBar'
import Footer from '../../components/home/footer/Footer'
import { VStack, Text, SimpleGrid, HStack, Image, Skeleton } from '@chakra-ui/react'
import VehicleRecords from '../../components/car/VehicleRecords'
import NavBar from '../../components/home/navBar/NavBar'
import { useLocation } from 'react-router-dom'
import { GetVehicleByIdApi } from '../../Axios/Api'
import { CdnLink } from '../../components/utils/ProviderFunctions'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'; 
import BreadCrumb from '../../components/home/navBar/BreadCrumb'
import BackButton from '../../components/BackButton/BackButton'
import AuthenticatedLayout from '../../components/auth/AuthenticatedLayout'

export default function CarDetails() {
  const location = useLocation();
  const { id } = location.state || {};
  const [vehicles, setVehicles] = useState({});
  const [isState, setState] = useState({
    isOpen: false,
    photoIndex: 0,
    photos: [],
  });

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await GetVehicleByIdApi(id);
        setVehicles(response.data);
      } catch (error) {
        console.error("Failed to fetch vehicles", error);
      }
    };

    fetchVehicles();
  }, [id]);

  useEffect(() => {
    if (vehicles.vehiclePhotos) {
      const photoUrls = vehicles.vehiclePhotos.map(photo => CdnLink(photo));
      setState(prevState => ({ ...prevState, photos: photoUrls }));
    }
  }, [vehicles]);

  return (
    <AuthenticatedLayout>
      <NavBar />
        <BreadCrumb
          imageUrl={"url('./sell_a_vehicle.png')"}
          title={
            <VStack 
            maxW={'100%'} 
            alignItems={{ base: 'center', md: 'start' }} 
          >
            <HStack align={"center"} justify={"center"}>
              <BackButton/>
              <Text fontWeight={"600"} color={"#fff"} letterSpacing={"-2.53px"} fontSize={"46px"} opacity={1}>Car Details </Text>
            </HStack>
      <CarDetailsTopBar vehicle={vehicles} />
          </VStack>
          }
        />

      <VStack 
        maxW={'100%'} 
        alignItems={{ base: 'center', md: 'start' }} 
        px={{ base: 4, md: 22, lg: 28 }} 
        py={4}
      >
        <Text color={'#153373'} fontWeight={600} fontSize={'lg'}>
          More Vehicle Specifications
        </Text>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 4 }}
          w={'100%'}
          py={4}
          spacing={10}
        >
          <VStack alignItems={'start'}>
            <HStack spacing={4}>
              <Text fontWeight={700} fontSize={'xs'}>Engine</Text>
              <Text fontSize={'xs'} >{vehicles?.engine || '-'}</Text>
            </HStack>
            <HStack spacing={4}>
              <Text fontWeight={700} fontSize={'xs'}>Highway Mileage</Text>
              <Text fontSize={'xs'}>{vehicles?.highwayMileage || '0'} miles/gallon</Text>
            </HStack>
          </VStack>

          <VStack alignItems={'start'}>
            <HStack spacing={4}>
              <Text fontWeight={700} fontSize={'xs'}>Made In</Text>
              <Text fontSize={'xs'} >{vehicles?.madeIn || '-'}</Text>
            </HStack>
            <HStack spacing={4}>
              <Text fontWeight={700} fontSize={'xs'}>City Mileage</Text>
              <Text fontSize={'xs'}>{vehicles?.cityMileage ||'-'} miles/gallon</Text>
            </HStack>
          </VStack>

          <VStack alignItems={'start'}>
            <HStack spacing={4}>
              <Text fontWeight={700} fontSize={'xs'}>Style</Text>
              <Text fontSize={'xs'} >{vehicles?.style || '-'}</Text>
            </HStack>
            <HStack spacing={4}>
              <Text fontWeight={700} fontSize={'xs'}>Fuel Type</Text>
              <Text fontSize={'xs'}>{vehicles?.fuelType || '-'}</Text>
            </HStack>
          </VStack>

          <VStack alignItems={'start'}>
            <HStack spacing={4}>
              <Text fontWeight={700} fontSize={'xs'}>Standard Seating</Text>
              <Text fontSize={'xs'} >{vehicles?.standardSeating || '-'}</Text>
            </HStack>
            <HStack spacing={4}>
              <Text fontWeight={700} fontSize={'xs'}>Fuel Capacity</Text>
              <Text fontSize={'xs'}>{vehicles?.fuelCapacity || '-'} gallon</Text>
            </HStack>
          </VStack>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          w={'auto'}
          alignContent={'center'}
          py={4}
          spacing={2}
        >
          {vehicles?.vehiclePhotos?.map((vehicle, i) => (
            <Image 
              key={i}
              src={CdnLink(vehicle)} 
              width={500} 
              alt='car image' 
              maxHeight={190} 
              fit={'cover'} 
              align={'center'} 
              borderRadius={8} 
              fallback={<Skeleton height="190px" borderRadius={'lg'} />}
              onClick={() => setState(prev => ({ ...prev, isOpen: true, photoIndex: i }))}
            />
          ))}
        </SimpleGrid>
      </VStack>
      <VehicleRecords vehicle={vehicles} />
      <Footer />
      {isState.isOpen && (
        <Lightbox
          mainSrc={isState.photos[isState.photoIndex]}
          nextSrc={isState.photos[(isState.photoIndex + 1) % isState.photos.length]}
          prevSrc={isState.photos[(isState.photoIndex + isState.photos.length - 1) % isState.photos.length]}
          onCloseRequest={() => setState(prev => ({ ...prev, isOpen: false }))}
          onMovePrevRequest={() => setState(prev => ({ ...prev, photoIndex: (isState.photoIndex + isState.photos.length - 1) % isState.photos.length }))}
          onMoveNextRequest={() => setState(prev => ({ ...prev, photoIndex: (isState.photoIndex + 1) % isState.photos.length }))}
        />
      )}
    </AuthenticatedLayout>
  )
}
