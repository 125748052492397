import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image, Text } from '@chakra-ui/react';
import NoCarFound from '../../Assets/NoDataImage/SearchingCar.png';
import NoCarFoundColor from '../../Assets/NoDataImage/SearchingCarColor.png';
import NoCarFoundColor1 from '../../Assets/NoDataImage/SearchingCarColor1.png';
import noCars from '../../Assets/NoDataImage/no_car.png'
const NoDataComponent = ({ type }) => {
  let imageSrc;
  let message;
  let submessage;

  switch (type) {
    case 'nocar':
      imageSrc = NoCarFound;
      message = ' OOPS! NO CARS AVAILABLE';
      submessage=`Sorry! This car is not available right now. We will notify you once it's available.`
      break;
    case 'nouser':
      imageSrc = NoCarFound;
      message = ' OOPS! NO Users AVAILABLE';
      submessage=`Sorry! This User is not available right now. We will notify you once it's available.`
      break;
    case 'nocars':
      imageSrc = noCars;
      message = ' OOPS! NO CARS AVAILABLE';
      submessage=`Sorry! This car is not available right now. We will notify you once it's available.`
      break;
    case 'nocarcolor':
      imageSrc = NoCarFoundColor;
      message = ' OOPS! NO CARS AVAILABLE';
      submessage=`Sorry! This car is not available right now. We will notify you once it's available.`
      break;
    case 'nocarcolor1':
      imageSrc = NoCarFoundColor1;
      message = ' OOPS! NO CARS AVAILABLE';
      submessage=`Sorry! This car is not available right now. We will notify you once it's available.`
      break;

    default:
      imageSrc = null;
      message = 'No Data Found';
      break;
  }

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="50vh" 
      width="100vw"
      textAlign="center"
      p={3}
    >
      {
      
      imageSrc && type==='nocars' ?
     ( 
      <Image src={imageSrc}  boxSize="auto"/>
      )
:
(
  <Image src={imageSrc} width={250} height={250} aspectRatio={1} boxSize="200px" mb="4" opacity={0.6}/>
)}

<Text 
Text color={'#153373'} fontSize={'sm'} fontWeight={500}
>
{message}
</Text>
<Text color={'#8C8C8C'} fontSize={'xs'}>
{submessage}
</Text>
      
    
    </Box>
  );
};

NoDataComponent.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NoDataComponent;
