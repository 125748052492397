import {
  Card,
  Text,
  HStack,
  Box,
  VStack,
  Center,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustFaqCard = ({
  title,
  subtitle,
  icon,
  goto,
  actions = true,
  onEdit,
  onDelete,
}) => {
  const navigate = useNavigate();

  return (
    <Card
      bg="white"
      borderRadius={"39px"}
      boxShadow="0px 10px 99px #00000029"
      textAlign={"center"}
      justify={"center"}
      w={"100%"}
      minHeight={{ base: "120px", md: "149px" }}
      p={{ base: 4, md: 6, lg: 8 }}
      mt={{ base: 4, md: 6, lg: 8 }}
      onClick={() => navigate(goto)}
      cursor="pointer"
    >
      <VStack justify={"center"} align={"flex-start"} w="100%">
        <HStack
          h={"50%"}
          w={"100%"}
          justify={"flex-start"}
          align={"center"}
          position={'relative'}
          spacing={{ base: 4, md: 8, lg: 10 }}
        >
          {icon && (
            <Box
              bg="#153373"
              borderRadius={"27px"}
              p={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              position={"relative"}
              left={-5}
              zIndex={10}
            >
              {icon}
            </Box>
          )}
          <Text
            color={"#000"}
            opacity={"0.8"}
            fontSize={{ base: "16px", md: "18px", lg: "20px", xl: "22px" }}
            fontWeight={"600"}
            isTruncated
          >
            {title}
          </Text>
          {actions && (
            <Box
              bg="#fff"
              borderRadius={"27px"}
              p={4}
              boxShadow="0px 10px 49px #00000029"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position={"absolute"}
              right={35}
              top={-65}
              zIndex={10}
            >
              <HStack
                h={"50%"}
                w={"100%"}
                justify={"center"}
                align={"center"}
                spacing={4}
                overflow="hidden"
              >
                <Text
                  color={"#000"}
                  opacity={"1"}
                  fontSize={{
                    base: "14px",
                    md: "16px",
                    lg: "18px",
                    xl: "20px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit && onEdit();
                  }}
                  cursor="pointer"
                >
                  Edit
                </Text>
                <Center height="24px">
                  <Divider orientation="vertical" borderColor={'#000'}/>
                </Center>
                <Text
                  color={"#E22937"}
                  opacity={"1"}
                  fontSize={{
                    base: "14px",
                    md: "16px",
                    lg: "18px",
                    xl: "20px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete && onDelete();
                  }}
                  cursor="pointer"
                >
                  Delete
                </Text>
              </HStack>
            </Box>
          )}
        </HStack>
        {subtitle && (
          <HStack
            h={"50%"}
            w={"100%"}
            justify={"flex-start"}
            align={"center"}
            overflow="hidden"
            pt={4}
          >
            <Text
              color={"#000"}
              opacity={"0.8"}
              fontSize={{ base: "14px", md: "16px", lg: "18px", xl: "20px" }}
              isTruncated
            >
              {subtitle}
            </Text>
          </HStack>
        )}
      </VStack>
    </Card>
  );
};

export default CustFaqCard;
