import React, { useEffect, useState } from "react";
import NavBar from "../../components/home/navBar/NavBar";
import BreadCrumb from "../../components/home/navBar/BreadCrumb";
import CustSpinner from "../../components/Spinner/CustSpinner";
import Img from "../../Assets/Images/defaultCarImage.png";
import Cars from "../../Assets/Images/Cars.png";
import CarsLoan from "../../Assets/Images/CarsLoan.png";
import UserManage from "../../Assets/Images/UserMana.png";
import NoDataComponent from "../../components/utils/NoDataComponent";
import {
  Button,
  Card,
  HStack,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAuth } from "../../providers/AuthProvider";
import { GetAllUsersStatApi } from "../../Axios/Api";
import DefaultLayout from "../../components/auth/DefaultLayout";
import BottomBarStatic from "../../components/home/footer/BottomBarSatic";
import Lottie from "react-lottie";
import * as animationData from "../../Assets/Icons/ShakeHand.json";
import { useNavigate } from "react-router-dom";
import ChartComp from "../../components/Chart/ChartComp";
import AuthenticatedLayout from "../../components/auth/AuthenticatedLayout";

export default function Dashboard() {
  const { logged,  user } = useAuth();
  const [userStats, setUserStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const fetchVehicles = (values) => {
    setLoading(true);
    GetAllUsersStatApi(values)
      .then((response) => {
        if (response.data !== null) {
          setUserStats(response.data);
        } else {
          setUserStats([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setUserStats([]);
      })
      .finally(() => {
        });
          setLoading(false);
  };

  useEffect(() => { 
    fetchVehicles();
  }, [logged]);


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const cards = [
    {
      title: "Cars",
      image: Cars,
      goTo: "/cars",
    },
    {
      title: "Car Loan",
      image: CarsLoan,
      goTo: "/car-loan",
    },
    {
      title: "User Management",
      image: UserManage,
      goTo: "/users",
    },
  ];

  const data = [
    { month: "Jan", Users: 1000 },
    { month: "Feb", Users: 300 },
    { month: "Mar", Users: 200 },
    { month: "Apr", Users: 278 },
    { month: "May", Users: 189 },
    { month: "Jun", Users: 239 },
    { month: "Jul", Users: 349 },
    { month: "Aug", Users: 439 },
    { month: "Sep", Users: 549 },
    { month: "Oct", Users: 949 },
    { month: "Nov", Users: 849 },
    { month: "Dec", Users: 249 },
  ];



  const incomeData = [
    { month: "Jan", Amount: 1000 },
    { month: "Feb", Amount: 3005 },
    { month: "Mar", Amount: 2000 },
    { month: "Apr", Amount: 2789 },
    { month: "May", Amount: 1895 },
    { month: "Jun", Amount: 2390 },
    { month: "Jul", Amount: 3409 },
    { month: "Aug", Amount: 4009 },
    { month: "Sep", Amount: 5409 },
    { month: "Oct", Amount: 4609 },
    { month: "Nov", Amount: 3009 },
    { month: "Dec", Amount: 8309 },
  ];

  return (
    <AuthenticatedLayout>
      <NavBar />
      {logged && 
      <BreadCrumb
        imageUrl={"url('./sell_a_vehicle.png')"}
        title={
            <HStack align={"center"} justify={"center"}>
            <Text>
            Welcome {user?.firstName || ""}
            </Text>
             <Lottie options={defaultOptions} height={80} width={80} />
            </HStack>
        }
      />}

      <VStack w={"100%"} h={"auto"} py={"8"}>
        {loading ? (
          <CustSpinner />
        ) : (
          <>
            <SimpleGrid
              columns={{ base: 1, md: 1, lg: 3 }}
              w={"95%"}
              justifyItems={"center"}
              py={12}
              p={15}
              spacing={5}
              rowGap={50}
            >
              {cards.map((item, index) => (
                <Card
                  key={index}
                  bg="white"
                  borderRadius={"39px"}
                  style={{
                    boxShadow: "0px 10px 99px #00000029",
                  }}
                  textAlign={"center"}
                  w={"100%"}
                  h={254}
                >
                  <Image
                    src={item.image}
                    alt="car image"
                    w={"100%"}
                    h={"60%"}
                    fit={"cover"}
                    align={"center"}
                    borderTopRadius={"39px"}
                    fallbackSrc={Img}
                    fallback={
                      <Skeleton height="60%" borderTopRadius={"39px"} />
                    }
                  />
                  <HStack
                    h={"40%"}
                    w={"100%"}
                    justify={"center"}
                    align={"center"}
                  >
                    <Text color={"#000"} opacity={"0.8"} fontSize={"22px"}>
                      {item.title}
                    </Text>
                  </HStack>
                  <Button
                    onClick={() => navigate(`${item.goTo}`)}
                    colorScheme="red"
                    py={6}
                    rounded={"49px"}
                    w={{ base: "80%", md: "80%", lg: "80%" }}
                    position={"absolute"}
                    bottom={"0"}
                    left={"50%"}
                    transform={"translate(-50%, 50%)"}
                    fontSize={"xs"}
                  >
                    See Details
                  </Button>
                </Card>
              ))}
            </SimpleGrid>

            {data && userStats ? (
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1 }}
                w={"100vw"}
                justifyItems={"center"}
                py={12}
                p={15}
                spacing={5}
              >
                <ChartComp
                  data={userStats.monthlyCounts || []}
                  subtitle={"No of users enrolled in 2024 - "}
                  count={userStats.totalUserCount || 0}
                  showDatePicker={true}
                  onDateChange={fetchVehicles}
                />

                <ChartComp
                  incomeData={incomeData}
                  subtitle={"Total income in 2024 - "}
                  count={"$50,000"}
                />
              </SimpleGrid>
            ) : (
              <NoDataComponent type="nocars" />
            )}
          </>
        )}
      </VStack>

      <BottomBarStatic />
    </AuthenticatedLayout>
  );
}
