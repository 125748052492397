import { Card, Text, HStack, Box, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustNavCard = ({ title, subtitle, icon ,goto}) => {
  const navigate = useNavigate();
  return (
    <Card
      bg="white"
      borderRadius={"39px"}
      style={{
        boxShadow: "0px 10px 99px #00000029",
      }}
      textAlign={"center"}
      justify={"center"}
      minWidth={297}
      minHeight={149}
      p={8}
      onClick={() => navigate(goto)}
    >
      <HStack
        h={"50%"}
        w={"100%"}
        justify={"flex-start"}
        align={"center"}
        overflow="hidden"
        spacing={10}
      >
        {icon && (
        <VStack justify={'center'} align={'flex-start'}>
          <Box
            bg="#153373"
            borderRadius={"27px"}
            p={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position={'absolute'}
            left={-45}
            zIndex={10}
          >
            {icon}
          </Box>
</VStack>
        )}        
        <Text
          color={"#000"}
          opacity={"0.8"}
          fontSize={["16px", "18px", "20px", "22px"]}
          isTruncated
        >
          {title}
        </Text>
      </HStack>
      {subtitle && (
        <HStack
          h={"50%"}
          w={"100%"}
          justify={"flex-start"}
          align={"center"}
          overflow="hidden"
          pt={4} 
        >
          <Text
            color={"#000"}
            opacity={"0.8"}
            fontSize={["16px", "18px", "20px", "22px"]}
            isTruncated
          >
            {subtitle}
          </Text>
        </HStack>
      )}
    </Card>
  );
};

export default CustNavCard;
