import React from 'react';
import { PiArrowCircleLeftFill } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); 
  };

  return (
    <IconButton
      aria-label="Go back"
      icon={<PiArrowCircleLeftFill size={79} color='#fff'/>}
      onClick={handleBack}
      variant="ghost"
      _hover={{ color: "blue.500" }} // Optional hover effect
      fontSize="3xl" // Adjust the icon size
      color="black" // Set initial color
    />
  );
};

export default BackButton;
